import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import { LoginColumn, LoginPop } from './LoginAndSIgnUpStyle';
import { axiosRequest } from '../../../utility/axios';
import { apiBaseUrl, redisApiBaseUrl } from '../../../config';
import { failureToast, getDeviceType, showToast, successToast, validatePhoneNumber } from '../../../utility';
import moment from 'moment';
import OTPVerificationDialog from './OTPVerification';
import { useDispatch } from 'react-redux';
import { updateSignUpUserState, updateUserState } from '../../../reducers/user';
import AuthContext from './context';
import { sessionManager } from '../../../managers/sessionManager';
import { cookiesConstants } from '../../../constants';
import LoginDialog from './LoginDialog';
import UserProfileDialog from '../Home/User.js/UserProfileDialog';
import { Popover } from '@mui/material';
import PersonalDetailsDialog from './PersonalDetails';
import CoinWinDialog from './CoinWinDialog';
import admin from "../../../image/admin.png"


const JoinTheCommunityDialog = () => {
    const { handleDialogToggle, toggleDialog } = useContext(AuthContext);
    const dispatch = useDispatch();
    const [mobileNumber, setMobileNumber] = useState('')
    const [referCode, setReferCode] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handlePopoverClose = () => {
          setAnchorEl(null);
          handleDialogToggle("join", false);
      };
    
      const openPop = Boolean(anchorEl);
    const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
    const isLoggedIn = sessionManager.getDataFromCookies(cookiesConstants.IS_LOGGED_IN)|| false;
    const handleInput = (event) => {
        setMobileNumber(event.target.value)
    }

    const signUp = async () => {
        if (!validatePhoneNumber(mobileNumber))
        {
            failureToast("Invalid Phone")
            return
            }
        const url = `${redisApiBaseUrl}/userRedisSignUp`;
        const request = {
            "phone": mobileNumber,
            "device_type": getDeviceType() || "Desktop",
            "device_id":`${ getDeviceType() || "Desktop"}-${moment.now()}`
           }    
        const signupDetail = await axiosRequest("POST", url, request)
        if (!signupDetail.status || !signupDetail || signupDetail.errors || !signupDetail.data) {
            failureToast(signupDetail?.message || "Unable to Signup")
            return
        }
        successToast("OTP Sent Successfully")
        dispatch(updateSignUpUserState({ data: { ...signupDetail.data, refer_code: referCode } }))
        handleDialogToggle("otpVerification",true)
        // const userWalletInfo =  await getUserWalletInfo(signupDetail.data.user_id)
        // dispatch(userWalletInfo(userWalletInfo))
      
    }
    const getUserWalletInfo = async (userId) => {
        let url = `${redisApiBaseUrl}/getUserWalletRedis/${userId}`;
         const response = await axiosRequest("GET", url);
         if (!response || response.errors || !response.status) {
             return;
         }
        return response;
     }
    const onLoginClick = (event) => {
        if (isLoggedIn)
            handlePopoverOpen(event);
        else {
            handleDialogToggle("join", !toggleDialog.join);
            setMobileNumber('');
            setReferCode('')
        }
        
    }
    return (
        <>
            <LoginPop id={openPop ? "simple-popover" : undefined} onClick={(event) => {onLoginClick(event)}}>
                    <Image src="https://storage.googleapis.com/cricfun-images/img-1717999214863.png" width={"30px"} margin={"5px"} mdWidth={"25px"} xsWidth={"18px"} xsMargin={"0px 0px 0px 4px"}></Image>
                    <LoginColumn justifyContent="center">
                    <Heading  color={"#FFFFFF"} cursor={"pointer"} fontSize={"20px"} textAlign={"center"} mdFont={"18px"} marginLeft= {"10px"}>{profileDetails?.full_name || "Login"}</Heading>
                    </LoginColumn>
             </LoginPop>
           <>
                {
                  isLoggedIn ?
                        <UserProfileDialog openPop={openPop} anchorEl={anchorEl} handlePopoverClose={handlePopoverClose}></UserProfileDialog>    
                        :
                        <>
                            { JoinCommunityComponent(toggleDialog, mobileNumber, handleDialogToggle, signUp, handleInput, referCode, setReferCode) } 
                            </>
                }
                </>
            <OTPVerificationDialog
                mobileNumber={mobileNumber}
            ></OTPVerificationDialog>
            <LoginDialog></LoginDialog>
            
        </>
    )
}
const JoinCommunityComponent = (toggleDialog, mobileNumber, handleDialogToggle, signUp, handleInput, referCode, setReferCode) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            signUp();
        }
    };
   
    return (
        <Dialog open={toggleDialog?.join && (!toggleDialog.otpVerification)} sx={{
            "& .MuiDialog-paper": {
                margin: "20px 10px",
            borderRadius:"5px"
            },

      
           
         
        }}>
        <Row justifyContent='end'>
            <Image width={"20px"}  xsWidth={"15px"} margin={"10px"} marginTop={"10px"} cursor={"pointer"} src="../Assets/cross.svg" onClick={() => handleDialogToggle("join",false)}></Image>
        </Row>
        <MainBox>

            <Heading fontSize={"32px"} fontWeight={"700"} mdFont={"28px"} smFont={"26px"}>Join the Community! </Heading>
            <Heading fontSize={"21px"} width={"80%"} lineHeight={"1.2"} smWidth={"90%"} mdFont={"20px"} smFont={"20px"} fontWeight={"300"}>Sign up today for exclusive access and personalised experiences</Heading>
                <Input placeholder='Mobile Number' smFont={"14px"} value={mobileNumber} onChange={(event) => handleInput(event)} onKeyPress={handleKeyPress}></Input>
                <Input placeholder='Enter Referral Code' smFont={"14px"} value={referCode} onChange={(event)=>setReferCode(event.target.value)} onKeyPress={handleKeyPress}></Input>

            <Button onClick={signUp} smFont={"14px"}>Signup</Button>
            <Section>
                <Row justifyContent='space-between' >
                    <HorizontalLine></HorizontalLine>
                    <Heading fontSize={"14px"} smFont={"14px"} textAlign={"center"} padding={"0px 10px"}>Or</Heading>
                    <HorizontalLine></HorizontalLine>
                </Row>
            </Section>
            {/* <Section>
                <Row justifyContent='center'>
                    <Image height={"30px"} margin={"25px"} src="../Assets/Googlelogo.svg"></Image>
                    <Image height={"30px"} src="../Assets/facebook.svg"></Image>
                </Row>
            </Section> */}

            <Row justifyContent='center'>
                <Heading  fontSize={"20px"} fontWeight={"400"} smFont={"14px"} marginRight = {"10px"}>Already have an account? </Heading>
            <Heading cursor={"pointer"} fontSize={"20px"} smFont={"14px"} color={"rgb(22, 138, 192)"} fontWeight={"400"} onClick={() => { handleDialogToggle("join", false);  handleDialogToggle("login",true)}} >Login</Heading>
            </Row>
        </MainBox>
    </Dialog>
    )
}
const MainBox = styled.div`
padding:0px 50px 30px 50px;
max-width:450px;

  @media (max-width:450px) {
  padding:0px 40px 30px 40px;
  
  }
`;

const Heading = styled.div`
cursor:${props => props.cursor || "auto"};
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
padding: ${props => props.padding };
margin-right: ${props => props.marginRight};
width: ${props => props.width || "auto"};
margin-left: ${props => props.marginLeft || "0px"};
line-height :${props=> props.lineHeight};
@media (max-width:1399px) {
    font-size: ${props => props.mdFont}
  }
  @media (max-width:450px) {
    font-size: ${props => props.smFont};
    width:${props => props.smWidth}
  }
`
const Input = styled.input`
font-size:16px;
border:1px solid #CFCFD3;
width:100%;
border-radius:25px;
height: 50px;
padding: 0px 20px;
margin: 10px 0px;
  @media (max-width:450px) {
    font-size: ${props => props.smFont};
    height:40px;
  }
`
const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border:none;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
height: 50px;
width: 100%;
border-radius: 25px;
font-size:18px;
margin-bottom: 10px;
margin-top:10px;
  @media (max-width:450px) {
    font-size: ${props => props.smFont};
    height:40px;
  }
`
const HorizontalLine = styled.div`
border:1px solid #DEDEDE;
width:100%;
height:1px;
margin:10px 2px 0px 2px;
`
export const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
cursor:${props => props.cursor || "auto"};
@media (max-width:1399px) {
    max-width: ${props => props.mdWidth}
  }
@media (max-width:415px) {
    max-width: ${props => props.xsWidth};
    margin: ${props => props.xsMargin}
  }

`
const Section = styled.div`
margin-bottom:25px;
`
export default JoinTheCommunityDialog