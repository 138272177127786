import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Column, Row } from "simple-flexbox";
import styled from "styled-components";
import { redisApiBaseUrl } from "../../../../config";
import { axiosRequest } from "../../../../utility/axios";
import { sessionManager } from "../../../../managers/sessionManager";
import { cookiesConstants } from "../../../../constants";
import moment from "moment";

export const ColumnDiv = styled.div`
margin: 5px auto;
background: #FFFFFF;
padding:20px;
border-radius: 8px;
min-height: ${props => props.minHeight};
@media(max-width:550px){
padding: 6px;
};
`

const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
margin-left:${props => props.marginLeft || "0px"};
cursor :${props =>props.cursor || "auto"};
@media(max-width:550px){
width:${props => props.smWidth};
};

`
export const CustomTabList = styled(TabList)`
list-style-type:none;
display:flex;
width:auto;
padding:0px;
min-width: 500px;
@media(max-width:680px){
    min-width:420px;
};
@media(max-width:590px){
    min-width:340px;
};

`

export const CustomTab = styled(Tab)`
background:${props => props.isActive ? "linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)" : "#F7F7F8"};
padding: 5px 20px;
color:${props => props.isActive ? "#FFFFFF" : "#000000"};
font-size: 20px;
font-weight:500;
border-radius: 20px;
margin-right: 10px;
cursor:pointer;
@media(max-width:680px){
    font-size: 16px;
    padding: 5px 10px;
};
`
export const TransactionDiv = styled.div`
background: #F7F7F8;
display:flex;
justify-content:space-between;
border-radius: 5px;
padding: 10px 15px;
margin-bottom: 10px;

`
export const Heading = styled.div`
font-size: ${props => props.fontSize || "14px"};
font-weight:${props => props.fontWeight || "500"};
text-align: ${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
// padding: ${props => props.padding || "0px"};
margin: ${props => props.margin};
@media(max-width:680px){
font-size:${props => props.mdFontSize};
};
@media(max-width:550px){
font-size:${props => props.smFontSize};
};
@media(max-width:425px){
font-size:${props => props.xsFontSize};
};

`
export const MainContainer = styled.div`
background:#F1F3F4;
padding: 20px 30px 20px 30px;
margin: 0 auto;

@media(max-width:550px){
padding: 14px;
width:100%;
};
`
export const Darkcontainer = styled.div`
background:#F1F3F4;
padding:15px 15px 0px 0px;
`

const Transactions = (props) => {
    const [transactionList, setTransactionList] = useState([])
    const getTransactions = async () => {
        const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS)
        let url = `${redisApiBaseUrl}/getTransactionRedis/${profileDetails.user_id}/0/10`;
        const response = await axiosRequest("GET", url);
        if (!response || response.errors || !response.status) {
            return;
        }
        setTransactionList(response.data)
    }
    useEffect(() => {
        if (props?.toggleDialog?.transactions)
            getTransactions()
    }, [props?.toggleDialog?.transactions])
    return (
        <Dialog open={props?.toggleDialog?.transactions} 
        sx={{
            "& .MuiDialog-paper": {
                 margin: "20px 10px",
                borderRadius:"4px"
            },
        }}>
            {/* <Darkcontainer>
                <Row justifyContent='end'>
                    <Image width={"12px"} src="../Assets/cross.svg" onClick={() => props.handleDialogToggle("transactions", false)}></Image>
                </Row>
            </Darkcontainer> */}
            <MainContainer>
                <Row justifyContent="space-between">
                <Heading fontSize={"16px"} mdFontSize={"14px"} fontWeight={"600"}>All Transactions</Heading>
                    <Image width={"12px"}  cursor={"pointer"}src="../Assets/cross.svg" onClick={() => props.handleDialogToggle("transactions", false)}></Image>
                    </Row>

                <ColumnDiv minHeight={"400px"}>

                    {TabsSection(transactionList)}
                </ColumnDiv>
            </MainContainer>
        </Dialog>
    )
}

const TabsSection = (transactionList) => {
    const [tabIndex, setTabIndex] = useState(0)
    return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <CustomTabList>
                <CustomTab isActive={tabIndex === 0 ? true : false}>All</CustomTab>
                <CustomTab isActive={tabIndex === 1 ? true : false}>Games</CustomTab>
                <CustomTab isActive={tabIndex === 2 ? true : false}>Rewards</CustomTab>

            </CustomTabList>
            <TabPanel>
                {TransactionDetail(transactionList)}
            </TabPanel>
            <TabPanel>
                {TransactionDetail(transactionList, 3)}
            </TabPanel>
            <TabPanel>
                {TransactionDetail(transactionList, 1)}
            </TabPanel>
        </Tabs>
    )
}

const TransactionDetail = (transactionList, type) => {
    let transactions = transactionList;
    if (type)
        transactions = transactions.filter(({ transaction_type }) => { return transaction_type == type })
    return (
        <>
            {transactions && transactions.length ? transactions.map((transaction) => (
                <TransactionDiv>
                    <Column>
                        <Heading fontWeight={"500"}>{transaction?.activity_name}</Heading>
                        <Heading color={"#81838A"} mdFontSize={"12px"} smFontSize={"10px"} fontWeight={"400"}>{moment(transaction?.txn_date).format("DD MMM, HH:mm")}</Heading>
                        <Heading color={"#81838A"}  xsFontSize={"10px"}mdFontSize={"12px"} smFontSize={"10px"} fontSize={"14px"} fontWeight={"400"}>Txn ID: {transaction?.local_txn_id}</Heading>
                    </Column>
                    <Row>
                        <Image width={"20px"} margin={"5px"}  smWidth={"18px"} src="../Assets/coin.svg"></Image>
                        <Heading fontSize={"20px"} smFontSize={"16px"}padding={"15px 0px 0px 0px "} margin={"auto"}>{transaction.sign_type}{transaction.coin}</Heading>
                    </Row>
                </TransactionDiv>
            )) : 
            <Heading fontSize={"20px"} textAlign={"center"} margin={"24% auto "}>No Transactions</Heading>

            }
        </>
    )
}
//games=3, rewards=1
export default Transactions;