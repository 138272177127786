import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Row } from 'simple-flexbox';
import { axiosRequest } from '../../../utility/axios';
import { redisApiBaseUrl } from '../../../config';
import PersonalDetailsDialog from './PersonalDetails';
import { sessionManager } from '../../../managers/sessionManager';
import { cookiesConstants } from '../../../constants';
import { failureToast, successToast } from '../../../utility';
import AuthContext from './context';


const MainBox = styled.div`
padding: 12px 50px 30px 50px;
max-width:400px;
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align: ${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
@media(max-width:500px){
font-size: ${props => props.smFont}
};
`
const Input = styled.input`
font-size:16px;
border:1px solid #CFCFD3;
width:12%;
border-radius:8px;
height: 50px;
padding: 0px 20px;
margin: 20px 0px;
color:#000000;
padding:9px;
@media(max-width:500px){
height:40px;
font-size:14px;
padding: 3px 5px;
width:14%;
};
`
const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border:none;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
height: 50px;
width: 100%;
border-radius: 25px;
font-size:18px;
margin-bottom: 10px;
@media(max-width:500px){
  height:40px;
  font-size:16px;
}
`
const HorizontalLine = styled.hr`
border:1px solid #DEDEDE;
width:100%;
`
const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
margin-left:${props => props.marginLeft || "0px"};
@media(max-width:500px){
width:${props => props.smWidth};
};


`
const Section = styled.div`
margin-bottom:25px;
`
function OTPVerificationDialog(props) {
    const {handleDialogToggle, toggleDialog } = useContext(AuthContext);
    const {  mobileNumber } = props;
    const itemsRef = useRef([]);
    const [userInput, setUserInput] = useState("");
    const [otpTimer, setOTPTimer] = useState(60);

    const decrementTimer = () => {
      if (otpTimer > 0) {
        setOTPTimer(otpTimer - 1);
      }
    };
    useEffect(() => {
        const timerInterval = setInterval(decrementTimer, 1000);
        return () => {
          clearInterval(timerInterval);
        };
    }, [otpTimer]);
    
  
    const codeChangeHandler = (event) => {
      const [, codeFieldIndex] = event.target.name.split("-");
      let fieldIntIndex = parseInt(codeFieldIndex, 10);
      setUserInput((prevState) => prevState + event.target.value);
  
      if (fieldIntIndex < 5) {
        itemsRef.current[fieldIntIndex + 1].focus();
      } else {
        const field = document.querySelector(`Input[name=code-${fieldIntIndex}]`);
        field.blur();
      }
    };

    const verifyOTP = async () => {
        const url = `${redisApiBaseUrl}/redisVerifyOtpRedister`;
        const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
        const request = {
            "user_id": profileDetails.user_id,
            "device_id": profileDetails.device_id,
            "device_type": profileDetails.device_type,
            "otp": userInput,
            "refer_code":profileDetails.refer_code
        }    
        const response = await axiosRequest("POST", url, request)
       
        if (!response || response.errors || !response.status) {
          failureToast(response?.message || "Unable to verify")
          setUserInput("")
            
            return
        }
        successToast("OTP Verified")
        handleDialogToggle("otpVerification",false)
        handleDialogToggle("join",false)
        handleDialogToggle("personalDetails",true)
        
    }
    const resendOTP = async () => {
        const url = `${redisApiBaseUrl}/resendOtpRedis`;
        const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
        const request = {
            "user_id":profileDetails.user_id,
            "phone": profileDetails.phone,
        }    
        const response = await axiosRequest("POST", url, request)
        if (!response || response.errors || !response.status) {
            failureToast(response.message || "Unable to resent")
            return
        }
        successToast("OTP sent successfully")
        setOTPTimer(60)
    }
      
    return (
        <>
        <Dialog open={toggleDialog.otpVerification}  sx={{
                "& .MuiDialog-paper": {
                    margin: "20px 10px",
                borderRadius:"5px"
                },

          
               
             
            }}>
            <Row justifyContent='start'>
            <Image width={"20px"} marginTop={"10px"} marginLeft={"10px"} src="../Assets/right_arrow_icon.svg" onClick={() => { handleDialogToggle("otpVerification", false); setUserInput("") }}></Image>
            </Row>
            <MainBox>
                <Row justifyContent='center'>
                    <Image width={"200px"} smWidth={"150px"} src="../Assets/otp.svg"></Image>
                </Row>

                <Heading fontSize={"24px"} smFont={"20px"} textAlign={"center"}>OTP Verification</Heading>
            <Heading fontSize={"16px"} smFont={"14px"} color={"#02153F"} fontWeight={"400"} textAlign={"center"}>{`Enter the six digit code sent to -`}</Heading>
            <Heading fontSize={"16px"} smFont={"14px"} color={"#02153F"} textAlign={"center"}>{`+91-${mobileNumber}`}</Heading>
                <Row justifyContent='space-between'>
                    {new Array(6)
                        .fill(0)
                        .map((item, index) => (
                            <Input
                                ref={(ref) => itemsRef.current.push(ref)}
                                name={`code-${index}`}
                                key={index}
                                onChange={(event) => codeChangeHandler(event)}
                                maxLength={1}
                            />
                        ))}
                </Row>
                <Section>
                    <Row justifyContent='center'>
                            <Heading fontSize={"16px"} smFont={"12px"} fontWeight={"400"} color={"#02153F"}>Didn't receive the code ? </Heading>
                            {otpTimer ?
                                <Heading fontSize={"16px"} smFont={"12px"} color={"rgb(22, 138, 192)"} fontWeight={"400"}> {otpTimer}</Heading>
                                : 
                                <Heading fontSize={"16px"} smFont={"12px"} color={"rgb(22, 138, 192)"} fontWeight={"400"} onClick={resendOTP}> Resend OTP </Heading>
                                }
                    </Row>
                </Section>
                <Button onClick={verifyOTP}>Verify</Button>
            </MainBox>
            </Dialog>
            <PersonalDetailsDialog></PersonalDetailsDialog>
            </>
    )
}

const CodeInput = () => {
    const itemsRef = useRef([]);
    const [userInput, setUserInput] = useState("");
  
    const codeChangeHandler = (event) => {
      const [, codeFieldIndex] = event.target.name.split("-");
      let fieldIntIndex = parseInt(codeFieldIndex, 10);
      setUserInput((prevState) => prevState + event.target.value);
  
      if (fieldIntIndex < 5) {
        itemsRef.current[fieldIntIndex + 1].focus();
      } else {
        const field = document.querySelector(`Input[name=code-${fieldIntIndex}]`);
        field.blur();
      }
    };
  
    const codeInputFields = new Array(6)
      .fill(0)
      .map((item, index) => (
        <Input
          ref={(ref) => itemsRef.current.push(ref)}
          name={`code-${index}`}
          key={index}
          onChange={(event) => codeChangeHandler(event)}
          maxLength={1}
        />
      ));
  
    return <>{codeInputFields}</>;
  };
export default OTPVerificationDialog