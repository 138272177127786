import React, { useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import { openPlayStore } from '../../../utility';
import AuthContext from './context';
import { useNavigate } from "react-router-dom";
const MainBox = styled.div`
padding: 50px;
max-width:450px;
background:radial-gradient(334.45% 74.65% at 50.13% 39.9%, #0053CF 0%, #03133C 100%),
linear-gradient(87.71deg, rgba(37, 172, 248, 0.31) 3.75%, rgba(165, 213, 240, 0.31) 26.18%, rgba(148, 216, 255, 0.31) 39.64%, rgba(140, 201, 236, 0.31) 72.16%, rgba(255, 255, 255, 0.31) 111.42%);
@media(max-width:500px){
    padding:50px 30px;
};
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:center;
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
@media(max-width:500px){
    font-size: ${props => props.smFontSize};
};
`;
const HighlightText = styled.span`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:center;
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
padding-right:5px;
@media(max-width:500px){
    font-size: ${props => props.smFontSize};
};
`
const Input = styled.input`
font-size:16px;
border:1px solid #CFCFD3;
width:100%;
border-radius:25px;
height: 50px;
padding: 0px 20px;
margin: 20px 0px;
`
const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border: 1.2px solid #FDE880;
background: linear-gradient(156.45deg, #0DD281 15.13%, #00A362 78.95%),
linear-gradient(0deg, #FDE880, #FDE880);
height: 60px;
width: 100%;
border-radius: 25px;
font-size:34px;
font-weight:700;
margin-bottom: 10px;
@media(max-width:500px){
    font-size:30px;
    height:50px;
};
`
const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
`
const Section = styled.div`
margin-bottom:25px;
`
const CoinWinDialog = (props) => {
    // const {toggleDialog, handleDialogToggle} = useContext(AuthContext);
    let navigate = useNavigate();
   useEffect(()=>{

   },[props.toggleDialog.signupCoin])

    return (
        <Dialog open={props.toggleDialog.signupCoins} onBackdropClick={()=>{props.handleCloseAll()}}
        sx={{
            "& .MuiDialog-paper": {
                margin: "20px 10px",
            borderRadius:"5px"
            },

      
           
         
        }}
        >
            
            <MainBox>
                <Row justifyContent='center'>
                    <Image width={"200px"} src="../Assets/win.svg"></Image>
                </Row>

                <Heading fontSize={"26px"} smFontSize={"18px"} fontWeight={"600"} color={"#FFFFFF"}>Congratulations! You have won
                    <HighlightText color={"#FFD400"} fontSize={"26px"} smFontSize={"18px"}   fontWeight={"600"}>  FREE COINS </HighlightText>
                    for signing up</Heading>
                    <Row justifyContent='center'>
                    <Image width={"60px"} src="../Assets/coin.svg"></Image>
                    <Heading  fontSize={"75px"} mdFontSize={"65px"}  fontWeight={"700"} color={"#FFFFFF"}>100</Heading>
                </Row> 
                     <Button onClick={()=>navigate("/", { replace: true })}>Play Now</Button>
            </MainBox>
        </Dialog>
    )
}
export default CoinWinDialog