import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import { useDispatch } from "react-redux";
import AuthContext from './context';
import { redisApiBaseUrl } from '../../../config';
import { sessionManager } from '../../../managers/sessionManager';
import { axiosRequest } from '../../../utility/axios';
import { subtract } from 'lodash';
import { cookiesConstants } from '../../../constants';
import { failureToast, successToast, validatePhoneNumber } from '../../../utility';

const MainBox = styled.div`
padding:0px 50px 30px 50px;
max-width:450px;
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:left;
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
@media (max-width:1399px) {
    font-size: ${props => props.mdFont}
  }
  @media (max-width:450px) {
    font-size: ${props => props.smFont};
    width:${props => props.smWidth}
  }
`
const Input = styled.input`
font-size:16px;
border:1px solid #CFCFD3;
width:100%;
border-radius:25px;
height: 50px;
padding: 0px 20px;
margin-top: 20px;
  @media (max-width:450px) {
   height:40px;
   font-size: ${props => props.smFont};
  }
`
const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border:none;
background: ${props => props.isActive? 'linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)': "#AEAEAE"};
height: 50px;
width: 100%;
border-radius: 25px;
font-size:18px;
margin-bottom: 10px;
margin-top:20px;
@media (max-width:450px) {
   height:40px;
   font-size:14px;
};

`
const HorizontalLine = styled.hr`
border:1px solid #DEDEDE;
width:100%;
`
const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
cursor:${props => props.cursor || "auto"};
`
const Section = styled.div`
margin-bottom:25px;
`
const OTPInputDiv = styled.div`
font-size:16px;
border:1px solid #CFCFD3;
width:100%;
border-radius:25px;
height: 50px;
padding: 0px 20px;
margin-top: 20px;
display:flex;
justify-content:space-between;
  @media (max-width:450px) {
    font-size: ${props => props.smFont};
    height:40px;
  }
`
const OTPInput = styled.input`
border:none;
outline:none;
&:focus:{
    border : none;
    outline:none;
};
  @media (max-width:450px) {
    width:100%;

  };
`
const OTPButton = styled.button`
font-size: 14px;
color: rgb(22, 138, 192);
border:none;
outline:none;
&:focus:{
    border : none;
    outline:none;
};
background:#FFFFFF;
  @media (max-width:450px) {
    font-size: 12px;
    background:transparent;
    width:50%;

  };
  @media (max-width:390px) {
    width:85%;
};
`
const CreateOrForgotPassword = () => {
    const { handleDialogToggle, toggleDialog, closeAllDialogs } = useContext(AuthContext);
    const dispatch = useDispatch();
    const [passwordDetails, setPasswordDetails] = useState({
        mobile: '',
        otp: '',
        password: '',
        confirmPassword:'',
        user_id:''
    })
    const [isOTPVerified, setIsOTPVerified] = useState(false)
    const onHandleInput = (event) => {
        setPasswordDetails({
            ...passwordDetails,
            [event.target.name]:event.target.value
        })
    }
    const sendOTP = async () => {
        const isValid = validatePhoneNumber(passwordDetails.mobile)
        if (!isValid) {
            failureToast("Invalid Mobile")
            return;
        }
        
        const url = `${redisApiBaseUrl}/sendOtpRedis`;
        const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
        const request = {
            "phone": passwordDetails.mobile,
        }    
        const response = await axiosRequest("POST", url, request)
        if (!response || response.errors || !response.status) {
            failureToast(response.message || "Unable to resent")
            return
        }
        successToast("OTP sent successfully")
    }
    const verifyOTP = async () => {
        const url = `${redisApiBaseUrl}/redisVerifyOtp`;
        const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
        const request = {
            "phone": passwordDetails.mobile,
            "otp":passwordDetails.otp
        }    
        const response = await axiosRequest("POST", url, request)
        
        if (!response || response.errors || !response.status) {
            failureToast(response?.message || "Unable to verify")
            
            return
        }
        successToast("OTP Verified");
        setIsOTPVerified(true)
        setPasswordDetails({
            ...passwordDetails,
            user_id: response.data.user_id
        })

    }
    
    const updatePassword = async () => {
        if (!validateDetails())
            return
        const url = `${redisApiBaseUrl}/redisForgetPwd`;
        const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
        
        const request = {
            "user_id":passwordDetails.user_id,
            "password":passwordDetails.password
        }    
        const response = await axiosRequest("POST", url, request)
       
        if (!response || response.errors || !response.status) {
            failureToast(response?.message || "Unable to update")
            return
        }
        successToast("Password Updated");
        handleDialogToggle("forgotPassword", false)
        handleDialogToggle("login",true)
    }
    const validateDetails = () => {
        if (!passwordDetails.mobile)
        {
            failureToast("Mobile is Required")
            return false;
        }
        else if (!passwordDetails.otp) {
            failureToast("OTP is Required")
            return false;
        }
        else if (!passwordDetails.password) {
            failureToast("Password is Required")
            return false;
        }
        else if (!passwordDetails.confirmPassword) {
            failureToast("Conform Password is Required")
            return false;
        }
        else if (passwordDetails.confirmPassword !== passwordDetails.password) {
            failureToast("Confirm Password should be same as password")
            return false;
        }
        return true
    }
    return (
        <Dialog open={toggleDialog.forgotPassword} sx={{
            "& .MuiDialog-paper": {
                margin: "20px 10px",
            borderRadius:"5px"
            },

      
           
         
        }}>
            <Row justifyContent='end'>
                    <Image width={"20px"} margin={"10px"} cursor={"pointer"} marginTop={"10px"} src="../Assets/cross.svg" onClick={closeAllDialogs}></Image>
                    </Row>
            <MainBox>
                
            <Heading fontSize={"32px"} fontWeight={"700"}  mdFont={"28px"} smFont={"20px"}>Create Password</Heading>
            <Heading fontSize={"24px"} width={"80%"} mdFont={"20px"} smFont={"16px"} fontWeight={"300px"}>Generate your New Password</Heading>
                <OTPInputDiv  smFont={"14px"}>
                    <OTPInput placeholder='Mobile' value={passwordDetails.mobile} name="mobile" onChange={onHandleInput}></OTPInput>
                    <OTPButton onClick={sendOTP}>Send OTP</OTPButton>
                </OTPInputDiv>
                <OTPInputDiv  smFont={"14px"}>
                    <OTPInput placeholder='OTP' value={passwordDetails.otp} name="otp" onChange={onHandleInput}></OTPInput>
                    <OTPButton onClick={verifyOTP}>Verify OTP</OTPButton>
                </OTPInputDiv>
                <Input placeholder='New Password' smFont={"14px"} value={passwordDetails.password} name="password" onChange={onHandleInput}></Input>
                <Input placeholder='Confirm Password' smFont={"14px"} value={passwordDetails.confirmPassword} name="confirmPassword" onChange={onHandleInput}></Input>
                <Button isActive={isOTPVerified} disabled={!isOTPVerified} onClick={updatePassword}>Save</Button>
            </MainBox>
            </Dialog>
    )
}
export default CreateOrForgotPassword