import styled from "styled-components";
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Column, Row } from "simple-flexbox";
import { logout } from '../../../../reducers/user';
import { useDispatch } from 'react-redux';
import { successToast } from "../../../../utility";
export const ColumnDiv = styled.div`
padding:22px 10px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
margin-left:${props => props.marginLeft || "0px"};
padding-bottom: 25px;
padding-top: 20px;
@media(max-width:680px){
    width:${props => props.smLWidth};
};
@media(max-width:480px){
    width:${props => props.smWidth};
    margin-right:${props => props.smMargin};
     margin-bottom:${props => props.smMarginBottom};
}
`

export const Heading = styled.div`
font-size: ${props => props.fontSize || "24px"};
font-weight:${props => props.fontWeight || "600"};
line-height: 30px;
letter-spacing: 0em;
text-align: ${props => props.align || "center"};
padding: ${props => props.padding || "0px"};
color: ${props => props.color || "#000000"};
@media(max-width:600px){
    font-size:${props => props.smLFont}
};
@media(max-width:500px){
    font-size:${props => props.smFont};
    line-height:20px;
    padding: ${props => props.smPadding};
};
@media(max-width:400px){
    font-size:${props => props.xsFont};
};
`
const Button = styled.button`
background: ${props => props.background || ""};
color: ${props => props.color || ""};
width: 80%;
margin: ${props => props.margin || "0px"};
border-radius: ${props => props.borderRadius || "20px"};
border:${props => props.border || "none"};
font-size: 22px;
padding: 10px;

@media(max-width:500px){
    font-size:${props => props.smFont};
};
`



const UserLogout = (props) => {
    const dispatch = useDispatch();
    const userLogout = () => {
        dispatch(logout());
        successToast("Logout Successfully");

        props.handleDialogToggle("logout", false);
        window.location.reload();
        props.handlePopoverClose();


    }

    useEffect(() => {

    }, [props?.toggleDialog?.logout])
    return (
        <Dialog open={props?.toggleDialog?.logout} sx={{
            "& .MuiDialog-paper": {
                borderRadius: "30px",
                margin: "20px 10px",
            },





        }}>
            <ColumnDiv>
                <Image src="../Assets/logout.png" width={"25%"} smLWidth={"30%"} smWidth={"40%"}></Image>
                <Heading fontWeight={"600"} fontSize={"30px"} smLFont={"24px"} smFont={"24px"} xsFont={"18px"} padding={"10px 20px"}>Logging out?</Heading>
                <Heading fontWeight={"400"} fontSize={"22px"} smLFont={"20px"} smFont={"17px"} xsFont={"16px"} padding={"10px 20px"} color={"#81838A"}>Are you sure want to log out of this <br></br> account</Heading>
                <Button background={"linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)"} color={"#FFFFFF"} margin={"10px"} borderRadius={"30px"} onClick={userLogout} smFont={"18px"}>Yes, Logout</Button>
                <Button color={"#0BA9DB"} margin={"10px"} border={"1px solid #0BA9DB"} background={"#FFFFFF"} borderRadius={"30px"} onClick={() => props.handleDialogToggle("logout", false)} smFont={"18px"}>Cancel</Button>


            </ColumnDiv>
        </Dialog>
    )
}





export default UserLogout 
