import React from "react";
import PredictTheWinnerComponent from "./PredictWinner";
import { Column, Row } from "simple-flexbox";
import { Image, MainContainer } from "./MatchInfoStyle";
import BlogDetails from "../Blogs/BlogComponent";
import { getPathParam } from "../../../utility";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";
import ParticularBlogsComponent from "../Blogs/ParticularBlogComponent";
import { styled } from "styled-components";
import { Grid } from "@mui/material";
import { FirstColumn } from "./InfoTab";

export const ReferAndEarnGrid = styled(Grid)`
// display:flex;
// justify-content:center;
padding:${props => props.padding || "20px 0px "};
@media (max-width:900px) {
  display:none;
   }

`
const BlogsComponent = () => {
    return (



        <MainContainer padding={"1.5% 2%"}>
        <Grid container justifyContent={"center"} display={"flex"} columnSpacing={{ md: 2 }}>
            <ReferAndEarnGrid item md={3.5} paddingTop={"10px"}>
                {getPathParam("bet") == 1 ?
                    <PredictTheWinnerComponent></PredictTheWinnerComponent>
                    : ""}
                {/* <Image width={"100%"} marginTop={"20px"} src="../Assets/dummyad.svg"></Image>    */}
                <ReferAndEarn ></ReferAndEarn>
            </ReferAndEarnGrid>

            <Grid item justifyContent={"center"} display={"flex"} md={5}>
            <BlogDetails marginTop={"20px"}></BlogDetails>
            </Grid>

            <ReferAndEarnGrid item md={3.5}>
                {/* <Image width={"100%"} src="../Assets/dummyad.svg"></Image>    */}
            </ReferAndEarnGrid >
        </Grid>
    </MainContainer>



    )
}

export default BlogsComponent;
