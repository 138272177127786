import styled from "styled-components";
import { Navbar, NavLink } from 'react-bootstrap';
import { Link } from "react-router-dom";

const path = window.location.pathname;


export const NavbarDiv = styled(Navbar)`
background: ${path.includes("game") ? '#1D274D' : "linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)"};
padding-bottom:0px;
width:100%;
padding-top: 0px;
`
export const NavbarLink = styled(NavLink)`
font-weight: 400;
font-size: 18px;
color: #FFFFFF !important;
padding:25px 10px 16px 10px;
border-bottom:${props => props.active ? "8px solid white" : "none"};
font-weight: ${props => props.active ? "600" : "400"};
&:hover {
  font-weight: 600;
 
}
&:active {
  font-weight: 600;
 
}

  @media (max-width:1399px) {
    font-size: 16px;
  }
  @media (max-width:600px) {
    padding:10px 6px;
  }
     @media (max-width:370px) {
    padding:10px 5px;
  }
`
export const NavBarBrand = styled(NavLink)`
color: #FFFFFF !important;
list-style-type: none;
`
export const MainLogo = styled.img`
max-width:${props => props.width || "140px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-left: ${props => props.marginLeft || "0px"};
cursor:pointer;
@media (max-width:1399px) {
  max-width: 100px;
};
@media (max-width:630px) {
  max-width: 85px;
};
@media (max-width:415px) {
  max-width: 70px;
};
`
export const Image = styled.img`
max-width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
border-radius:${props => props.borderRadius || "0px"};
margin-top:${props => props.marginTop || "0px"};
margin-right: ${props => props.marginRight || "0px"};
padding-bottom : ${props => props.paddingBottom || "0px"};
@media (max-width:1399px) {
  max-width: ${props => props.mdWidth};
}
@media (max-width:630px) {
  max-width: ${props => props.smWidth};
  height: ${props => props.smHeight};
    width: ${props => props.smWidth};
  display: ${props => props.smDisplay};
  padding-bottom :${props => props.smPaddingBottom};
}
@media (max-width:415px) {
  max-width: ${props => props.xsWidth};
  margin-right:${props => props.marginRight};

};

`
export const NavLinkText = styled.div`
font-weight: 400;
font-size: 18px;
color: #FFFFFF !important;
padding-bottom:7px;
`
export const GetFreeCoins = styled.div`
border: 1px solid #7DC6FC;
color:#0BA9DB;
display:flex;
justify-content:space-between;
background:#FFFFFF;
align-items:center;
padding: 3px 6px;
border-radius: 40px;
@media (max-width:1399px) {
  font-size: 14px;
};
@media (max-width:630px) {
  font-size: 11px;
};
@media (max-width:630px) {
  width:180px;
  height:38px;
  font-size:16px;
};
@media (max-width:415px) {
  // font-size: 6px;
  margin-top:5px;
};
@media (max-width:380px) {
  // font-size: 5px;
  width:55px;
};
@media (max-width:1075px) {
  display: ${props => props.type !== "mobile" ? "none" : ""};
  width:180px;
  justify-content:center;
  margin: 10px auto;


}
`;
export const DownloadText = styled.div`
font-size: ${props => props.fontSize ? props.fontSize : "18px"};
padding-right: 5px;
@media (max-width:1399px) {
  font-size: 14px;
};
@media (max-width:630px) {
  display:none;
}

`
export const SubHeaderDiv = styled.div`
// margin: 12px 0px;

`

export const SubHeaderUl = styled.ul`
margin:0 auto;
width:80%;
display: flex;
list-style: none;
justify-content: space-between;

`
export const VerticalLine = styled.div`
background:#000000;
height:22px;
width:2px;
margin: ${props => props.margin};
`
export const CustomContainer = styled.div`
display: flex;
    flex-wrap: inherit;
    align-items: center;
    flex-flow: ${props => props.flexFlow};
    justify-content: space-evenly;
    width:100%;
    @media (max-width:1075px) {
      justify-content:space-between;
      margin: 0px 10px 0px 10px;
};
    @media (max-width:630px) {
      justify-content:space-between;
      // margin: 0px 5px 0px 10px;
};
`
export const MainHeaderRow = styled.div`
display:flex;
flex-flow:row;
margin-right: 40px;
@media (max-width:1075px) {
  display:none;
}


`
export const MainHeaderRightRow = styled.div`
display:flex;
flex-flow:row;
  @media (max-width:415px) {
    width:25%;
  }
`
export const BurgerIcon = styled.img`
width: 25px;
margin-top: 10px; 
@media (min-width:1075px) {
  display:none;
}
@media (max-width:730px) {
  max-width: 20px;
  max-height: 20px;
}
`
export const MobileSideBarDiv = styled.div`
position:absolute;
width:30%;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
z-index:2;
@media (min-width:1075px) {
  display: none;
};
@media (max-width:600px) {
  width:100%;
}
`