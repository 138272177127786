import styled from "styled-components";
import { Tab, TabList } from 'react-tabs';

export const MainContainer = styled.div`
  font-size: 16px;
  text-align: center;
  Columnor: #000000;
  padding: 0% 1.5%;
  margin-bottom : 10px;
  height :${props => props.height || "inherit"};
  width :${props => props.width || "inherit"};
  background :${props => props.background || "inherit"}
`;

export const Section = styled.div`
margin-top:20px;
`
export const MatchBanner = styled.div`
background-image:url("/images/matchInfoBack.svg");
background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
  display:flex;
  justify-content:space-evenly;
`
export const ReferAndEarnSpan = styled.span`
@media(max-width:1200px){
 display: ${props => props.display}
};
`;
export const MatchBannerTimerDiv = styled.div`
border: 1px solid #BDBDBD;
background:rgba(0,0,0,0.5);
align-self:center;
padding:60px 30px;
`
export const ColumnDiv = styled.div`
  margin-top: ${props => props.marginTop || "10px"};
  background: #FFFFFF;
  padding:${props => props.padding || "20px"};
  margin-bottom: ${props => props.marginBottom || "0px"};
  border-radius: 8px;
  
`;
export const SeriesMatchColumnDiv = styled.div`
  margin-top: 20px;
  background: #FFFFFF;
  padding:20px;
  border-radius: 8px;
  width: 100%;
  // margin-left: 10px;
`;
export const LeaderboardBlock = styled.div`
     background:#EAEDF1;
     padding: 8px 30px;
     border-radius: 10px;
`
export const LeaderboardText = styled.div`
    font-size:18px;
`
export const LeaderboardImage = styled.img`
    width: 30px;
`
export const PredictWinnerText = styled.div`
    padding-right: 40px;
`
export const ShareImage = styled.img`
    //  max-width: 350px;
    width: 100%;
    cursor: pointer;
     
//  padding :${props => props.padding || "20px 0px"};
    // margin-top :${props => props.marginTop || "20px"};
       @media (max-width:1199px) {
  //  display:none;
    }

      @media (max-width:1199px) {
display:${props => props.display || "none"};
    }
`
export const ShareCodeContainer = styled.div`
    padding:${props => props.padding || "10px 15px"}; 
`
export const ShareCodediv = styled.div`
    display:flex;
    justify-content:space-between;
    padding: 10px 15px;
    border-radius : 17px;
    background: #f5f5f5;
    border: 1px dashed #dddddd;
    
`
export const ShareButton = styled.div`
    border : 2px solid #7DC6FC;
    display:flex;
    justify-content:space-evenly;
    border-radius : 35px;
    background: #E8F7FF;
    cursor : pointer;
    padding: ${props => props.padding || "6px 20px"};
    width: ${props => props.width || "140px"};
    margin-left: ${props => props.shareButtonMarginLeft};
    @media (max-width:1200px) {
     width:95%;
     justify-content:center;
     padding:6px 2px;
    //  margin:20px 10px;
    }
    
`
export const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:  ${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
margin-right:${props => props.marginRight || "0px"};
margin-top: ${props => props.marginTop || "0px"};
width:${props => props.width};
margin: ${props => props.margin || "0px"};
padding-left: ${props => props.paddingLeft};
@media (max-width:1065px) {
  font-size: ${props => props.mdFont};
}
@media (max-width:800px) {
  font-size: ${props => props.smFont};
}

@media (max-width:415px) {
  font-size: ${props => props.xsFont};
  padding-left: ${props => props.xsPadding};
}
  @media (max-width:375px) {
  font-size: ${props => props.xxsFont};

}
`
export const SubHeading = styled(Heading)`
font-weight: ${props => props.fontWeight || "200"};
font-size:  ${props => props.fontSize || "16px"};
color: ${props => props.color || "#000000"};

`
export const MatchLogo = styled(LeaderboardImage)`
width:50px;
margin-right:20px
`
export const MatchVenue = styled.div`
background:#F1F3F5;
`
export const MatchVenueDiv = styled.div`
padding:10px;
text-align:left;
display:flex;
`
export const HorizontalLine = styled.div`
background: ${props => props.background || "linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%)"};
width:${props => props.width || "100%"};
height: ${props => props.height || "1px"};
border-top: ${props => props.borderTop || "0px"};
border-bottom: ${props => props.borderBottom || "0px"};
border: ${props => props.border || "0px"};
margin:${props => props.margin || "20px 0px"};

 @media (max-width:1399px) {
 margin:${props => props.mdMargin};

  }

`

export const HorizontalLineBlue = styled.hr`
border:4px solid #BBE6FF;
width:100px;
background:#BBE6FF;
`
export const TopPlayerdiv = styled.div`
background-color:#F8FAFD;
border: 1px solid #E2E2E2;
padding: 10px 55px;
`
export const TopPlayerImage = styled(LeaderboardImage)`
width:50px
`
export const PlayerScore = styled.span`
font-weight: 600;
`
export const TeamPlayerScore = styled(PlayerScore)`
text-align:left
`

export const TeamScoreDiv = styled.div`
background:#F8FAFD;
border: 1px solid #E2E2E2;
padding: 10px 15px;
margin-top:15px;
border-radius: 5px;
`
export const RecentMatch = styled.div`
background: #F5F5F5;
border-radius:15px;
padding: 8px 15px;
`
export const LastMatchWinBlock = styled.div`
color:#FFFFFF;
background:#359846;
width:30px;
border-radius:5px;
padding: 1px 8px;
`
export const LastMatchLoseBlock = styled(LastMatchWinBlock)`
background:#DC3A40;
`
export const RunRateNumber = styled(LastMatchLoseBlock)`
height: ${props => props.height || "24px"};
background: ${props => props.background};
font-size:13px;
margin: ${props => props.margin}; 
padding: ${props => props.padding || "1px 8px"}; 
`;
export const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border:none;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
padding:0px 20px;
`
export const SecondaryButton = styled(Button)`
background: #FFFFFF;
color: #0BA9DB;
border: 1px solid #0BA9DB;
`
export const WicketSplit = styled.div`
width:${props => props.width};
background: ${props => props.background || "#E8F7FF"};
color: ${props => props.color || "#1890E8"};
border:${props => props.border || "1px solid #7DC6FC"};
padding:${props => props.padding || '15px'};
border-radius:38px;
`
export const VenueInfoHead = styled.div`
background: linear-gradient(90deg, rgba(255, 255, 255, 0) 1.51%, #E6E6E6 48.07%, rgba(245, 245, 245, 0) 98.33%);
display:flex;
justify-content:center;
`
export const PieChartDiv = styled.div`
background:#FDFDFD;
border: 0.5px solid #E2E2E2;
padding : 25px;
`
export const BlueBackground = styled(PieChartDiv)`
background:#F5FCFF;
border-color:#7DC6FC
`
export const VerticalLine = styled.div`
background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%);
width:1px;
height:150px;
`
export const CustomTab = styled(Tab)`
border-radius:10px;
color:${props => props.primary ? "#FFFFFF" : "#0BA9DB"};
border:${props => props.primary ? "none" : "1px solid #0BA9DB"};
background: ${props => props.primary ? "linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)" : "#FFFFFF"} ;
padding:0px 30px;
cursor:pointer;

`;
export const SquadTab = styled(CustomTab)`
border-radius: ${props => props.borderRadius || "24px"};
max-width:${props => props.maxWidth || "130px"};
width:100%;
padding:${props => props.padding || "5px 30px"};
cursor:pointer;
`
export const InningTab = styled(CustomTab)`
border-radius: ${props => props.borderRadius || "24px"};
padding:${props => props.padding || "5px 30px"};
cursor:pointer;
`
export const CustomTabList = styled(TabList)`
list-style-type:none;
display:flex;
justify-content:space-between;
width:100%;
padding:0px;
`
export const PlayerDetailDiv = styled(PieChartDiv)`
width: 300px;
display:flex;
justify-content:space-between;
padding: 15px 20px;
border-radius: 6px;
`
export const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
cursor:${props => props.cursor || "auto"};
  @media (max-width:1399px) {
    width: ${props => props.mdWidth};
  }


`

export const OverInfoDiv = styled.div`
overflow: scroll;
width: 300px;
max-width: 100%;
margin: 0 auto;
`
export const ScrollableContainer = styled.div`
width: 100%;
overflow-x: auto; /* Enable horizontal scrolling */
white-space: nowrap; /* Prevent content from wrapping */
`;

export const BallRunCircle = styled.div`
display: inline-block;
width: ${props => props.width || "50px"};
height:  ${props => props.height || "50px"};
border-radius: 50%;
background:${props => props.background || "#8C91A4"};
color: #FFFFFF;
margin:5px;
font-size: 13px;
`;

export const MatchScoreTab = styled(Tab)`
display:flex;
justify-content:space-between;
background:${props => props.isActive ? "#E8F7FF" : "#FFFFFF"};
border: ${props => props.isActive ? "0.6px solid #7DC6FC" : "none"};
box-shadow:${props => props.isActive ? " 2px 4px 4px 0px #0000000D" : "0px 4px 11px 0px #F2F2F2"};
padding: 15px 40px;
margin-right:${props => props.marginRight || "0px"};
cursor:pointer;

&:focus : {
    border:none;
    outline: none;
};
`
export const AccordianBanner = styled.div`
background: #F5F5F5;
border: 0.5px solid #E2E2E2;
width: 500px;
padding: 10px;
border-radius: 5px;
`
export const AccordianInnerDiv = styled.div`
background: #F5F5F5;
border: 0.5px solid #E2E2E2;
padding: 10px;
border-radius: 5px;
display:flex;
justify-content:space-between;
felx-flow:row;
margin-top:5px;
`
export const BallDetailDiv = styled.div`
background: #F0F0F0;
border-radius : 50px;
padding:  10px 14px;
display:flex;
justify-content:space-between;
margin-bottom:5px;
`
export const Label = styled.div`
width:10px;
height:10px;
background:${props => props.background};
border-radius:50%;
margin:6px 4px 0px 0px;
`
export const LabelContainer = styled.div`
width:70%;
margin: 0 auto;
display:flex;
justify-content:space-evenly;
margin-top:15px;
`
export const FantasyTipsContainer = styled.div`
max-width:500px;
width:100%;
margin-top:15px;
margin-top:40px;
`
export const MatchTimeDiv = styled.div`
background:#FFEFE0;
color:#C57A36;
font-size: 16px;
font-weight:600;
padding: 4px 8px;
border-radius: 35px;
`
export const FancyOddContainer = styled(PieChartDiv)`
background:#FDFDFD;
border: 0.5px solid #E2E2E2;
padding : 25px;
border-radius:5px;
`
export const FancyOddPlayer = styled.div`
width:400px;
display:flex;
justify-content:space-between;
margin-bottom: 15px;
`
export const FancyOddSection = styled.div`
margin : ${props => props.margin};
display:flex;
justify-content:space-between;
`
export const BlogDetailsDiv = styled.div`
display:flex;
justify-content:space-between;
// border-bottom: 1px solid #F1F3F5;
// max-width:600px;
padding:${props => props.padding};
margin:0px auto;
padding-top: ${props => props.paddingTop};
@media (max-width:1065px) {
  // padding:20px 1px;
  max-width:520px
}

`
export const TopBlog = styled.div`
// max-width:520px;
// padding: 10px 0px;
margin: ${props => props.margin || "auto auto 20px auto"};
border-bottom: 1px solid #F1F3F5;


`
export const TopBlogImage = styled.img`
// max-width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
position: relative;
width: 100%;
max-height:${props => props.height || "auto"};
border-radius: 10px;
    @media (max-width:1260px) {
    width: 96%;
    max-width:${props => props.mdWidth};
     min-width:0px;
  }

`
export const BlogImage = styled.img`
max-width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
position: relative;
width: 100%;
border-radius: 10px;
height:${props => props.height || "auto"};
  @media (max-width:1399px) {
    max-width: ${props => props.mdWidth};
  };
    @media (max-width:1065px) {
    width: 40%;
    margin-left: 5px;
  };

    @media (max-width: 375px) {
height:${props => props.xsHeight};
  };

`

export const ReferAndEarnDiv = styled.div`
background:#FFFFFF;
border-radius:8px 17px 8px 8px;
margin-top: ${props => props.marginTop || "20px"};
max-width: ${props => props.maxWidth || "700px"};
height:fit-content;
width:${props => props.width || "100%"};
margin:${props => props.margin || "10px auto"};
padding :${props => props.padding || "auto"};
 @media (max-width: 375px) {
   width: 90%;
  }
`
export const BlogColumnDiv = styled.div`
  margin:${props => props.margin};
  background: #FFFFFF;
  border-radius: 8px;
  padding:${props => props.type === "HOME" ? "25px" : (props.padding || "0px")};
  @media (max-width:600px) {
    margin: ${props => props.smMargin};
  }

`;
export const PaginationContainer = styled.div`
display: flex;
justify-content: center;
margin-top: 20px;
`

export const ReferAndEarnBottomSection = styled.div`
background: #FFFFFF;
`;