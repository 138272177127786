import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  // white-space: nowrap;
  margin-top:${props => props.margin || "10px"};
  border-spacing: 0 20px;
  // padding: 10px;
  background:${props => props.background};

`;
export const FantasyTable = styled(Table)`
border-spacing: 0px;

`
export const TableHead = styled.thead`
  border-radius: 8px;
  background:${props => props.background || "#F5F5F5"};
  padding :${props => props.padding || "0px"};

  
`;
export const TableBody = styled.tbody`
  position: relative;
`;
export const HeadColumn = styled.th`
  color: #000000;
  padding:${props => props.padding || "0px"};
  font-weight: 500;
  width= ${props => props.width};
text-align:  ${props => props.textAlign || "left"};
  font-size: 20px;
  border-left: none;
  border-right: none;
  border-top: none;
  &:first-child {
    padding-right: 40px;
  };
  @media(max-width:1340px){
    font-size: ${props => props.mdFont};
  };
    @media(max-width:450px){
    font-size: ${props => props.xsFont};
     &:first-child {
    padding-right: 20px;
  
  };
    padding:${props => props.smPadding};
  };

`;
export const BodyRow = styled.tr`
  padding: ${props => props.padding || "0px 10px"};
  margin: 0px;
  background :${props => props.background};
  // border-spacing:0px 14px !important;
`;

export const BodyColumn = styled.td`
  font-size: 16px;
  font-weight: 500;
  border-left: none;
  border-right: none;
  width:${props => props.width};
  // display:flex;
  justify-content:flex-end;
  padding:${props => props.padding || "0px 20px  10px 20px"};
  border-top: none;
  border-bottom: 1px solid #edebeb6e;
  text-align: ${props => props.textAlign || "left"};
  vertical-align: middle;

  margin-right: ${props => props.marginRight};
  @media(max-width:580px){
    padding:${props => props.smPadding};
    font-size:${props => props.xsFont};
 
    
  };
`;
export const OverBall = styled.td`  
width: 33px;
height: 26px;
`;
export const FantasyBodyColumn = styled(BodyColumn)`
border: 0.5px solid #E2E2E2;


`;
export const FantasyHeadColumn = styled(HeadColumn)`
border: 0.5px solid #E2E2E2;
&:first-child {
    
  };
  @media(min-width:1200px) and (max-width:1380px){
         &:first-child {
    padding-right: 0px;
  };
};

   @media(max-width:580px){
    font-size:${props => props.smFont};
         &:first-child {
    padding-right: 0px;
    
  };
  padding:${props => props.smPadding};
    };


      @media(max-width: 375px){
    padding:${props => props.xsPadding};
    
  };
`


export const TableHeadMatchList = styled.thead`
  border-radius: 8px;

`;

export const HeadColumnMatchList = styled.th`
  
  padding: 14px 0 14px 26px;
  font-weight: 500;
  text-align: left;
  font-size: 20px;
  border-left: none;
  border-right: none;
  border-top: none;
  &:first-child {
    padding-right: 40px;
  }

`;

export const FancyOddTable = styled.table`
  width: 100%;
  border-collapse: separate;
  // white-space: nowrap;
  margin-top:${props => props.margin || "10px"};
  border-spacing: 0 20px;
  // padding: 10px;
  background:${props => props.background};

`;
export const FantasyOddTableHead = styled.thead`
  border-radius: 8px;
  background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
`;

export const FancyOddHeadColumn = styled.th`
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 12px 10px;  
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
export const FancyOddBodyRow = styled.tr`
  margin: 0px;
  background:#FDFDFD;
`;

export const FancyOddBodyColumn = styled.td`
  vertical-align: middle;
  padding: ${props => props.padding || "10px 20px"};
  border: 0.5px solid #E2E2E2;
  &:first-child {
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:nth-child(2) {
    border-right: none;
    border-left: none;
  }
  &:last-child {
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;