import styled from "styled-components";
import { Tab, TabList } from 'react-tabs';

export const MainContainer = styled.div`
font-size: 16px;
text-align: center;
Columnor: #000000;
padding: 1.5% 2%;
`;

export const AccordianSeriesBanner = styled.div`
background: #FFFFFF;
border: 0.5px solid #E2E2E2;
width: 433px;
padding: 10px;
border-radius: 5px;
margin-top: 10px;
`
export const AccordianInnerDiv = styled.div`
background: #FFFFFF;
border: 0.5px solid #E2E2E2;
padding: 10px;

`
export const ClearButton = styled.button`
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
border: none;
color: white;
padding: 0px 22px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
border-radius: 7px;
width: 90px;
height:48px;

// margin-right: 64px;
@media (max-width:540px) {
height:40px;
     }
`
export const CustomMatchTabList = styled(TabList)`
list-style-type:none;
display:flex;
justify-content:space-evenly;
width:100%;
padding:0px;
// @media (max-width:390px) {
// width:280px
//      }


`
export const CustomMatchTab = styled(Tab)`
color:${props => props.primary ? "#30A4F8" : "#525B7C"};
border-bottom-image:${props => props.primary ? "linear-gradient(99.03deg, #30A4F8 17.68%, #035E9F 98.98%)" : "none"};
border-bottom-width:${props => props.primary ? "4px" : "0px"};
border-bottom-style:${props => props.primary ? "solid" : ""};
padding:0px 30px 12px 30px;
outline: none;
cursor:pointer;

@media (max-width:500px) {
    width:33%;
    padding : 0px ;
    font-size: 13px;
   
     }

`;
export const HorizontalLineToActive = styled.hr`
width:${props => props.width || "0px"};
height: ${props => props.height || "0px"} ;
background: linear-gradient(rgba(48, 164, 248, 1)) !important;
`
export const SeriesBlock = styled.div`
padding: 0px 10px;
min-width: 500px;
max-width:600px;
background: ${props => props.background || ""};
@media (max-width:1150px) {
    min-width:0px
     }

`
export const Section = styled.div`
margin-bottom: ${props => props.marginBottom || "20px"};
margin-top: ${props => props.marginTop};
`
export const ViewAll = styled.div`
color:${props => props.color || "#30A4F8"};
margin-right: 10px;
cursor: pointer;
@media (max-width:540px) {
    font-size:14px;
     }
`
export const Arrow = styled.img`
width: 7px;
cursor: pointer;
`
export const MatchInfoBlock = styled.div`
background: ${props => props.background || ""};
border: 1px solid #EFEFEF;
margin-top: ${props => props.marginTop || ""};
margin-bottom: ${props => props.marginBottom || ""};
border-radius: 5px;
`
export const HorizontalLine = styled.hr`
background: ${props => props.background || "linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%)"};
width:${props => props.width || "500px"};
height: ${props => props.height || "0px"};
border-top: ${props => props.borderTop || "0px"};
border-bottom: ${props => props.borderBottom || "0px"};
border: ${props => props.border || "0px"}
`
export const MatchNamediv = styled.div`
display:flex;
justify-content:space-between;
margin-left:${props => props.marginLeft || "0px"};
margin-right:${props => props.marginRight || "0px"};
padding-bottom:10px;
`
export const CountryLogo = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
`
export const MatchScorediv = styled.div`
display:flex;
justify-content:space-between;
padding-bottom:10px;
`
export const CommentDiv = styled.div`
display:flex;
justify-content:space-between;
padding:${props => props.padding || "12px 20px"} ;
`

export const Select = styled.select`
padding:10px 15px;
border:none;
border-radius:5px;

font-size: 20px;
font-weight: 400;
text-align: left;
width:85%;
margin-right: 10px;
margin: 0px 10px 0px 0px;
border-right: 16px solid transparent;
cursor : pointer;
&:focus :{
    outline:none;
    border:none;
}

@media (max-width:540px) {
    font-size:14px;
     }

`;

export const MatchInfoSection = styled.div`
background: ${props => props.background || ""};
border: 1px solid #EFEFEF;
margin-top: ${props => props.marginTop || ""};
margin-bottom: ${props => props.marginBottom || ""};
border-radius: 5px;
padding: 10px;
`

