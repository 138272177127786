import { createSlice } from '@reduxjs/toolkit'
import { sessionManager } from '../managers/sessionManager';
import { cookiesConstants } from '../constants';

const initialState = {
  currentMatch: {},
  currentScore: {},
  selectBlog: {},
  selectFantasy: {},
  currentPath: '/'
}

export const matchSlice = createSlice({
  name: 'match',
  initialState: initialState,
  reducers: {
    selectMatch: (state, action) => {
      state.currentMatch = action.payload.data;
      sessionManager.setDataInCookies(action.payload.data, cookiesConstants.SELECTED_MATCH)
    },
    currentScore: (state, action) => {
      state.currentScore = action.payload.data;
      sessionManager.setDataInCookies(action.payload.data, cookiesConstants.CURRENT_SCORE)
    },
    currentBlog: (state, action) => {
      state.selectBlog = action.payload;
      sessionManager.setDataInCookies(action.payload.data, cookiesConstants.CURRENT_BLOG)
    },
    currentFantasyNews: (state, action) => {
      state.selectFantasy = action.payload;
    },
    currentRoutePath: (state, action) => {
      state.currentPath = action.payload;
    },

  },
})

export const { selectMatch, currentScore, currentBlog, currentFantasyNews, currentRoutePath } = matchSlice.actions

export default matchSlice.reducer   