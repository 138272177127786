import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Row, Column } from "simple-flexbox";
import MatchInfoContext from "./matchInfoContext";
import { useSelector } from "react-redux";
import { getRemainingTime } from "../../../utility";
import { sessionManager } from "../../../managers/sessionManager";
import { cookiesConstants } from "../../../constants";
import { useState } from "react";

const MatchBannerComponent = () => {
    const { matchInfo } = useContext(MatchInfoContext);
    let currentMatch = useSelector((state) => { return state?.match?.currentMatch })
    const [time, setTime] = useState({
        text: getRemainingTime(currentMatch.start_date).text,
        time: getRemainingTime(currentMatch.start_date).time
    })
    if (!currentMatch || !Object.keys(currentMatch).length) {
        currentMatch = sessionManager.getDataFromCookies(cookiesConstants.SELECTED_MATCH)
    }
    let currentScore = useSelector((state) => { return state?.match?.currentScore || {} })
    if (!currentScore || !Object.keys(currentScore).length) {
        currentScore = sessionManager.getDataFromCookies(cookiesConstants.CURRENT_SCORE)
    }
    
    useEffect(()=>{
        const matchStartInterval = setInterval(()=>{
           const temp = getRemainingTime(currentMatch.start_date);
            setTime({
                text: temp.text,
                time: temp.time
            })
            console.log("calling -->  ")
        },1000)
        return () => clearInterval(matchStartInterval);
    })
    return (
        <MatchBanner>

            <ScoreColumn justifyContent="center">
                <Row style={{alignItems:"center"}}>
                    <Column>
                        <Image src={currentMatch?.localteam_image || "../Assets/dummy1.png"} height={"70px"} width={"70px"} marginRight={"10px"} marginTop={currentScore?.localteam_score ?"10px" : "0px"}
                            onError={(e) => {
                                if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                            }}
                        ></Image>
                    </Column>
                    <Column>

                        <Heading fontSize={"34px"} fontWeight={"500"} color={"#FFFFFF"} margin={currentScore?.localteam_score ? "0px 0px 0px 0px" : "6px 0px 0px 0px"}>{currentMatch?.localteam_short_name}</Heading>

                        {currentMatch?.match_status !== "Not Started" ?
                            <Row justifyContent="end" >
                                <Heading fontSize={"24px"} color={"#FFFFFF"}>{currentScore?.localteam_score}</Heading>
                                <Heading fontSize={"24px"} color={"#AFAFAF"} margin={"0px 0px 0px 5px"}> {currentScore.localteam_overs ? `(${currentScore?.localteam_overs})` : currentScore?.visitorteam_score ? "Yet to bat" : ""}</Heading>

                            </Row>
                            : ""}
                    </Column>
                </Row>
            </ScoreColumn>
            {/* Middle Banner */}
            <CustomColumn >
                <MatchBannerTimerDiv>
                    <>
                        {currentScore?.powerplay ? <PprDiv>PP{currentScore?.powerplay}</PprDiv> : ""}
                        {matchInfo?.match_status === "Not Started" ?
                            <>
                                <Heading fontSize={"30px"} lgFont={"28px"} mdFont={"26px"} smFont={"22px"} xsFont={"19px"} textAlign={"center"} color={"#FFFFFF"}>{time.text}{time.time}</Heading>
                                <VenueInfoHead>{`${matchInfo.match_type} - ${matchInfo.dateOfMatch}    ${matchInfo.timeOfMatch}`}</VenueInfoHead>
                            </>

                            : ""
                        }
                        {matchInfo?.match_status === "Finished" ?
                            <VenueInfoHead mdFont={"12px"} lgFont={"14px"} smFont={"10px"}>{currentMatch?.comment || currentMatch?.toss}</VenueInfoHead>
                            : ""
                        }
                        {matchInfo?.match_status === "In Progress" ?
                            <>
                                {
                                    currentScore.currentEvent ?
                                        <Heading textAlign={"center"} fontSize={"30px"} color={"#FFFFFF"} mdFont={"34px"} lgFont={"38px"} smFont={"28px"}>{currentScore.currentEvent}</Heading>
                                        : ""
                                }
                                <VenueInfoHead margin={"0px 0px 20px 0px"} mdFont={"12px"} lgFont={"14px"} smFont={"10px"}>{currentScore.comment || matchInfo.comment || matchInfo.toss}</VenueInfoHead>
                                <HorizontalLine margin={"0px 0px 20px 0px"}></HorizontalLine>
                                <RunRateDiv justifyContent="space-between">
                                    <Heading fontSize={"24px"} fontWeight={"400"} mdFont={"14px"} lgFont={"16px"} smFont={"12px"} color={"#FFFFFF"} opacity={"0.8"}>CRR: {currentScore?.crr}</Heading>
                                    {currentScore?.rrr ? <Heading fontSize={"24px"} mdFont={"14px"} lgFont={"16px"} smFont={"12px"} fontWeight={"400"} color={"#FFFFFF"} opacity={"0.8"}>RRR: {currentScore?.rrr}</Heading> : ""}
                                    {currentScore?.target ? <Heading fontSize={"24px"} mdFont={"14px"} lgFont={"16px"} smFont={"12px"} fontWeight={"400"} color={"#FFFFFF"} opacity={"0.8"}>TARGET: {currentScore?.target}</Heading> : ""}
                                </RunRateDiv>

                            </>
                            : ""
                        }

                    </>
                </MatchBannerTimerDiv>
            </CustomColumn>

            {/* Right Side */}
            <ScoreColumn justifyContent="center">
                <Row>
                    <Column>
                        <Row justifyContent="flex-end">
                            <Heading fontSize={"34px"} fontWeight={"500"} color={"#FFFFFF"} margin={currentScore?.visitorteam_score ? "0px" : "6px 0px"}>{currentMatch?.visitorteam_short_name}</Heading>
                        </Row>
                        <Row justifyContent="end">
                            {currentMatch?.match_status !== "Not Started" ?
                                <Row justifyContent="end">
                                    <Heading fontSize={"24px"} color={"#FFFFFF"}>{currentScore?.visitorteam_score}</Heading>
                                    <Heading fontSize={"24px"} color={"#AFAFAF"} margin={"0px 0px 0px 5px"}> {currentScore?.visitorteam_overs ? `(${currentScore?.visitorteam_overs})` : currentScore?.localteam_overs ? "Yet to bat" : ""}</Heading>
                                </Row>

                                : ""}
                        </Row>
                    </Column>
                    <Column>
                        <Image src={currentMatch?.visitorteam_image || "../Assets/dummy1.png"} height={"70px"} width={"70px"} marginLeft={"10px"} marginTop={currentScore?.visitorteam_score ? "10px" : "0px"}
                            onError={(e) => {
                                if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                            }}
                        ></Image>
                    </Column>

                </Row>
 
            </ScoreColumn>

        </MatchBanner>
    )
}
export default MatchBannerComponent;

const RunRateDiv = styled.div`

display:flex;
justify-content:space-between;
`
const HorizontalLine = styled.div`
height:1px;
border: 1px solid #808486;
margin:${props => props.margin}

`
const MatchBanner = styled.div`
background-image:url("/images/matchInfoBack.webp");
background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
  display:flex;
  justify-content:space-evenly;
  @media (max-width:360px) {
    nth-child(2) {
        display: none;
    }
  }
`;
const MatchBannerTimerDiv = styled.div`
border: 1px solid #BDBDBD;
background:rgba(0,0,0,0.7);
align-self:center;
padding:10% 30px;
width: 100%;
opacity: 0.8;
position: relative;
min-height:200px;
display: flex;
    flex-flow: column;
    justify-content: center;
@media (max-width : 1060px) {
    padding:4% 30px;

};
`;
const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
margin: ${props => props.margin || "0px"};
opacity: ${props => props.opacity || "1"};
@media (max-width : 1399px) {
    font-size:  ${props => props.lgFont};
};
@media (max-width : 1170px) {
    font-size:  ${props => props.mdFont};
};
@media (max-width : 1060px) {
    font-size:  ${props => props.smFont};
};
@media (max-width : 580px) {
    font-size:  ${props => props.xsFont};
};
`
const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.marginRight || "0px"};
height:${props => props.height || "auto"};
margin-top: ${props => props.marginTop };
margin-left: ${props => props.marginLeft || "auto"};
`
const VenueInfoHead = styled.div`
background: linear-gradient(90.02deg, rgba(0, 0, 0, 0.35) -3.57%, #525D64 47.99%, rgba(0, 0, 0, 0.38) 103.7%);
color:#FFFFFF;
display:flex;
justify-content:center;
font-weight:400;
margin:${props => props.margin};
@media (max-width : 1399px) {
    font-size:  ${props => props.lgFont};
};
@media (max-width : 1170px) {
    font-size:  ${props => props.mdFont};
};
@media (max-width : 1060px) {
    font-size:  ${props => props.smFont};
};
`
const CustomColumn = styled.div`
display:flex;
flex-flow:column;
justify-content:center;
width:35%;
@media(max-width: 900px){
    width:80%;
    padding:10%;
};
@media(max-width: 480px){
    width:100%;
    padding:8%;
};
`
const PprDiv = styled.div`
font-weight: 400;
font-size: 20px;
text-align: center;
color: white;
background-color: red;
border-radius: 0px 50px 50px 0px;
width: 50px;
position: absolute;
left: -1%;
bottom: 92%;
`
const ScoreColumn = styled(Column)`
@media(max-width: 900px){
    display:none !important;
}
`