import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import { sessionManager } from '../../../../managers/sessionManager';
import { cookiesConstants } from '../../../../constants';
import { redisApiBaseUrl } from '../../../../config';
import { axiosRequest } from '../../../../utility/axios';
import CricFunInstallCoinBurnDialog from '../../Games/CricFunInstallCoinBurnDialog';
import { useSelector } from 'react-redux';
import Transactions from './AllTransactions';
import GetFreeCoinDialog from '../../Coin/GetFreeCoin';
import MyReferTab from './HowItWorkDialog';
import { openPlayStore } from '../../../../utility';
import { useNavigate } from "react-router-dom";
import CricFunInstallSpinDialog from '../../Games/CricFunInstallSpinDialog';


export const CoinDiv = styled.div`
background-color:#FFFFFF;
border-radius: 5px;
padding: 10px 15px;
`
export const TotalCoinDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

const MainBox = styled.div`
padding: 12px 40px 30px 40px;
max-width: 957px;
@media(max-width:530px){
    padding: 12px 10px 30px 10px;
};
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align: ${props=>props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
margin-top: ${props => props.marginTop || "0px"};
margin-right: ${props => props.marginRight || "0px"};
margin-left: ${props => props.marginLeft || "0px"};
margin-top: ${props => props.marginTop || "0px"};
@media(max-width:580px){
    font-size:  ${props => props.mdfontSize};
};
`
const Image = styled.img`
width:${props => props.width || "23px"};
margin-right:${props => props.marginRight || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
margin-left:${props => props.marginLeft || "0px"};
margin: ${props => props.margin};
position:${props => props.position || "static"};
cursor :${props=>props.cursor || "auto"};
@media (max-width:550px) {
    margin-right: ${props => props.mdMarginRight || "auto"};
    margin-left: ${props => props.mdMarginLeft || "auto"};
    width:${props => props.smWidth|| "15px"};
    height: ${props => props.smHeight};
    margin-top: ${props => props.smMarginTop};
    margin: ${props => props.smMargin};

  }
`
const ColoumDiv = styled.div`
background-color: #F1F3F4;
min-width:500px;
@media(max-width:580px){
    min-width: 450px;
};
@media(max-width:530px){
    min-width: 400px;
};
@media(max-width:470px){
    min-width: 300px;
};
`
export const OtherActivityColumnDiv = styled.div`
  margin-top: 25px;
  background: #FFFFFF;
  padding:20px 10px 1px 10px;
  border-radius: 8px;
  margin-bottom:10px;
  
`
export const OtherActivityDiv = styled.div`
background: #F7F7F8;
border-radius:5px;
display:flex;
justify-content:space-between;
padding: 10px;
width:100%;
margin-bottom:20px;
cursor : pointer;
@media (max-width:550px) {
    
}
`
export const OtherActivityTextDiv = styled.div`
display:flex;
justify-content:space-between;
padding-right: 4px;

`
export const Button = styled.button`
border-radius:20px;
color:#FFFFFF;
border:none;
font-size:12px;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
padding:0px 20px;
width:105px;
font-weight:500;
`
export const Arrow = styled.img`
width: 10px;
`
export const OtherCoinMainDiv = styled.div`
background: #EFF9FE;
padding: 5%;
border-radius: 5px;
margin: 10px auto;
max-width:380px;
width:100%;
border : 1px solid #C3E6FF;
`
export const VerticalLine = styled.div`
background: linear-gradient(180deg, rgba(239, 239, 239, 0) 0%, rgba(83, 230, 255, 1) 100%, rgba(239, 239, 239, 0) 0%);
width:1px;
height: ${props => props.height || "60px"};
`
export const BurnButton = styled.button`
background: linear-gradient(156.37deg, #0DD281 15.22%, #00A362 78.97%),linear-gradient(0deg, #FDE880, #FDE880);
border-radius: 20px;
text-aling:center;
color: #FFFFFF;
border: none;
margin-left: 100px;
width: 300px;
padding: 10px 0px;
margin: 0px auto 10px auto;

`
export const MyBalance = styled.div`
display: flex;
justify-content: center;
align-items: center;
`
export const CloseButton = styled.div`
display: flex;
justify-content: right;
align-items: right;
`

const MyBalanceDialog = (props) => {


let navigate = useNavigate();
    const data = [{
        image: "../Assets/transcations.svg",
        text: "All Transactions",
    },
    {
        image: "../Assets/getfreecoin.svg",
        text: "Get Free Coins",

    },
    {
        image: "../Assets/dailyspin.svg",
        text: "Daily Spin",

    },
    {
        image: "../Assets/refer&earn.svg",
        text: "Refer & Earn",

    },
    {
        image: "../Assets/help.svg",
        text: "Help",


    }
    ];
    const [toggleDialog, setToggleDialog] = useState({
        burnCoins: false,
        transactions: false,
        freeCoins: false,
        spin : false ,
        myReferrals: false
    })
    const handleDialogToggle = (name, value) => {
        setToggleDialog({
            ...toggleDialog,
            [name]: value
        })

    }

    const walletInfo = sessionManager.getDataFromCookies(cookiesConstants.WALLET_INFO)
    const onClickHandler = (type) => {
        if (type === "All Transactions") {
            handleDialogToggle("transactions", true)
        }
        else if (type === "Get Free Coins") {
            handleDialogToggle("freeCoins", true)
        }
        else if (type === "Refer & Earn") {
            handleDialogToggle("myReferrals", true)
        }
        else if (type === "Daily Spin") {
          handleDialogToggle("spin", true) 
        }
        else if (type== "Help"){

            navigate("/contactUS");
        }
        props.handleDialogToggle("wallet", false)
    }

    return (
        <>
            <Transactions toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></Transactions>
            <GetFreeCoinDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></GetFreeCoinDialog>
            <MyReferTab toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></MyReferTab>
            <CricFunInstallSpinDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></CricFunInstallSpinDialog>


            <Dialog open={props?.toggleDialog?.wallet && sessionManager.getDataFromCookies(cookiesConstants.IS_LOGGED_IN)}
             sx={{
            "& .MuiDialog-paper": {
                margin: "20px 10px",
                borderRadius:"4px",
                width:"100%"
            },
        }}
            >

                <ColoumDiv>
                    <Row justifyContent='end'>
                        <Image width={"15px"}  smWidth={"12px"} mdMarginRight={"10px"} marginRight={"10px"} marginTop={"10px"} position={"fixed"} src="../Assets/cross.svg" cursor ={"pointer"} onClick={() => props.handleDialogToggle("wallet", false)}></Image>
                    </Row>
                    <Row justifyContent='center'>
                        <MyBalance>
                            <Heading paddingBottom={"2px"} marginTop={"10px"} textAlign={"center"} fontSize={"20px"} mdfontSize={"20px"} fontWeight={"500"} >My Balance</Heading>
                        </MyBalance>
                    </Row>
                    <MainBox>

                        <Row justifyContent='center'>
                        </Row>
                        <CoinDiv>
                            <Column>
                                <TotalCoinDiv>
                                    <Column>
                                        <Heading textAlign={"center"}  color={"#000000"} fontSize={"24px"} mdfontSize={"18px"} fontWeight={"500"} >Total Coins </Heading>
                                        <Row justifyContent='center' alignItems='center'>
                                            <Image width={"30px"} height={"30px"} marginRight={"5px"} fontSize={"25px"} smMarginTop={"15px"} smHeight={"35px"} smWidth={"35px"}  src="https://storage.googleapis.com/cricfun-images/img-1716976427090.png"></Image>
                                            <Heading textAlign={"center"} color={"#000000"} fontSize={"35px"} mdfontSize={"18px"} fontWeight={"600"}>{(walletInfo?.rewards + walletInfo?.winning) || 0}</Heading>
                                        </Row>
                                    </Column>
                                </TotalCoinDiv>

                                <Row>
                                    <OtherCoinMainDiv>
                                        <Row justifyContent='space-evenly'>
                                            <Column>
                                                <Row>
                                                    <Heading color={"#30A4F8"} fontWeight={"400px"} fontSize={"17px"} > Winnings</Heading>
                                                </Row>
                                                <Row   justifyContent='center'alignItems='center'>
                                                    <Image width={"20px"} margin={"9px 4px"} height={"20px"} smHeight={"25px"} smWidth={"25px"} smMargin={"6px"} src="https://storage.googleapis.com/cricfun-images/img-1716976427090.png"></Image>
                                                    <Heading textAlign={"center"} fontWeight={"500"}fontSize={"20px"} mdfontSize={"20px"} >{(walletInfo?.winning )|| 0}</Heading>
                                                </Row>
                                            </Column>
                                            <VerticalLine></VerticalLine>
                                            <Column>
                                                <Row  justifyContent='center'>
                                                    <Heading color={"#30A4F8"} fontWeight={"400px"} fontSize={"17px"} > Rewards</Heading>
                                                </Row>
                                                <Row   justifyContent='center' alignItems='center'>
                                                    <Image width={"20px"} margin={"9px 4px"} height={"20px"} smHeight={"25px"} smWidth={"25px"} smMargin={"5px"} src="https://storage.googleapis.com/cricfun-images/img-1716976427090.png"></Image>
                                                    <Heading textAlign={"center"} fontWeight={"500"} fontSize={"20px"} mdfontSize={"20px"}>{(walletInfo?.rewards)|| 0}</Heading>
                                                </Row>
                                            </Column>
                                        </Row>
                                    </OtherCoinMainDiv>
                                </Row>
                                <Row>
                                    <BurnButton onClick={() => { props.handleDialogToggle("wallet", false); handleDialogToggle("burnCoins", true) }}>BURN COINS</BurnButton>
                                </Row>
                            </Column>
                        </CoinDiv>
                        <OtherActivityColumnDiv>


                            {data.map((item) => (
                                <OtherActivityDiv onClick={() => onClickHandler(item.text)}>
                                    <OtherActivityTextDiv>
                                        <Image marginRight={"20px"}  mdMarginRight={"10px"}  src={item.image}></Image>

                                        <Heading fontSize={"18px"} fontWeight={"500"}>{item.text} </Heading>

                                    </OtherActivityTextDiv>
                                    <Arrow src="../Assets/rightarrowblack.svg"></Arrow>
                                </OtherActivityDiv>

                            ))}
                        </OtherActivityColumnDiv>
                    </MainBox>
                </ColoumDiv>
            </Dialog>
            <CricFunInstallCoinBurnDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></CricFunInstallCoinBurnDialog>
        </>
    )
}

// const TotalCoins = () => {
//     return(
//         <>
//         <CoinDiv>
//             <Column>
//             <TotalCoinDiv>
//                 <Column>
//                 <Heading textAlign= {"center"}  color={"#000000"} fontSize={"28px"} fontWeight={"600"}  marginLeft={"14px"}>Total Coins </Heading>
//                 <Row >
//             <Image width={"15px"} marginRight={"5px"}  src="../Assets/coin.svg"></Image> 
//             <Heading  textAlign= {"center"}  color={"#000000"} fontSize={"42px"} fontWeight={"700"}>2000.22</Heading>
//             </Row>
//                 </Column>
//             </TotalCoinDiv>

//             <Row>
//                 <OtherCoins></OtherCoins>
//             </Row>
//             <Row>
//                 <BurnButton>BURN COINS</BurnButton>
//             </Row>
//             </Column>
//         </CoinDiv>
//         </>
//     )
// }
// const OtherCoins = () => {
//     return (
//         <>
//             <OtherCoinMainDiv>
//                 <Row justify-content='space-between'>
//                     <Column>
//                         <Row>
//                             <Heading color={"#30A4F8"} fontWeight={"400px"} fontSize={"14px"} marginLeft={"15px"}> Winnings</Heading>
//                         </Row>
//                         <Row >
//                             <Image width={"15px"} marginRight={"5px"} src="../Assets/coin.svg"></Image>
//                             <Heading textAlign={"center"} fontSize={"16px"} marginRight={"78px"}>2000.22</Heading>
//                         </Row>
//                     </Column>
//                     <VerticalLine></VerticalLine>
//                     <Column>
//                         <Row>
//                             <Heading color={"#30A4F8"} fontWeight={"400px"} fontSize={"14px"} marginLeft={"100px"}> Rewards</Heading>
//                         </Row>
//                         <Row >
//                             <Image width={"15px"} marginRight={"5px"} marginLeft={"100px"} src="../Assets/coin.svg"></Image>
//                             <Heading textAlign={"center"} fontSize={"16 px"} >20</Heading>
//                         </Row>
//                     </Column>
//                 </Row>
//             </OtherCoinMainDiv>
//         </>
//     )
// }


export default MyBalanceDialog