import styled from "styled-components";

export const MainBox = styled.div`
padding:0px 50px 30px 50px;
max-width:450px;
`;

export const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:left;
font-style: ${props => props.fontStyle || ""};
font-family: ${props => props.fontFamily || ""};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
padding-left: ${props => props.paddingLeft || "0px"};
padding-top: ${props => props.paddingTop || "0px"};
margin-top: ${props => props.marginTop || "0px"};
margin-left: ${props => props.marginLeft || "0px"};
@media(max-width:500px){
   
    padding: ${props => props.smPadding};
};
`
export const Input = styled.input`
font-size:16px;
border:1px solid #CFCFD3;
width:100%;
border-radius:25px;
height: 50px;
padding: 0px 20px;
margin: 20px 0px;
`
export const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border:none;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
height: 50px;
width: 100%;
border-radius: 25px;
font-size:18px;
margin-bottom: 10px;
`
export const HorizontalLine = styled.hr`
border:1px solid #DEDEDE;
width:100%;
`
export const Image = styled.img`
background-color :${props =>props.background || ""};
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
position : ${props => props.position || "relative"};
top: ${props => props.top || "0px"};
left: ${props => props.left || "0px"};
border-radius: ${props =>props.borderRadius || "50%"};
cursor: ${props=>props.cursor || "auto"};
padding :${props => props.padding || "0px"};


@media(max-width:530px){
   left: ${props => props.smLeft};
      width: ${props => props.smWidth};
  
};
`
export const Section = styled.div`
margin-bottom:25px;
`
export const LoginPop = styled.div`
display:flex;
justify-content:space-between;
padding-top:5px;
cursor:pointer;
align-items: center;

`;
export const LoginColumn = styled.div`
display:flex;
justify-content:center;
flex-flow:column;
@media (max-width:600px) {
   display:none;
   };
`;