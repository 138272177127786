import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import { Radio, RadioGroup } from '@mui/material';
import { redisApiBaseUrl } from '../../../config';
import { axiosRequest } from '../../../utility/axios';
import { failureToast, getDeviceType, successToast, validateEmail } from '../../../utility';
import moment from 'moment';
import { sessionManager } from '../../../managers/sessionManager';
import { cookiesConstants } from '../../../constants';
import { useDispatch } from 'react-redux';
import { updateUserState } from '../../../reducers/user';
import AuthContext from './context';
import CoinWinDialog from './CoinWinDialog';



const MainBox = styled.div`
padding: 12px 50px 30px 50px;
max-width:400px;
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:left;
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
@media(max-width:500px){
font-size: ${props => props.smFont};
};
`
const Input = styled.input`
font-size:14px;
border:1px solid #CFCFD3;
width:100%;
border-radius:6px;
height: 40px;
padding: 0px 20px;
margin: 10px 0px;
background-color:#F5F5F5;
@media(max-width:500px){
    height:30px;
    font-size:${props => props.smFontSize}
};
`
const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border:none;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
height: 50px;
width: 100%;
border-radius: 25px;
font-size:18px;
margin-bottom: 10px;
@media(max-width:500px){
height:40px;
font-size:15px;
};
`
const HorizontalLine = styled.hr`
border:1px solid #DEDEDE;
width:100%;
`
const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
margin-left:${props => props.marginLeft || "0px"};


`
const Section = styled.div`
margin-bottom:25px;
`
const PersonalDetailsDialog = () => {
    const { toggleDialog, closeAllDialogs, handleDialogToggle } = useContext(AuthContext);
    const dispatch = useDispatch()
    const [details, setDetails] = useState({
        full_name: '',
        team_name: '',
        email: '',
        password: '',
        gender: '',
        confirm_pass: ''
    })
    const onHandleInput = (event, type) => {
        if (type)
            setDetails({
                ...details,
                gender: event.target.value
            })
        else
            setDetails({
                ...details,
                [event.target.name]: event.target.value
            })
    }
    const updatePersonalDetails = async () => {
        const isValid = validateDetails()
        if (!isValid) return;
        const url = `${redisApiBaseUrl}/userRedisUpdateProfile`;
        const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
        const request = {
            "user_id": profileDetails.user_id,
            "device_id": profileDetails.device_id,
            "device_type": profileDetails.device_type,
            ...details
        }
        const personalDetails = await axiosRequest("POST", url, request)
        if (!personalDetails || personalDetails.errors || !personalDetails.status) {
            failureToast(personalDetails?.message || "Unable to update")
            return
        }
        successToast("Profile Updated")
        dispatch(updateUserState(personalDetails))
        handleDialogToggle("freeCoins", true)
        closeAllDialogs();
        // handleDialogToggle("signupCoins", true);

    }
    const handleCloseAll = () => {

        // handleDialogToggle("signupCoins", false);
        // handleDialogToggle("join", false);
        // handleDialogToggle("personalDetails", false);
        closeAllDialogs();
    }
    const validateDetails = () => {
        if (!details.full_name) {
            failureToast("Name is Required")
            return false;
        }
        else if (!details.team_name) {
            failureToast("Team Name is Required")
            return false;
        }
        else if (!details.email) {
            failureToast("Email is Required")
            return false;
        }
        else if (!validateEmail(details.email)) {
            failureToast("Invalid Email")
            return false;
        }
        else if (!details.password) {
            failureToast("Password is Required")
            return false;
        }
        else if (!details.confirm_pass) {
            failureToast("Conform Password is Required")
            return false;
        }
        else if (details.confirm_pass !== details.password) {
            failureToast("Confirm Password should be same as password")
            return false;
        }
        else if (!details.gender) {
            failureToast("Gender is Required")
            return false;
        }
        return true
    }

    return (
        <>
            <Dialog open={toggleDialog?.personalDetails} sx={{
                "& .MuiDialog-paper": {
                    margin: "20px 10px",
                    borderRadius: "5px"
                },




            }}>
                <MainBox>
                    <Heading fontSize={"18px"} smFont={"16px"} fontWeight={"600"}>Personal Details</Heading>

                    <Input placeholder='Name' name='full_name' smFontSize={"12px"} value={details.full_name} onChange={onHandleInput}></Input>
                    <Input placeholder='Team Name' name='team_name' smFontSize={"12px"} value={details.team_name} onChange={onHandleInput}></Input>
                    <Input placeholder='Email' name='email' smFontSize={"12px"} value={details.email} onChange={onHandleInput}></Input>
                    <Input placeholder='Set Password' name='password' smFontSize={"12px"} value={details.password} onChange={onHandleInput}></Input>
                    <Input placeholder='Confirm Password' name='confirm_pass' smFontSize={"12px"} value={details.confirm_pass} onChange={onHandleInput}></Input>

                    <Heading color={"#5F5F5F"} fontWeight={"400"} fontSize={"12px"}>Gender</Heading>
                    <Section>
                        <Row justifyContent='space-between'>
                            <div>
                                <input type="radio" name="group" value="male" onChange={(event) => onHandleInput(event, "gender")}></input>
                                <label for="male">Male</label>
                            </div>

                            <div>
                                <input type="radio" name="group" value="female" onChange={(event) => onHandleInput(event, "gender")}></input>
                                <label for="female">Female</label>
                            </div>
                            <div>
                                <input type="radio" name="group" value="others" onChange={(event) => onHandleInput(event, "gender")}></input>
                                <label for="others">Others</label>
                            </div>
                        </Row>
                    </Section>

                    <Button onClick={updatePersonalDetails}>Update Profile</Button>
                </MainBox>
            </Dialog>
            <CoinWinDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle} handleCloseAll={handleCloseAll}></CoinWinDialog>

        </>

    )
}
export default PersonalDetailsDialog