import { useEffect, useState } from "react"
import { Tabs } from 'react-tabs';
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";
import SeriesLiveMatches from "./MatchListing";
import { redisApiBaseUrl } from "../../../config";
import { axiosRequest } from "../../../utility/axios";
import { failureToast } from "../../../utility";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../reducers/user";
import { Grid } from "@mui/material";
import { styled } from "styled-components";
import { Helmet } from "react-helmet";
import { FirstColumn } from "../MatchInfo/InfoTab";
const { TabPanel } = require("react-tabs")
const { Column, Row } = require("simple-flexbox")
const { default: SubHeader } = require("../../components/Header/SubHeader")
const { ColumnDiv } = require("../MatchInfo/MatchInfoStyle")
const { MainContainer, CustomMatchTabList, CustomMatchTab, ClearButton, Select } = require("./MatchListStyle")


const Matches = () => {
    const [seriesList, setSeriesList] = useState({
        allSeries: [],
        filteredSeries: []
    })
    const [tabIndex, setTabIndex] = useState(0)
    const [selectedSeries, setSelectedSeries] = useState("")
    const dispatch = useDispatch()
    const getMatchList = async (tab) => {
        dispatch(setLoader({ data: true }))
        const url = `${redisApiBaseUrl}/matchesList?page=1&size=100&match_type=${tab}`;
        const response = await axiosRequest("GET", url, {})
        if (!response || response.errors || !response.status) {
            // failureToast(response.message || "Unable to load data")
            setSeriesList({})
            dispatch(setLoader({ data: false }))
            return
        }
        setSeriesList({
            ...seriesList,
            allSeries: response?.data?.matchGroup,
            filteredSeries: response?.data?.matchGroup
        })
        dispatch(setLoader({ data: false }))
    }
    useEffect(() => {
        getMatchList("live")
    }, [])

    const changeTab = (tabIndex) => {
        setTabIndex(tabIndex)
        getMatchList(getMatchType(tabIndex))
    }

    const filterData = (event) => {
        let seriesId = event?.target?.value || "";
        setSelectedSeries(seriesId)
        if (!seriesId) {
            getMatchList(getMatchType(tabIndex))
            return
        }
        let tempData = seriesList.allSeries;
        const filteredData = tempData.filter(({ series_id }) => series_id == seriesId)
        setSeriesList({
            ...seriesList,
            filteredSeries: filteredData
        })
    }
    const getMatchType = (tabIndex) => {
        if (tabIndex === 0)
            return "live"
        else if (tabIndex === 1)
            return "upcoming"
        else
            return "finished"
    }

    return (
        <>

            <SubHeader />

            <MainContainer>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                    <Grid item md={12} lg={3.5} width={"100%"}>
                        {FirstColumn()}


                    </Grid>
                    <Grid item md={12} lg={5} width={"100%"} display={"flex"} justifyContent={"center"} >
                        <MiddleColumnMainDiv>
                            {SeriedDropDown(seriesList, filterData, selectedSeries)}
                            <MatchListTab tabIndex={tabIndex} changeTab={changeTab} seriesList={seriesList.filteredSeries}></MatchListTab>
                        </MiddleColumnMainDiv>
                    </Grid>
                    <Grid item md={12} lg={3.5} width={"100%"} display={"flex"} justifyContent={"center"}>

                    </Grid>
                </Grid>
            </MainContainer>

        </>
    )
}
const SeriedDropDown = (seriesList, filterData, selectedSeries) => {
    const { allSeries } = seriesList;
    return (
        <Row justifyContent="space-between" alignItems="center" style={{ marginTop: "10px" }}>
            <Select type="select" value={selectedSeries} onChange={(event) => { filterData(event) }}>
                <option value={""}>Select Series</option>
                {allSeries && allSeries.length && allSeries.map((series) => (
                    <option value={series.series_id}>{series.seriesName}</option>
                ))}
            </Select>
            <ClearButton onClick={() => filterData("clear")}>Clear</ClearButton>
        </Row>
    )
}

const MatchListTab = (props) => {
    const { tabIndex, changeTab, seriesList } = props
    return (
        <>
            <Row>
                <ColumnDiv marginRight={"0px"}>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
                        <CustomMatchTabList>
                            <CustomMatchTab primary={tabIndex === 0 ? true : false}><h1 style={{ fontSize: "13px", margin: "5px 0px 0px 0px" }}>Live Cricket Score</h1>
                            </CustomMatchTab>
                            <CustomMatchTab primary={tabIndex === 1 ? true : false}>Upcoming
                            </CustomMatchTab>
                            <CustomMatchTab primary={tabIndex === 2 ? true : false}>Finished
                            </CustomMatchTab>
                        </CustomMatchTabList>
                        <TabPanel>
                            <SeriesLiveMatches seriesList={seriesList}></SeriesLiveMatches>
                        </TabPanel>
                        <TabPanel >
                            <SeriesLiveMatches seriesList={seriesList}></SeriesLiveMatches>
                        </TabPanel>
                        <TabPanel >
                            <SeriesLiveMatches seriesList={seriesList}></SeriesLiveMatches>
                        </TabPanel>
                    </Tabs>
                </ColumnDiv>
            </Row>
            <Row>
                <ColumnDiv marginRight={"0px"}>
                    <p > Keep updated with live cricket scores and news
                        for all ongoing cricket matches or tournaments,
                        including Men's and Women's T20s, ODIs, Tests,
                        First-class, and List A cricket, among others formats.
                        Cricfun's live cricket scoring feature provides
                        extensive coverage of ongoing matches, offering
                        ball-by-ball updates.
                        Whether it's franchise leagues or major international
                        fixtures, you'll feel the thrill of cricket here
                    </p>
                </ColumnDiv>
            </Row>
        </>
    )
}
const ReferAndEarnGrid = styled(Grid)`
display:flex;
justify-content:center;
@media (max-width:900px) {
  display:none;
   }
`
export const MiddleColumnMainDiv = styled.div`
@media (max-width:1400px) {
    max-width:540px
     }
@media (max-width:1150px) {
    max-width:500px
     }
@media (max-width:1060px) {
    max-width:420px
         }
@media (max-width:900px) {
    max-width:600px
                 }
`

export default Matches