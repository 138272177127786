import React, { useState } from "react"
import { Row, Column } from "simple-flexbox"
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import match, { currentScore, selectMatch } from "../../../reducers/match";
import moment from "moment";
import { ColumnDiv, Heading, HorizontalLine, MainContainer, SeriesMatchColumnDiv } from "../pagesStyle";
import { CountryLogo, CustomMatchTab, CustomMatchTabList, MatchInfoSection } from "../MatchList/MatchListStyle";
import { redisApiBaseUrl } from "../../../config";
import { axiosRequest } from "../../../utility/axios";
import { useEffect } from "react";
import { TabPanel, Tabs } from "react-tabs";
import SubHeader from "../../components/Header/SubHeader";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";
import { MatchHeader, MatchInfoBlock } from "../MatchList/LiveBettingMatchList";
import { getDate } from "../../../utility";
import { AllMatchOfSeries } from "../MatchList/MatchListing";
import { Grid } from "@mui/material";
import { ReferAndEarnGrid } from "../MatchInfo/Blogs";
import { MiddleColumnMainDiv } from "../MatchList/Matches";

const ViewAllTab = () => {

    const [matchList, setMatchList] = useState([]);
    const [seriesName, setSeriesName] = useState('');
    const getMatchList = async (series_id, series_name) => {
        const url = `${redisApiBaseUrl}/getMatchListBySeries?series_id=${series_id}&page=1&size=100`;

        const response = await axiosRequest("GET", url, {})

        if (!response || response.errors || !response.status) {
            setMatchList([])
            return
        }
        setMatchList(response.data.matchListData)

    }


    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)

        // const series_id = "127854"
        const series_id = queryParameters.get("series_id");
        const series_name = queryParameters.get("series_name")
        setSeriesName(series_name);
        getMatchList(series_id, series_name)
    }, [])
    return (
        //  <ColumnDiv>
        //      <Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
        //          <CustomMatchTabList>
        //              <CustomMatchTab primary={tabIndex === 0 ? true : false}> Series Matches
        //              </CustomMatchTab>
        //          </CustomMatchTabList>
        //          <TabPanel>

        //          </TabPanel>

        //      </Tabs>
        //  </ColumnDiv>
        <>
            <SubHeader />
            {/* <MainContainer >
                <Grid container >
                    <ReferAndEarnGrid item md={3.5} lg={3.5} >
                    <ReferAndEarn margin="0px" marginTop="0px" shareButtonMarginLeft={"20px"} shareButtonWidth={"111px"}></ReferAndEarn>
                    </ReferAndEarnGrid>
                    <Grid item md={12} lg={5} display={"flex"} justifyContent={"center"} m={"0px auto"}>
                        {/* <MiddleColumnMainDiv> */}
                        {/* {SeriesMatches(matchList)} */}
                        {/* </MiddleColumnMainDiv> */}
                    {/* </Grid>
                    <ReferAndEarnGrid item md={3.5} lg={3.5} >
                    
                    </ReferAndEarnGrid>
                </Grid>
            </MainContainer> */} 
             <MainContainer >
                <Grid container>
                    <ReferAndEarnGrid item md={3.5} lg={3.5} >
                        {/* {ReferAndEarn()} */}
                        {/* <ReferAndEarn shareButtonMarginLeft={"20px"} ></ReferAndEarn> */}
                        <ReferAndEarn margin="0px" marginTop="0px" shareButtonMarginLeft={"20px"} shareButtonWidth={"111px"} width={"97%"}></ReferAndEarn>

                    </ReferAndEarnGrid>
                    <Grid item md={5} lg={5} display={"flex"} justifyContent={"center"} width={"100%"}>
                    {SeriesMatches(matchList, seriesName)}
                    </Grid>
                    <ReferAndEarnGrid md={3.5} lg={3.5}  >

                    </ReferAndEarnGrid>
                </Grid>
            </MainContainer>

        </>
    )
}

const SeriesMatches = (matchList, seriesName) => {
    console.log("seriesName",seriesName);
    return (
        <>
         <SeriesMatchColumnDiv>
         <Column>
            <Heading paddingBottom={"20px"} fontSize={"22px"} smFont={"15px"} textAlign="center">{seriesName}</Heading>
        </Column>
         <AllMatchOfSeries matchList = {matchList} type={"series"} viewAll ={true}></AllMatchOfSeries>
         </SeriesMatchColumnDiv>
        
            {/* <SeriesMatchColumnDiv>
                {matchList && matchList.length && matchList.map((match) => (
                    <MatchInfoSection marginBottom={"10px"} marginTop={"10px"}>

                        <Row justifyContent="space-between" > 
                            <Heading fontWeight={"200px"} fontSize={"18px"} margin={"5px"}>{getDate(match.start_date).time}</Heading>
                            <Heading fontWeight={"200px"} fontSize={"18px"} margin={"5px"}>{match.match_status}</Heading>

                        </Row>

                        <HorizontalLine border={"0px solid #DEDEDE"} margin={"0px"}></HorizontalLine>

                        <Column justifyContent="space-between">
                            <Row justifyContent="space-between">
                                <>
                                    <CountryLogo width={"30px"}  src={match.localteam_image || "../Assets/dummy.png"}
                                        onError={(e) => {
                                            if (e.target.src !== "../Assets/dummy.png") { e.target.onerror = null; e.target.src = "../Assets/dummy.png" }
                                        }}
                                    ></CountryLogo>
                                    <Heading marginRight={"30px"} >{match.localteam_short_name}</Heading>
                                </>

                                <Row>
                                    {/* <Heading>{match?.localteam_score?.split("(")[0]?.replace("/", "-")}</Heading> */}
            {/* <Heading fontWeight={"400"}>{`(${match?.localteam_score?.split("(")[1]}`}</Heading> */}
            {/* <Heading >{match?.localteam_score?.split("(")[0]?.replace("/", "-")}</Heading>
                                    <Heading color={match?.localteam_score ? "#00000" : "#A2A5B0"} marginLeft={match?.localteam_score ? "0px" : "10px"} fontWeight={"400"}>{match?.localteam_score ? `(${match?.localteam_score?.split("(")[1]}` : "Yet To Bat"}</Heading>
                                </Row>
                            </Row> */}

            {/* <Row justifyContent="space-between">
                                <>
                                    <CountryLogo width={"30px !important"}  src={match.visitorteam_image || "../Assets/dummy.png"}
                                        onError={(e) => {
                                            if (e.target.src !== "../Assets/dummy.png") { e.target.onerror = null; e.target.src = "../Assets/dummy.png" }
                                        }}
                                    ></CountryLogo>
                                    <Heading marginRight={"30px"} >{match.visitorteam_short_name}</Heading>
                                </>

                                <Row>
                                    <Heading  >{match?.visitorteam_score?.split("(")[0]?.replace("/", "-")}</Heading> */}
            {/* <Heading fontWeight={"400"}>{`(${match?.visitorteam_score?.split("(")[1]}`}</Heading> */}
            {/* <Heading color={match?.visitorteam_score ? "#00000" : "#A2A5B0"} marginLeft={match?.visitorteam_score ? "0px" : "10px"} fontWeight={"400"}>{match?.visitorteam_score ? `(${match?.visitorteam_score?.split("(")[1]}` : "Yet To Bat"}</Heading>
                                </Row>
                            </Row>
                        </Column>
                    </MatchInfoSection>


                ))}
            </SeriesMatchColumnDiv> */}


        </>
    )
}



export default ViewAllTab