import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
// import FooterContact from "./components/Footer/ContactUs";
// import FeedbackForm from "./components/Footer/FeedBack";
// import FooterContent from "./components/Footer/FooterContent";
// import PageNotFound from "./PageNotFound";
// import BlogDetails from "./pages/Blogdetails";
// import BlogDetails2 from "./pages/Blogdetails-2";
// import BlogListing from "./pages/BlogListing";
// import Home from "./pages/Home";
// import StripContentPage from "./pages/StripContentPage";
// import SubContentPage from "./pages/SubContentPage";
// import MatchDetail from "./pages/MatchInfo"
// import LiveMatchListTab from "./pages/MatchList/LiveBettingMatchList";
// import GameMainComponent from "./pages/Games/GameInfo";
// import ReferAndEarn from "./pages/ReferAndEarn/ReferAndEarn";
// import Matches from "./pages/MatchList/Matches";
import { history } from "../managers/history";
import ViewAllTab from "./pages/ViewAllMatches/ViewAll";
import Loader from "./components/loader";
import { useSelector } from "react-redux";
import AboutUs from "./pages/PrivacyPolicy/AboutUs";
// import BlogsComponent from "./pages/MatchInfo/Blogs";
// import ParticularBlogsComponent from "./pages/Blogs/ParticularBlogComponent";
// import ContactUS from "./pages/PrivacyPolicy/ContactUs";
// import FAQ from "./pages/PrivacyPolicy/FrequentlyAskedQues";
// import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
// import RulesAndRegulations from "./pages/PrivacyPolicy/RulesAndRegulation";
// import TermsAndConditions from "./pages/PrivacyPolicy/TermsAndConditions" ;
// import ResponsiveGaming from "./pages/PrivacyPolicy/ResponsiveGaming" ;
// import Treansaction  from  "./pages/PopUpsBoxes/Transaction";
// import FantasyComponent from "./pages/FantasyPrediction/FantasyPrediction";
// import ParticularFantasyComponent from "./pages/FantasyPrediction/ParticularFantasy";
const FooterContact = lazy(() => import('./components/Footer/ContactUs'));
const FeedbackForm = lazy(() => import('./components/Footer/FeedBack'));
const FooterContent = lazy(() => import('./components/Footer/FooterContent'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const BlogDetails = lazy(() => import('./pages/Blogdetails'));
const BlogDetails2 = lazy(() => import('./pages/Blogdetails-2'));
const BlogListing = lazy(() => import('./pages/BlogListing'));
const Home = lazy(() => import('./pages/Home'));
const StripContentPage = lazy(() => import('./pages/StripContentPage'));
const SubContentPage = lazy(() => import('./pages/SubContentPage'));
const MatchDetail = lazy(() => import('./pages/MatchInfo'));
const LiveMatchListTab = lazy(() => import('./pages/MatchList/LiveBettingMatchList'));
const GameMainComponent = lazy(() => import('./pages/Games/GameInfo'));
const ReferAndEarn = lazy(() => import('./pages/ReferAndEarn/ReferAndEarn'));
const Matches = lazy(() => import('./pages/MatchList/Matches'));
const BlogsComponent = lazy(() => import('./pages/MatchInfo/Blogs'));
const ParticularBlogsComponent = lazy(() => import('./pages/Blogs/ParticularBlogComponent'));
const ContactUS = lazy(() => import('./pages/PrivacyPolicy/ContactUs'));
const FAQ = lazy(() => import('./pages/PrivacyPolicy/FrequentlyAskedQues'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const RulesAndRegulations = lazy(() => import('./pages/PrivacyPolicy/RulesAndRegulation'));
const TermsAndConditions = lazy(() => import('./pages/PrivacyPolicy/TermsAndConditions'));
const ResponsiveGaming = lazy(() => import('./pages/PrivacyPolicy/ResponsiveGaming'));
const Transaction = lazy(() => import('./pages/PopUpsBoxes/Transaction'));
const OddsPlay = lazy(() => import('./pages/OddsPlay/oddsPlay'));
const FantasyComponent = lazy(() => import('./pages/FantasyPrediction/FantasyPrediction'));
const ParticularFantasyComponent = lazy(() => import('./pages/FantasyPrediction/ParticularFantasy'));


function Router() {
    const user = useSelector((state)=>{return state.user})
    const blog = useSelector((state)=>{return state.match.selectBlog})
    const fantasy = useSelector((state)=>{return state.match.selectFantasy})
    let loader = user.loading ? <Loader /> : null;

    return (
        <>
              {loader}
        <Suspense >
                <Routes history={history}>
                  
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/:category/:slug/:id" element={<BlogDetails />} />
                    <Route exact path="blog-details2" element={<BlogDetails2 />} />
                    <Route exact path='/:slug/:id' element={<StripContentPage />} />
                    <Route exact path='/page/:slug/:id' element={<SubContentPage />} />
                    <Route exact path='/all/:slug/' element={<FooterContent />} />
                    <Route exact path='/contact/' element={<FooterContact/>} /> 
                    <Route exact path='/feedback/' element={<FeedbackForm/>} /> 
                    <Route exact path="/nodatafound" element={<PageNotFound />} />
                    <Route exact path="/match-info" element={<MatchDetail/>} />
                    <Route exact path="/live-cricket-score" element={<Matches/>} />
                    <Route exact path="/live" element={<LiveMatchListTab/>} />
                    <Route exact path="/game" element={<GameMainComponent/>} />
                    <Route exact path="/refer" element={<ReferAndEarn/>} />
                    <Route exact path="/blog" element={<BlogsComponent/>} />
                    <Route exact path={`/blog-info/:slug/:id`} element={<ParticularBlogsComponent/>} />
                    <Route exact path="/fantasy-prediction" element={<FantasyComponent/>} />
                    <Route exact path={`/fantasy-info/:slug/:id`} element={<ParticularFantasyComponent/>} />
                    <Route exact path="/series-matchList" element={<ViewAllTab/>} />

                    <Route exact path="/contactUS" element={<ContactUS/>} />
                    <Route exact path="/AboutUs" element={<AboutUs/>} />
                    
                    <Route exact path="/frequentlyAskedQuestions" element={<FAQ/>} />
                    <Route exact path="/privacypolicy" element={<PrivacyPolicy/>} />
                    <Route exact path="/rulesAndRegulations" element={<RulesAndRegulations/>} />
                    <Route exact path="/privacypolicy" element={<PrivacyPolicy/>} />
                    <Route exact path="/resposiveGaming" element={<ResponsiveGaming/>} />
                    <Route exact path="/termsAndConditions" element={<TermsAndConditions/>} />
                    <Route exact path="/transactions" element={<Transaction />} />
                    <Route exact path="/odds-play" element={<OddsPlay />} />
            </Routes>
        </Suspense>
            
        </>
    )
}

export default Router