// const ApiKey =  'a9fe3d90-3227-ec11-8d3d-0124sz130003';
const ApiKey =  process.env.REACT_APP_API_KEY;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL; //live
// const apiBaseUrl = 'http://localhost:4500/api/v1/web';
const WebAppUrl = 'https://cricfun.com'
const redisApiBaseUrl =  `${process.env.REACT_APP_API_NEW_BASE_URL}/v1`
const webApiBaseUrl =`${process.env.REACT_APP_API_NEW_BASE_URL}/v2`
const configOne = {
    hostname: "http://3.111.38.13:5050", // Replace with your app's hostname
    protocol: "https", // Replace with the appropriate protocol (http or https)
};

module.exports = {
    ApiKey,
    apiBaseUrl,
    configOne,
    WebAppUrl,
    redisApiBaseUrl,
    webApiBaseUrl
}