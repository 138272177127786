import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { history } from "./managers/history";
import 'owl.carousel/dist/assets/owl.carousel.css'; // Import Owl Carousel CSS
import 'owl.carousel/dist/assets/owl.theme.default.css';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
     <BrowserRouter history={history}>
      <App />
    </BrowserRouter>
  </StrictMode>,
  rootElement
);
