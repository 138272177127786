import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-loading-skeleton/dist/skeleton.css'
import MainMenu from './view/components/Header/MainHeader';
import Footer from './view/components/Footer/Footer';
import Router from './view/router';
import store from './store.js';
import { Provider } from 'react-redux';
import { AuthProvider } from './view/pages/LoginAndSIgnup/context.js';

function App() {
  return (
    <>
      <Provider store={store}>
        <AuthProvider>
          <MainMenu />
          </AuthProvider>
          <Router>
          </Router>
        <Footer />
        </Provider>
    </>
  );
}
export default App

