
import React, { useEffect, useState } from 'react';
import { Heading } from '../../LoginAndSIgnup/LoginAndSIgnUpStyle';

import { apiBaseUrl, redisApiBaseUrl } from "../../../../config";
import { axiosRequest } from "../../../../utility/axios";
import styled from "styled-components";
import Dialog from '@mui/material/Dialog';
import { useScrollTrigger } from '@mui/material';
import { failureToast, successToast } from '../../../../utility';
import { Image } from '../../MatchInfo/MatchInfoStyle';
import { Row } from 'reactstrap';
// import crossSvg from '../../../../../public/Assets/cross.svg'

export const MainDiv = styled.div`
background: #FFFFFF;
padding: 25px 15px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position: relative;
`
const Input = styled.input`
font-size:14px;
border: none;
width: 330px;
border-radius:6px;
height: 40px;
padding: 0px 20px;
margin: 10px 10px;
background-color:#F5F5F5;
`
const Button = styled.button`
background: ${props => props.background || ""};
color: ${props => props.color || ""};
padding: 10px;
margin: ${props => props.margin || "0px"};
border-radius: 20px;
border:none;
width: 230px;
font-size: 15px;
`
const VerifyYourEmailDialog = (props) => {
  const [otp, setOtp] = useState("");
 

  const clickVerifyEmail = async () => {
    const input = { user_id: props.userProfile.user_id, otp:otp , device_id:props.userProfile.device_id , device_type:props.userProfile.device_type   }
    const url = `${redisApiBaseUrl}/redisEmailVerify`;

    const response = await axiosRequest("POST", url, input);
    if (!response || response.errors || !response.status) {
      failureToast(response?.message || "Unable to update");
      return;
    }
    successToast(response.message);
    // props.handleDialogToggle("userUpdateProfile",false);

    props.toggle("userUpdateProfile",false);
    props.handleTogle();
  }
  const handleClose = () => {
    // props.toggle("userUpdateProfile",false);
    props.handleTogle();
  } 
  return (
    <>

      <Dialog open={props.verifyEmail} sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
                     margin: "20px 10px",
          
        },
      }}>
        <MainDiv >
          

          <Heading fontWight={"600"} fontSize={"25px"} fontFamily={"Outfit"} paddingBottom={"10px"}> Verify your email</Heading>
          
          <Heading color={"#22213A"} fontWight={"400"} fontSize={"15px"} fontFamily={"Outfit"}> Code has been sent to your registered mail id</Heading>
          <Input placeholder={props.email} name='email' readOnly></Input>
          <Input placeholder='Enter code for verification' name='code' value={otp} onChange={(e) => setOtp(e.target.value)}></Input>
          <Button background={"linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)"} color={"#FFFFFF"} margin={"10px 25px"} onClick={() => clickVerifyEmail()}> Verify</Button>
        </MainDiv>
        <ImageDiv right={"30px"} top={"20px"}>
        <Image  width={"12px"} cursor={"pointer"} src="../../Assets/cross.svg"  onClick={handleClose}></Image>
        </ImageDiv>
      </Dialog>

    </>
  )
}

export const ImageDiv = styled.div`
position: absolute;
top: ${props => props.top || "15px"};
right: ${props => props.right || "20px"};

@media(max-width:450px){
   
   Top: ${props => props.smTop};
   right: ${props => props.smRight};
};
`

export default VerifyYourEmailDialog