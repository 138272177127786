import React, { useState } from "react"
import { Row, Column } from "simple-flexbox"
import { SeriesBlock, Section, ViewAll, Arrow, MatchInfoBlock, MatchNamediv, CountryLogo, MatchScorediv, CommentDiv } from "./MatchListStyle";
import { Heading } from "./LiveBettingMatchList";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import match, { currentScore, selectMatch } from "../../../reducers/match";
import moment from "moment";
import ViewAllTab from "../ViewAllMatches/ViewAll";
import { useNavigate } from "react-router-dom";
import { HorizontalLine } from "../pagesStyle";
const SeriesLiveMatches = (props) => {
    const { seriesList } = props;
    let navigate = useNavigate();

    const onViewAllClick = (series) => {
        console.log("onViewAllClick",series.seriesName);
        navigate(`/series-matchList/?series_id=${series.series_id}&series_name=${series.seriesName}`);
    }

    return (
        <>
            {seriesList && seriesList.length ? seriesList.map((series) => (
                <SeriesBlock>
                    <Section marginTop={"25px"} marginBottom={"20px"}>
                        <Row justifyContent="space-between">
                            <Column>
                                <Heading paddingBottom={"22px"} fontSize={"18px"} smFont={"14px"}>{series.seriesName}</Heading>
                            </Column>
                            <Column>
                                <Row onClick={() => { onViewAllClick(series) }}>
                                    <ViewAll color={"#0BA9DB"} series= {series}>View All</ViewAll>
                                    <Arrow src="../Assets/RightArrow.svg"></Arrow>
                                </Row>
                            </Column>
                        </Row>
                        <AllMatchOfSeries matchList={series.matches}></AllMatchOfSeries>
                    </Section>
                </SeriesBlock>
            )) : <Heading textAlign={"center"} width={"500px"} fontWeight={"400px"} paddingBottom={"22px"} fontSize={"18px"}>Match Not Available</Heading>}
        </>
    )
}
export const AllMatchOfSeries = ({ matchList, type, viewAll }) => {
    const dispatch = useDispatch();
    const onMatchClick = (data) => {
        dispatch(selectMatch({ data }))
        let score = {}
        if (data?.crick_match_id) {
            score.localteam_score = data?.localteam_score ? data?.localteam_score : '';
            score.visitorteam_score = data.visitorteam_score ? data?.visitorteam_score : "";
            score.localteam_overs = data.localteam_overs ? data?.localteam_overs : "";
            score.visitorteam_overs = data.visitorteam_overs ? data?.visitorteam_overs : "";
        } else {

            score.localteam_score = data?.localteam_score ? data?.localteam_score.split("(")[0] : "";
            score.visitorteam_score = data.visitorteam_score ? data?.visitorteam_score.split("(")[0] : "";
            score.localteam_overs = data.localteam_score ? data?.localteam_score.split("(")[1].replace(")", "") : "";
            score.visitorteam_overs = data.visitorteam_score ? data?.visitorteam_score.split("(")[1].replace(")", "") : "";
        }
        score.crr = data?.crr;
        score.rrr = data?.rrr;
        score.target = data?.target;
        score.powerplay = data?.powerplay;
        dispatch(currentScore({ data: score }))
    }
    const getDate = (date) => {
        return moment(date).format('lll')
    }
    return (
        <>
            {matchList && matchList.length && matchList.map((match) => (
                <div onClick={() => { onMatchClick(match) }}>
                    <Link to={{ pathname: `/match-info/?series=${match.series_id}&match=${match.match_id}&localTeam=${match.localteam_id}&visitorTeam=${match.visitorteam_id}&crickSeries=${match?.crick_series_id || 0}&crickMatch=${match?.crick_match_id || 0}&bet=${match?.betting_match}${match?.favourite_team ? `&fav=${match?.favourite_team}` : ""}` }}>
                        <MatchInfoBlock background={"#FDFDFD"} marginBottom={"10px"} marginLeft={"10px"}>
                            <Column>

                                {
                                    type !== "series"
                                        ?
                                        <Row>
                                            <Heading padding={"10px 10px 0px 10px"} fontWeight="normal" fontSize={"17px"} smFont={"12px"}  >{match.subtitle},</Heading>
                                            <Heading padding={"10px 10px 0px 10px"} fontWeight="normal" fontSize={"17px"} smFont={"12px"}  >{getDate(match.start_date)}</Heading>
                                        </Row>
                                        :
                                        <Row justifyContent="space-between">
                                            <Heading padding={"10px 10px 0px 10px"} fontWeight="normal" fontSize={"17px"} smFont={"12px"}  >{getDate(match.start_date)}</Heading>
                                            <Heading padding={"10px 10px 0px 10px"} fontWeight="normal" fontSize={"17px"} smFont={"12px"} >{match.match_status}</Heading>
                                        </Row>
                                }


                                <HorizontalLine margin={"6px 0px"} border={"0px solid #EFEFEF"}></HorizontalLine>
                            </Column>
                            <Section marginTop={"4px"} marginBottom={"0px"}>
                                <Row justifyContent="space-between">
                                    <Column justifyContent="space-between">
                                        <Row justifyContent="space-between">
                                            <MatchNamediv marginLeft={"10px"}>
                                                <CountryLogo width={"30px !important"} margin={"5px 20px"} src={match.localteam_image || "../Assets/dummy1.png"}
                                                    onError={(e) => {
                                                        if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                                                    }}
                                                ></CountryLogo>
                                                <Heading fontSize={"16px"} marginRight={"30px"} smFont={"14px"} marginLeft={"7px"}>{match.localteam_short_name}</Heading>
                                            </MatchNamediv>

                                        </Row>
                                        <Row justifyContent="space-between">
                                            <MatchNamediv marginLeft={"10px"}>
                                                <CountryLogo width={"30px !important"} margin={"5px 20px"} src={match.visitorteam_image || "../Assets/dummy1.png"}
                                                    onError={(e) => {
                                                        if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                                                    }}
                                                ></CountryLogo>
                                                <Heading fontSize={"16px"} marginRight={"30px"} smFont={"14px"} marginLeft={"7px"}>{match.visitorteam_short_name}</Heading>
                                            </MatchNamediv>
                                        </Row>
                                    </Column>
                                    <Column alignItems="end">
                                        <Row justifyContent="space-between">
                                            {match?.crick_match_id && viewAll ?
                                                <MatchScorediv>
                                                    <Heading margin={"5px"} fontSize={"16px"} smFont={"13px"}>{match?.localteam_score}</Heading>
                                                    <Heading margin={"5px 10px 5px 0px;"} fontWeight={"400"} color={"#A2A5B0"} fontSize={"16px"} smFont={"12px"} marginLeft={"10px"} marginRight={"20px"}>{match?.localteam_overs ? `(${match?.localteam_overs})` : match?.visitorteam_score ? "Yet To Bat" : ""}</Heading>
                                                </MatchScorediv>
                                                :
                                                <MatchScorediv>
                                                    <Heading margin={"5px"} fontSize={"16px"} smFont={"13px"}>{match?.localteam_score?.split("(")[0]?.replace("/", "-")}</Heading>
                                                    <Heading margin={"5px 10px 5px 0px;"} fontWeight={"400"} color={"#A2A5B0"} fontSize={"16px"} marginLeft={"10px"} marginRight={"20px"} smFont={"12px"}>{match?.localteam_score ? `(${match?.localteam_score?.split("(")[1] || ""}` : match?.visitorteam_score ? "Yet To Bat" : ""}</Heading>
                                                </MatchScorediv>

                                            }



                                        </Row>
                                        <Row justifyContent="space-between">
                                            {match?.crick_match_id && viewAll ?
                                                <MatchScorediv>
                                                    <Heading margin={"5px"} fontSize={"16px"} smFont={"13px"} >{match?.visitorteam_score}</Heading>
                                                    <Heading margin={"5px 10px 5px 0px"} fontWeight={"400"} color={"#A2A5B0"} fontSize={"16px"} smFont={"12px"} marginLeft={"10px"} marginRight={"20px"}>{match?.visitorteam_score ? `(${match?.visitorteam_overs})` : match?.localteam_score ? "Yet To Bat" : ""}</Heading>
                                                </MatchScorediv>
                                                :
                                                <MatchScorediv>
                                                    <Heading margin={"5px"} fontSize={"16px"} smFont={"13px"} >{match?.visitorteam_score?.split("(")[0]?.replace("/", "-")}</Heading>
                                                    <Heading margin={"5px 10px 5px 0px"} fontWeight={"400"} color={"#A2A5B0"} smFont={"12px"} fontSize={"16px"} marginLeft={"10px"} marginRight={"20px"}>{match?.visitorteam_score ? `(${match?.visitorteam_score?.split("(")[1] || ""}` : match?.localteam_score ? "Yet To Bat" : ""}</Heading>
                                                </MatchScorediv>

                                            }

                                        </Row>
                                    </Column>

                                </Row>
                            </Section>
                            <CommentDiv padding={"0px 10px"}>
                                <Row>
                                    <Heading fontSize={"16px"} smFont={"14px"} fontWeight={"400"} paddingBottom={"13px"} color={"#525B7C"}  >{match.comment || match.toss}</Heading>
                                </Row>
                            </CommentDiv>
                        </MatchInfoBlock>
                    </Link>
                </div>
            ))}
        </>
    )
}


export default SeriesLiveMatches;