import React, { useContext, useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Arrow } from "../MatchList/LiveBettingMatchList";
import { ColumnDiv, Heading, Image, LeaderboardBlock, LeaderboardBlockRow, LeaderboardImage, LeaderboardInnerDiv, LeaderboardText, Percentage, PercentText, PredictWinnerText, TotalVotingText } from "./MatchInfoStyle";
import { axiosRequest } from "../../../utility/axios";
import { failureToast, getColorForPercentage, successToast } from "../../../utility";
import { redisApiBaseUrl } from "../../../config";
import MatchInfoContext from "./matchInfoContext";
import { useSelector } from "react-redux";
import { sessionManager } from "../../../managers/sessionManager";
import { cookiesConstants } from "../../../constants";
import LeaderboardDialog from "../Leaderboard/leaderboard";
import { useDispatch } from 'react-redux';
import { logout } from '../../../reducers/user';
import { styled } from "styled-components";

const PredictTheWinnerComponent = () => {
    let currentMatch = useSelector((state) => { return state?.match?.currentMatch })
    if (!currentMatch || !Object.keys(currentMatch).length) {
        currentMatch = sessionManager.getDataFromCookies(cookiesConstants.SELECTED_MATCH)
    }
    const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);


    const [userVoting, setUserVoting] = useState("");
    const dispatch = useDispatch();
    const getUserVoting = async (series_id, match_id, fav) => {
        const url = `${redisApiBaseUrl}/user_voting/match/${match_id}/series/${series_id}`
        const response = await axiosRequest("GET", url);
        if (!response || !response.status)
            // failureToast(response?.message)
            return;
        const parsedData = parseUserVoting(response.data, fav);
        setUserVoting(parsedData)
    }
    const predictTheWinner = async (team_id) => {
        if (!sessionManager.getDataFromCookies(cookiesConstants.IS_LOGGED_IN)) {
            failureToast("Log in to Vote");
            return;
        }
        if (currentMatch.match_status === "Finished" || currentMatch.match_status === "In Progress") {
            failureToast("Not Vote If Match Finished Or Live");
            return;
        }

        const profile = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
        const queryParameters = new URLSearchParams(window.location.search)
        // const series_id = "127303"
        const series_id = queryParameters.get("series")
        // const match_id = "60076"
        const match_id = queryParameters.get("match")
        // user_voting/team/:team_id/match/:match_id/series/:series_id
        const url = `${redisApiBaseUrl}/user_voting/team/${team_id}/match/${match_id}/series/${series_id}`

        const response = await axiosRequest("POST", url);

        if (!response || !response.status) {
            if (response.statusCode == 401) {
                dispatch(logout());
                failureToast("Session Expired Please Logging Again")
                return

            }
            failureToast(response?.message)
            return;
        }
        else {

            successToast("Voted!!")
            getUserVoting(series_id, match_id)
        }
    }
    const parseUserVoting = (data, fav) => {
        let localteamData = { vote: 0 };
        let visitorteamData = { vote: 0 };
        let tie = { vote: 0 };
        data.voting.map((item, index) => {
            if (item.team_id === currentMatch.localteam_id) {
                localteamData.vote = item.vote
            }
            else if (item.team_id === currentMatch.visitorteam_id) {
                visitorteamData.vote = item.vote
            }
            else {
                tie.vote = item.vote
            }

        })
        // let loss = Number(data?.loss_count) || 0;
        // let win = Number(data?.win_count) || 0;
        // let tie = Number(data?.tie_count) || 0;
        // let lossTeam = getLosingTeamName(fav)
        const totalCount = localteamData.vote + tie.vote + visitorteamData.vote;
        let localTeamPercentage = 0;
        let visitorTeamPercentage = 0;
        let tiePercentage = 0;
        if (totalCount > 0) {
            localTeamPercentage = localteamData.vote > 0 ? (localteamData.vote / totalCount) * 100 : 0;
            visitorTeamPercentage = visitorteamData.vote > 0 ? (visitorteamData.vote / totalCount) * 100 : 0;
            tiePercentage = tie.vote > 0 ? (tie.vote / totalCount) * 100 : 0;
        }
        const userVotingData = {
            totalCount,
            leaderBoard: [
                {
                    percentage: parseInt(localTeamPercentage),
                    teamName: currentMatch.localteam_short_name,
                    color: localTeamPercentage > visitorTeamPercentage ? "#0BDBA9" : "#F21A1A",
                    // type:"win",
                    image: currentMatch.localteam_image,
                    team_id: currentMatch.localteam_id
                },
                {
                    percentage: parseInt(tiePercentage),
                    teamName: "TIE",
                    color: "grey",
                    image: "",
                    // type:"win",
                    team_id: currentMatch.localteam_id + currentMatch.visitorteam_id
                },
                {
                    percentage: parseInt(visitorTeamPercentage),
                    teamName: currentMatch.visitorteam_short_name,
                    color: visitorTeamPercentage > localTeamPercentage ? "#0BDBA9" : "#F21A1A",
                    // type:"loss",
                    image: currentMatch.visitorteam_image,
                    team_id: currentMatch.visitorteam_id


                },

            ]
        }
        if (data.userVotingInfo && data.userVotingInfo.length) {
            userVotingData.userVotingInfo = data.userVotingInfo[0];
        }
        return userVotingData;
    }
    const getLosingTeamName = (fav) => {
        let teamName = '';
        if (currentMatch)
            teamName = fav == currentMatch.localteam_short_name ? currentMatch.visitorteam_short_name : currentMatch.localteam_short_name;
        return teamName;

    }
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        // const series_id = "127303"
        const series_id = queryParameters.get("series")
        // const match_id = "60076"
        const match_id = queryParameters.get("match")
        const fav = currentMatch?.favourite_team || ""
        getUserVoting(series_id, match_id, fav)
    }, [])

    const [toggleDialog, setToggleDialog] = useState({
        leaderBoard: false
    })
    const handleDialogToggle = (name, value) => {
        setToggleDialog({
            ...toggleDialog,
            [name]: value
        })
    }
    const votingText = (userVoting) => {
        let text;

        if (userVoting?.userVotingInfo) {
            text = "You have already voted"
            if (currentMatch.match_status === "Finished") {
                text = "Your prediction is wrong!";
                if (userVoting?.userVotingInfo.winner_team_id == userVoting?.userVotingInfo.team_id) {
                    text = "Your prediction is right!"
                }
            }
        }
        else {
            if (currentMatch.match_status === "Not Started") {
                text = "Vote before the match begins";
            }
            else if (currentMatch.match_status === "In Progress") {
                text = "You can’t vote in live match";
            }
        }
        return text;
    }

    return (
        <>
            <LeaderboardDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></LeaderboardDialog>
            <CustomRow>
                <ColumnDiv width={"100%"} marginRight={"1px"} margin={"10px 0px 0px 0px "}>
                    <Row justifyContent="space-between" style={{ marginBottom: "15px" }}>
                        <Column>
                            <PredictWinnerText>
                                Predict the Winner
                            </PredictWinnerText>

                        </Column>
                        <Column >
                            <TotalVotingText>
                                Total Votes : {userVoting?.totalCount}
                            </TotalVotingText>
                        </Column>
                    </Row>
                    <Row justifyContent="space-between">
                        {userVoting && userVoting.leaderBoard && userVoting.leaderBoard.map((data, index) => (

                            <LeaderboardBlock width={"30%"} background={"#EAEDF1"} onClick={() => predictTheWinner(data.team_id)}>
                                <LeaderboardInnerDiv width={data.percentage > 0 ? `${data.percentage}%` : "100%"}
                                    background={data.percentage > 0 ? data.color : "#EAEDF1"}
                                >
                                    <LeaderboardBlockRow justifyContent="space-between">
                                        <Column ><PercentText  >{`${data.percentage}%`}</PercentText></Column>
                                        <Column justifyContent="center">
                                            <LeaderboardImage src={data.image || (data.teamName == "TIE" ? "../Assets/tie.png" : "../Assets/dummy1.png")}></LeaderboardImage>
                                            <LeaderboardText>
                                                <Row >
                                                    {data.teamName}
                                                </Row>
                                            </LeaderboardText>
                                        </Column>
                                    </LeaderboardBlockRow>
                                </LeaderboardInnerDiv>
                            </LeaderboardBlock>
                        ))}

                    </Row>
                    {
                        profileDetails && profileDetails.full_name && votingText(userVoting) ?
                            <VotingBlueBox>
                                <Column>
                                    <Row style={{ alignItems: "center" }}>
                                        <Image src="../Assets/star.svg"></Image>

                                        <Heading textAlign={"center"} fontWeight={"400"} fontSize={"15px"} xsFont={"12px"} paddingLeft={"10px"}>{votingText(userVoting)}</Heading>
                                    </Row>
                                </Column>
                                <Column>
                                    {/* <Row onClick={()=> handleDialogToggle("leaderboard", true)} style={{cursor: "pointer"}}>
                    <Heading fontWeight={"normal"} fontSize={"15px"} color={"#0BA9DB"} paddingRight={"5px"}> View Rank </Heading>
                    <Arrow src="../Assets/RightArrow.svg"></Arrow>
                    </Row> */}
                                </Column>
                            </VotingBlueBox>

                            : ""
                    }


                </ColumnDiv>
            </CustomRow>
        </>
    )
}

const CustomRow = styled(Row)`
display:flex;
justify-content:center;
flex-flow:row;
width:100%;
margin:0 auto;
max-width:700px;
height:fit-content;

`

const VotingBlueBox = styled.div`
background: linear-gradient(93.63deg, rgba(48, 164, 248, 0.21) 2.98%, rgba(3, 94, 159, 0) 97.02%);
display: flex;
justify-content:space-between;
border-radius: 10px;
margin: 10px 0px 0px;
align-items: center;
padding: 5px 10px;
`
export default PredictTheWinnerComponent;