import React, { Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Column } from "simple-flexbox";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PredictTheWinnerComponent from "./PredictWinner";
import Squad from "./Squad";
import { axiosRequest } from "../../../utility/axios";
import { redisApiBaseUrl } from "../../../config";
import { failureToast, getPathParam } from "../../../utility";
import { BodyColumn, BodyRow, Table } from "./TableStyle";
import PieChart from "../../components/PieChart";
import { AccordianInnerDiv, UmpireSection } from "./MatchInfoStyle";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";
import { useSelector } from "react-redux";
import { sessionManager } from "../../../managers/sessionManager";
import { cookiesConstants } from "../../../constants";
import MatchBannerComponent from "./MatchBanner";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../reducers/user";
import CricFunInstallSpinDialog from "../Games/CricFunInstallSpinDialog";


const InfoTab = () => {
    const [matchInfo, setMatchInfo] = useState([])
    const [venueInfo, setVenueInfo] = useState("")
    const [recentForm, setRecentForm] = useState("");
    const [toggleHis, setToggleHis] = useState({ 0: false });
    const dispatch = useDispatch();
    const handleShow = (id) => {
        setToggleHis((preState) => ({
            ...preState,
            [id]: !preState[id]
        }));
    };

    const getMatchInfo = async (series_id, match_id) => {

        dispatch(setLoader({ data: true }))
        const url = `${redisApiBaseUrl}/matchInfo?series_id=${series_id}&match_id=${match_id}`
        const matchInfo = await axiosRequest("GET", url);
        const selectedMatch = sessionManager.getDataFromCookies(cookiesConstants.SELECTED_MATCH)
        if (!matchInfo || !matchInfo.status)
            // failureToast(matchInfo?.message)
            return;
        setMatchInfo(matchInfo.data)
        await getVenueInfo(series_id, matchInfo.data.venue.venue_id)
        await getTeamRecentForm(series_id, selectedMatch.localteam_id, selectedMatch.visitorteam_id)
    }
    const getVenueInfo = async (series_id, venue_id) => {
        const url = `${redisApiBaseUrl}/getVenueInfo?venue_id=${venue_id}`
        const response = await axiosRequest("GET", url);
        if (!response || !response.status) {
            dispatch(setLoader({ data: false }))
            return;
        }
        // failureToast(response?.message)

        const venueDetailUrl = `${redisApiBaseUrl}/venueDetail?series_id=${series_id}&venue_id=${venue_id}`
        const venueDetailRes = await axiosRequest("GET", venueDetailUrl);
        if (!venueDetailRes || !venueDetailRes.status)
            failureToast(venueDetailRes?.message)
        const venue = { ...response.data, ...venueDetailRes.data }
        setVenueInfo(venue)
    }
    const getTeamRecentForm = async (series_id, localteam_id, visitorteam_id) => {
        const url = `${redisApiBaseUrl}/recentForm?series_id=${series_id}&localteam_id=${localteam_id}&visitorteam_id=${visitorteam_id}`
        const response = await axiosRequest("GET", url);
        if (!response || !response.status)
            // failureToast(response?.message)
            return;
        setRecentForm(response.data);
        dispatch(setLoader({ data: false }))
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        // const series_id = "127854"
        const series_id = queryParameters.get("series")
        // const match_id = "66418"
        const match_id = queryParameters.get("match")

        getMatchInfo(series_id, match_id)
    }, [])

    return (
        <>
            <MainContainer>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item sm={12} md={12} lg={3.5} display={"flex"} justifyContent={"center"} m={"0 auto"} width={"100%"}>
                        {FirstColumn()}
                    </Grid>
                    <Grid item sm={12} md={12} lg={5} display={"flex"} justifyContent={"center"} m={"0 auto"} width={"100%"}>

                        {SecondColumn(matchInfo, venueInfo, recentForm, toggleHis, handleShow)}

                    </Grid>
                    <Grid item sm={12} md={12} lg={3.5} display={"flex"} justifyContent={"center"} m={"0 auto"} width={"100%"}>
                        {ThirdColumn()}
                    </Grid>
                </Grid>
            </MainContainer>
        </>
    )
}
export const FirstColumn = () => {
    return (
        <Column style={{ width: "100%" }}>
            {getPathParam("bet") == 1 ?
                <>
                    <PredictTheWinnerComponent></PredictTheWinnerComponent>
                </>

                : ""}
            <ReferAndEarnSpan display={"none"}> <ReferAndEarn shareButtonMarginLeft={"20px"} ></ReferAndEarn> </ReferAndEarnSpan>
        </Column>
    )
}
const SecondColumn = (match, venue, recentForm, toggleHis, handleShow) => {
    let currentMatch = useSelector((state) => { return state?.match?.currentMatch })
    if (!currentMatch || !Object.keys(currentMatch).length) {
        currentMatch = sessionManager.getDataFromCookies(cookiesConstants.SELECTED_MATCH)
    }
    return (
        <ColumnDiv padding={"20px 20px 12px"} marginBottom={"30px"} smMarginBottom={"0px"}>
            <Row>
                <Column>
                    {/* <MatchLogo src={match?.series_image || "../../../../Assets/dummy1.png"}></MatchLogo> */}
                    <MatchLogo src={match?.series_image != " " && match?.series_image != null && match?.series_image != undefined ? match.series_image : "../../../../Assets/dummy1.png"}></MatchLogo>
                </Column>
                <Column alignItems="start">
                    <Heading lgFont={"20px"} smFont={"16px"}>{match?.match_short_title}, {match?.dateOfMatch}</Heading>
                    <SubHeading fontSize={"18px"} lgFont={"16px"} smFont={"14px"} fontWeight={"400"} color={"#424965"}>{match?.subtitle}</SubHeading>
                </Column>
            </Row>
            <Section>
                <Heading paddingBottom={"15px"} lgFont={"20px"} smFont={"16px"}>Match Details</Heading>
                <MatchVenue>
                    <MatchVenueDiv>
                        <Image src="../../../../Assets/calendar.svg" width={"30px"} margin={"13px"}></Image>
                        <SubHeading fontWeight={"500"} fontSize={"18px"} lgFont={"16px"} smFont={"14px"}>{match?.dateOfMatch}, {match?.timeOfMatch}</SubHeading>
                    </MatchVenueDiv>
                    <HorizontalLine border={" 0.7px solid #DEDEDE"}></HorizontalLine>
                    <MatchVenueDiv>
                        <Image src="../../../../Assets/pin.svg" width={"30px"} margin={"13px"}></Image>
                        <SubHeading fontWeight={"500"} fontSize={"18px"} lgFont={"16px"} smFont={"14px"}>{`${match?.venue?.name}, ${match?.venue?.location}, ${match?.venue?.country}`}</SubHeading>
                    </MatchVenueDiv>
                </MatchVenue>
            </Section>


            <Section>

                {match && match.match_summary ?
                    <>
                        <Heading paddingBottom={"15px"} lgFont={"20px"} smFont={"16px"}>Match Summary</Heading>
                        <Row justifyContent="space-around">
                            <Column>
                                <SubHeading fontWeight={"500"} fontSize={"18px"} color={"#282828"} >
                                    Top Batsman
                                </SubHeading>
                            </Column>
                            <Column>
                                <SubHeading fontWeight={"500"} fontSize={"18px"} color={"#282828"}>
                                    Top Bowler</SubHeading>
                            </Column>
                        </Row>
                        <Row justifyContent="space-between">
                            <CustomColumn width={"48%"} margin={"15px 0px 0px"}>
                                <TopPlayerdiv smFont={"12px"} xsFont={"8px"} smWidth={"160px"} smHeight={"160px"}>
                                    <Image width={"100px"} borderRadius={"50%"} height={"100px"} lgWidth={"80px"} lgHeight={"80px"} smWidth={"60px"} smHeight={"60px"} xsWidth={"80px"} xsHeight={"80px"} src={match?.match_summary?.top_bat?.player_image || "../../../../Assets/avatar.svg"}></Image>
                                    <Heading fontWeight={"500"} fontSize={"20px"} textAlign={"center"} lgFont={"16px"} smFont={"12px"} xsFont={"12px"} width={"max-content"} margin={"auto"}>{match?.match_summary?.top_bat?.player_name}</Heading>
                                    <PlayerScore smFont={"12px"} xsFont={"12px"}>{match?.match_summary?.top_bat?.runs}</PlayerScore>
                                    <span style={{ color: "#6B6B6B" }}>({match?.match_summary?.top_bat?.balls})</span>
                                    <div style={{ width: "max-content", margin: "auto" }}>

                                        <span><span style={{ margin: "0px 5px", color: "#6B6B6B" }}>4s</span><PlayerScore xsFont={"12px"}>{match?.match_summary?.top_bat?.fours}</PlayerScore></span>
                                        <img src="../../../../Assets/Vector.svg" style={{ margin: "0px 5px" }}></img>
                                        <span><span style={{ margin: "0px 5px", color: "#6B6B6B" }}>6s</span><PlayerScore>{match?.match_summary?.top_bat?.sixes}</PlayerScore></span>
                                    </div>
                                </TopPlayerdiv>
                            </CustomColumn>
                            <CustomColumn width={"48%"} margin={"15px 0px 0px"} >
                                <TopPlayerdiv smFont={"12px"} xsFont={"8px"} smWidth={"160px"} smHeight={"160px"}>
                                    <Image width={"100px"} borderRadius={"50%"} height={"100px"} lgWidth={"80px"} lgHeight={"80px"} smWidth={"60px"} smHeight={"60px"} xsWidth={"80px"} xsHeight={"80px"} src={match?.match_summary?.top_bowl?.player_image || "../../../../Assets/avatar.svg"}></Image>
                                    <Heading fontWeight={"500"} fontSize={"20px"} textAlign={"center"} lgFont={"16px"} smFont={"12px"} xsFont={"12px"} width={"max-content"} margin={"auto"} >{match?.match_summary?.top_bowl?.player_name}</Heading>
                                    <PlayerScore xsFont={"12px"} sFont={"10px"}>{match?.match_summary?.top_bowl?.overs}/{match?.match_summary?.top_bowl?.runs_conceded}</PlayerScore><span style={{ color: "#6B6B6B" }}>({match?.match_summary?.top_bowl?.wickets_taken})</span>
                                    <div style={{ width: "max-content", margin: "auto" }}>
                                        <span><span style={{ color: "#6B6B6B", margin: "0px 5px" }}>Econ </span><PlayerScore xsFont={"12px"}> {match?.match_summary?.top_bowl?.econ}</PlayerScore></span>
                                    </div>

                                </TopPlayerdiv>
                            </CustomColumn>
                        </Row>
                    </>
                    : ""}

            </Section>
            <Section>
                {match?.match_summary?.localteam ? TeamScore(match?.match_summary?.localteam) : ""}
            </Section>
            <Section>
                {match?.match_summary?.visitorteam ? TeamScore(match?.match_summary?.visitorteam) : ""}
            </Section>

            <Section>
                {recentForm[0]?.series_team_history ? <>

                    <Row justifyContent="space-between">
                        <Row>
                            <Heading margin={"0px 10px 0px 0px"} lgFont={"18px"} mdFont={"20px"} smFont={"16px"} fontSize={"21px"} fontWeight={"500"}>Team Recent Form </Heading>
                            <SubHeading color={"#8B8B8B"} fontWeight={"400"} smDisplay={"none"} fontSize={"20px"} lgFont={"17px"} smFont={"15px"}>( Last 10 matches)</SubHeading>
                        </Row>
                        <RecentMatch>
                            <Image width={"15px"} margin={"4px"} smWidth={"10px"} src="../../../../Assets/Ellipse.svg"></Image>
                            <Heading color={"#626161"} fontSize={"14px"} lgFont={"10px"} fontWeight={"400"} smFont={"9px"}> Recent Match </Heading>
                        </RecentMatch>

                    </Row>

                    {TeamPlayerScoreComponent(recentForm, toggleHis, handleShow)}

                </> : " "}


            </Section>
            <Section >

                {match?.head_to_head ?
                    <>
                        <Row>
                            <Heading margin={"0px 10px 0px 0px"} fontSize={"21px"} fontWeight={"500"} lgFont={"18px"} smFont={"16px"}>Head To Head </Heading>
                            <SubHeading color={"#8B8B8B"} fontWeight={"400"} fontSize={"20px"} lgFont={"14px"}>( Last 10 matches)</SubHeading>
                        </Row>
                        <TeamScoreDiv padding={"20px 40px"} smPadding={"20px"} background={"white"}>
                            <CustomRow width={"75%"} margin={"0px auto"}>
                                <Column alignItems="center">
                                    <LeaderboardImage height={"36px"} width={"36px"} xsHeight={"25px"} xsWidth={"25px"} borderRadius={"50%"} src={currentMatch?.localteam_image || "../../../../Assets/dummy1.png"}></LeaderboardImage>
                                    <Heading fontSize={"18px"} xsFont={"14px"}>{currentMatch?.localteam_short_name}</Heading>
                                </Column>
                                <Row justifyContent="space-between">
                                    <Heading color={"#50BD61"} fontSize={"30px"} xsFont={"16px"} margin={"5px"}>{match?.head_to_head?.localteamCount}</Heading>
                                    <Heading fontSize={"30px"} margin={"5px"} xsFont={"16px"}>-</Heading>
                                    <Heading color={"#DC3A40"} fontSize={"30px"} margin={"5px"} xsFont={"16px"}> {match?.head_to_head?.visitorteamCount}</Heading>
                                </Row>
                                <Column alignItems="center">
                                    <LeaderboardImage height={"36px"} width={"36px"} xsHeight={"25px"} xsWidth={"25px"} borderRadius={"50%"} src={currentMatch?.visitorteam_image || "../../../../Assets/dummy1.png"}></LeaderboardImage>
                                    <Heading fontSize={"18px"} xsFont={"14px"}>{currentMatch?.visitorteam_short_name}</Heading>
                                </Column>
                            </CustomRow>
                        </TeamScoreDiv>
                    </>

                    :
                    ""
                }
            </Section>

            {TeamComparison(recentForm)}

            {venue.avg_1_innings ? VenueInfo(venue) : " "}
            <Section>
                {VenueDetails(venue)}
            </Section>
            <Section>
                <Umpires match={match}></Umpires>
            </Section>

        </ColumnDiv>
    )
}
const ThirdColumn = () => {



    const [toggleDialog, setToggleDialog] = useState({
        freeCoins: false,
        spin: false
    })
    const handleDialogToggle = (name, value) => {
        setToggleDialog({
            ...toggleDialog,
            [name]: value
        })
    }
    return (

        <>

            <CricFunInstallSpinDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></CricFunInstallSpinDialog>
            <Column style={{ width: "100%" }}>
                <Squad isFull={true}></Squad>

                <ShareImage src="../Assets/spinwheel.svg" onClick={() => { handleDialogToggle("spin", true) }}></ShareImage>

            </Column>
        </>

    )
}
const TeamScore = (teamData) => {
    return (
        <>
            <TeamScoreDiv background={"#F8FAFD"}>
                <Row justifyContent="space-between" >
                    <Row>
                        <Image margin={"10px"} width={"36px"} height={"36px"} borderRadius={"50%"} src={teamData?.team_image || "../../../../Assets/dummy1.png"}></Image>
                        <Heading fontSize={"18px"} smFont={"16px"}>{teamData?.team_short_name}</Heading>
                    </Row>
                    <Heading fontSize={"18px"} smFont={"14px"}>{teamData?.team_score} ({teamData?.team_overs})</Heading>
                </Row>
            </TeamScoreDiv>
            <Section>
                <Row justifyContent="space-between">
                    <Column>
                        <SubHeading fontWeight={"500"} color={"#787878"} smFont={"14px"} >BATTER</SubHeading>
                        {teamData?.batter && teamData?.batter?.length && teamData.batter.map((batterInfo) => (
                            <>
                                <Heading fontSize={"18px"} lgFont={"15px"} smFont={"14px"}>{batterInfo.player_name}</Heading>
                                <Heading fontSize={"18px"} lgFont={"15px"} smFont={"14px"}>{batterInfo.runs} ({batterInfo.balls})</Heading>
                                <br></br>
                            </>
                        ))}
                    </Column>
                    <VerticalLine></VerticalLine>
                    <Column>
                        <SubHeading display={"block"} textAlign={"right"} fontWeight={"500"} color={"#787878"} smFont={"14px"}>BOWLER</SubHeading>
                        {teamData?.bowler && teamData?.bowler?.length && teamData.bowler.map((batterInfo) => (
                            <>
                                <Heading display={"block"} textAlign={"right"} fontSize={"18px"} smFont={"14px"} lgFont={"15px"}>{batterInfo?.player_name}</Heading>
                                <Heading display={"block"} textAlign={"right"} fontSize={"18px"} smFont={"14px"} lgFont={"15px"}>{batterInfo?.wickets_taken}/{batterInfo?.runs_conceded} ({batterInfo.overs})</Heading>
                                <br></br>
                            </>
                        ))}
                    </Column>
                </Row>
            </Section>
        </>
    )
}
const TeamPlayerScoreComponent = (recentForm, toggleHis, handleShow) => {

    const getHistoryInfo = (data) => {
        if (data.result === "win")
            return {
                data: "W",
                color: "#359846"
            }
        else return {
            data: "L",
            color: "#DC3A40"
        }
    }
    return (
        <>
            {recentForm && recentForm.length && recentForm.map((teamData, index) => (
                <TeamScoreDiv background={"#F8FAFD"} cursor={"pointer"} onClick={() => { handleShow(index) }}>

                    <Row justifyContent="space-between" alignItems="center" cursor={"pointer"}>
                        <Column>
                            <Row>
                                <LeaderboardImage height={"36px"} width={"36px"} xsHeight={"25px"} xsWidth={"25px"} borderRadius={"50%"} src={teamData?.image || "../../../../Assets/dummy1.png"}></LeaderboardImage>
                                <Heading margin={"0px 0px 0px 8px"} marginLeft={"9px"} fontSize={"18px"} xsFont={"14px"}>{teamData.team_short_name}</Heading>
                            </Row>
                        </Column>
                        <Column>
                            <Row justifyContent="space-between">
                                <Column>
                                    {
                                        !toggleHis[index] ?
                                            <Row>
                                                {teamData?.series_team_history?.length && teamData?.series_team_history.map((history) => (
                                                    <LastMatchWinBlock background={getHistoryInfo(history).color}>{getHistoryInfo(history).data}</LastMatchWinBlock>
                                                ))}
                                            </Row> :
                                            ""
                                    }

                                </Column>
                                <Column>
                                    <Row>
                                        <Image src={!toggleHis[index] ? "../Assets/down.svg" : "../Assets/upArrow.svg"} marginLeft={"15px"} key={index} show={toggleHis} index={index} width={"13px"} marginTop={"10px"}></Image>
                                    </Row>
                                </Column>

                            </Row>

                        </Column>

                    </Row>
                    {
                        teamData?.series_team_history?.length && teamData?.series_team_history.map((history) => (
                            <>
                                {toggleHis ?

                                    <AccordianInnerDiv key={index} show={toggleHis} background={"inherit"} border={"none"} index={index} smPadding={"8px 5px"} >

                                        <Row justifyContent="space-between">

                                            <Column>

                                                <Row>
                                                    <LastMatchWinBlock background={getHistoryInfo(history).color} smMarginRight={"0px"} smMarginLeft={"1px"} >{getHistoryInfo(history).data}</LastMatchWinBlock>
                                                    <Heading fontSize={"16px"} margin={"0px 10px 0px 7px"} fontWeight={"600"} color={history?.result != "win" ? "#8B8B8B" : "#000000"} smFont={"12px"}>{teamData.team_short_name} </Heading>
                                                    <Heading color={history?.result == "win" ? "#8B8B8B" : "#000000"} fontSize={"16px"} fontWeight={"500"} smFont={"12px"}>vs &nbsp;&nbsp; {history?.teams?.teamName}</Heading>
                                                </Row>

                                            </Column>
                                            <Row>
                                                <Heading color={"#8B8B8B"} fontSize={"16px"} fontWeight={"400"} marginTop={"5px"} smFont={"11px"}>{history?.comment}</Heading>
                                            </Row>
                                        </Row>


                                    </AccordianInnerDiv>
                                    : ""}
                            </>
                        ))
                    }
                </TeamScoreDiv>
            ))}
        </>

    )
}
const TeamComparison = (recentForm) => {
    const [venueToggle, setVenueToggle] = useState(false);
    return (<>
        {recentForm[0]?.team_comparison &&
            <>
                <Section>
                    <RowDiv >



                        {/* <Row justifyContent="space-between" > */}
                        <Row >
                            <Heading fontSize={"21px"} fontWeight={"500"} lgFont={"18px"} smFont={"16px"}>Team Comparison</Heading>
                            <SubHeading color={"#8B8B8B"} fontWeight={"400"} fontSize={"16px"} lgFont={"17px"} smFont={"14px"} margin={"0px 0px 0px 7px"} marginLeft={"4px"}>( Last 10 matches)</SubHeading>
                        </Row>
                        <Row >

                            <Button width={"90px"} height={"33px"} smWidth={"100px"} smHeight={"25px"} padding={"0px 10px"} xsFont={"12px"} borderRadius={"30px"} marginRight={"6px"} venueToggle={!venueToggle} onClick={() => setVenueToggle(false)}>Overall</Button>
                            <SecondaryButton height={"33px"} width={"90px"} smWidth={"100px"} smHeight={"25px"} padding={"0px "} borderRadius={"30px"} venueToggle={venueToggle} onClick={() => setVenueToggle(true)}>On Venue</SecondaryButton>
                        </Row>
                        {/* </Row> */}
                    </RowDiv>
                </Section>
                <Section>
                    <Row justifyContent="space-between">
                        {/* {recentForm && recentForm?.length && recentForm.map((team) => ( */}
                        <Column>
                            <Row>
                                <LeaderboardImage width={"45px"} height={"45px"} borderRadius={"50%"} marginTop={"5px"} src={recentForm[0].image || "../../../../Assets/dummy1.png"}></LeaderboardImage>
                                <Column>
                                    <Heading fontSize={"18px"} xsFont={"14px"}>{recentForm[0].team_short_name}</Heading>
                                    <SubHeading fontSize={"18px"} fontWeight={"400"} color={"#737478"} smFont={"14px"}>vs all teams</SubHeading>
                                </Column>

                            </Row>
                        </Column>

                        <Column>
                            <Row>
                                <Column>
                                    <Heading fontSize={"18px"} display={"block"} textAlign={"right"} xsFont={"14px"}>{recentForm[1].team_short_name}</Heading>
                                    <SubHeading fontSize={"18px"} fontWeight={"400"} color={"#737478"} smFont={"14px"}>vs all teams</SubHeading>
                                </Column>
                                <LeaderboardImage width={"45px"} height={"45px"} xsWidth={"40px"} xsHeight={"40px"} borderRadius={"50%"} marginRight={"10px"} marginTop={"5px"} src={recentForm[1]?.image || "../../../../Assets/dummy1.png"}></LeaderboardImage>


                            </Row>
                        </Column>
                        {/* ))} */}
                    </Row>
                    {TeamComparisonTable(recentForm, venueToggle)}
                </Section>
            </>
        }



    </>)

}
const TeamComparisonTable = (recentForm, venueToggle) => {

    let localteamData = recentForm && recentForm[0]?.team_comparison;
    let visitorteamData = recentForm && recentForm[1]?.team_comparison;
    let localteamOnVenueData = recentForm && recentForm[0]?.team_comparison_venue;
    let visitorteamOnVenueData = recentForm && recentForm[1]?.team_comparison_venue;

    let parseDataA = [
        {
            teamA: localteamData?.matchesPlayed,
            text: "Matches Played",
            teamB: visitorteamData?.matchesPlayed,
            teamAColor: Number(localteamData?.matchesPlayed) > Number(visitorteamData?.matchesPlayed) ? "#1D802E" : "#000000",
            teamBColor: Number(localteamData?.matchesPlayed) > Number(visitorteamData?.matchesPlayed) ? "#000000" : "#1D802E",
        },
        {
            teamA: localteamData?.win + "%",
            text: "Win",
            teamB: visitorteamData?.win + "%",
            teamAColor: Number(localteamData?.win) > Number(visitorteamData?.win) ? "#1D802E" : "#000000",
            teamBColor: Number(localteamData?.win) > Number(visitorteamData?.win) ? "#000000" : "#1D802E",
        },
        {
            teamA: localteamData?.avgScore,
            text: "Average Score",
            teamB: visitorteamData?.avgScore,
            teamAColor: Number(localteamData?.avgScore) > Number(visitorteamData?.avgScore) ? "#1D802E" : "#000000",
            teamBColor: Number(localteamData?.avgScore) > Number(visitorteamData?.avgScore) ? "#000000" : "#1D802E",
        },
        {
            teamA: localteamData?.highScore,
            text: "Highest Score",
            teamB: visitorteamData?.highScore,
            teamAColor: Number(localteamData?.highScore) > Number(visitorteamData?.highScore) ? "#1D802E" : "#000000",
            teamBColor: Number(localteamData?.highScore) > Number(visitorteamData?.highScore) ? "#000000" : "#1D802E",
        },
        {
            teamA: localteamData?.lowestScore,
            text: "Lowest Score",
            teamB: visitorteamData?.lowestScore,
            teamAColor: Number(localteamData?.lowestScore) > Number(visitorteamData?.lowestScore) ? "#000000" : "#C57A36",
            teamBColor: Number(localteamData?.lowestScore) > Number(visitorteamData?.lowestScore) ? "#C57A36" : "#000000",
        }
    ];

    let parseDataB = [
        {
            teamA: localteamOnVenueData?.matchesPlayed,
            text: "Matches Played",
            teamB: visitorteamOnVenueData?.matchesPlayed,
            teamAColor: Number(localteamOnVenueData?.matchesPlayed) > Number(visitorteamOnVenueData?.matchesPlayed) ? "#1D802E" : "#000000",
            teamBColor: Number(localteamOnVenueData?.matchesPlayed) > Number(visitorteamOnVenueData?.matchesPlayed) ? "#000000" : "#1D802E",
        },
        {
            teamA: localteamOnVenueData?.win + "%",
            text: "Win",
            teamB: visitorteamOnVenueData?.win + "%",
            teamAColor: Number(localteamOnVenueData?.win) > Number(visitorteamOnVenueData?.win) ? "#1D802E" : "#000000",
            teamBColor: Number(localteamOnVenueData?.win) > Number(visitorteamOnVenueData?.win) ? "#000000" : "#1D802E",
        },
        {
            teamA: localteamOnVenueData?.avgScore,
            text: "Average Score",
            teamB: visitorteamOnVenueData?.avgScore,
            teamAColor: Number(localteamOnVenueData?.avgScore) > Number(visitorteamOnVenueData?.avgScore) ? "#1D802E" : "#000000",
            teamBColor: Number(localteamOnVenueData?.avgScore) > Number(visitorteamOnVenueData?.avgScore) ? "#000000" : "#1D802E",
        },
        {
            teamA: localteamOnVenueData?.highScore,
            text: "Highest Score",
            teamB: visitorteamOnVenueData?.highScore,
            teamAColor: Number(localteamOnVenueData?.highScore) > Number(visitorteamOnVenueData?.highScore) ? "#1D802E" : "#000000",
            teamBColor: Number(localteamOnVenueData?.highScore) > Number(visitorteamOnVenueData?.highScore) ? "#000000" : "#1D802E",
        },
        {
            teamA: localteamOnVenueData?.lowestScore,
            text: "Lowest Score",
            teamB: visitorteamOnVenueData?.lowestScore,
            teamAColor: Number(localteamOnVenueData?.lowestScore) > Number(visitorteamOnVenueData?.lowestScore) ? "#000000" : "#C57A36",
            teamBColor: Number(localteamOnVenueData?.lowestScore) > Number(visitorteamOnVenueData?.lowestScore) ? "#C57A36" : "#000000",
        }
    ];

    return (
        <Section>
            <HorizontalLine></HorizontalLine>
            {venueToggle ?
                <>
                    <Table margin={"0px"}>
                        {parseDataB.map((data) => (
                            <BodyRow >
                                <BodyColumn width={"20%"} padding={"0px 0px 14px 0px"}> <Heading fontSize={"18px"} xsFont={"16px"} textAlign="center" color={data.teamAColor}> {data.teamA}</Heading></BodyColumn>
                                <BodyColumn width={"60%"} padding={"0px 0px 23px 0px"} textAlign="center"> <ColumnHeading textAlign="center">{data.text}</ColumnHeading></BodyColumn>
                                <BodyColumn width={"20%"} padding={"0px 0px 14px 0px"}><Heading textAlign="right" fontSize={"18px"} xsFont={"16px"} display={"block"} color={data.teamBColor}>{data.teamB}</Heading></BodyColumn>

                            </BodyRow>
                        ))}
                    </Table>
                </>
                :
                <>
                    <Table margin={"0px"}>
                        {parseDataA.map((data) => (
                            <BodyRow  >
                                <BodyColumn width={"20%"} align="center" padding={"0px 0px 14px 0px"}> <Heading fontSize={"18px"} xsFont={"16px"} color={data.teamAColor}> {data.teamA}</Heading></BodyColumn>
                                <BodyColumn width={"60%"} align="center" padding={"0px 0px 23px 0px"} textAlign="center"> <ColumnHeading textAlign="center">{data.text}</ColumnHeading></BodyColumn>
                                <BodyColumn width={"20%"} align="center" padding={"0px 0px 14px 0px"}><Heading textAlign="right" fontSize={"18px"} xsFont={"16px"} display={"block"} color={data.teamBColor}>{data.teamB}</Heading></BodyColumn>

                            </BodyRow>
                        ))}
                    </Table>
                </>
            }

        </Section>
    )
}
const VenueInfo = (venue) => {
    let bat = Number(venue.win_bat_first);
    let ball = Number(venue.win_bowl_first);
    const data = [
        { name: 'winBatFirst', value: bat, fill: bat > ball ? '#DC3A40' : '#359846' },
        { name: 'winBallFirst', value: ball, fill: bat > ball ? "#359846" : '#DC3A40' },
        { name: 'default', value: 100 - (bat + ball), fill: "#4B4A4A" },
    ]

    return (
        <Section marginTop={"20px"}>
            <VenueInfoHead>
                <Heading lgFont={"20px"} smFont={"16px"}>Venue Info </Heading>
            </VenueInfoHead>
            <Section>
                <VenueInfoRow justifyContent="space-between">
                    <Column>

                        <PieChartDiv padding={"5px"}>
                            <div style={{ padding: "30px" }}>
                                <PieChart data={data} label={venue.matches} ></PieChart>
                            </div>

                            <Section marginTop={"0px"} marginRight={"10px"} marginLeft={"10px"} mdMarginTop={"0px"} smWidth={"100%"} mdWidth={"60%"}>
                                <Row justifyContent="space-between">
                                    <SubHeading color={"#737478"} fontWeight={"500"}>Win Bat first</SubHeading>
                                    <SubHeading fontSize={"16px"} fontWeight={"500"} color={venue.win_bat_first > venue.win_bowl_first ? "#359846" : "#DC3A40"}>{venue.win_bat_first}%</SubHeading>
                                </Row>
                                <HorizontalLine margin={"10px"}></HorizontalLine>
                                <Row justifyContent="space-between">
                                    <SubHeading color={"#737478"} fontWeight={"500"}>Win Bowl first</SubHeading>
                                    <SubHeading fontSize={"16px"} fontWeight={"500"} color={venue.win_bat_first > venue.win_bowl_first ? "#DC3A40" : "#359846"}>{venue.win_bowl_first}%</SubHeading>
                                </Row>
                            </Section>
                        </PieChartDiv>

                    </Column>
                    <VenueInningsBlock smMarginTop={"20px"}>

                        <Row justifyContent="space-between">
                            <Column>
                                <SubHeading lgFont={"14px"}>Avg 1st Innings</SubHeading>
                                <Heading fontSize={"16px"} fontWeight={"600"} lgFont={"14px"}>{venue.avg_1_innings || "NA"}</Heading>
                            </Column>
                            <Column alignItems="end">
                                <SubHeading lgFont={"14px"}>Avg 2nd Innings</SubHeading>
                                <Heading fontSize={"16px"} fontWeight={"600"} lgFont={"14px"}>{venue.avg_2_innings || "NA"}</Heading>
                            </Column>
                        </Row>
                        <HorizontalLine margin={"14px 0px"}></HorizontalLine>
                        <Row justifyContent="space-between">
                            <Column>
                                <SubHeading>Highest Total</SubHeading>
                                <Row>
                                    <Heading fontSize={"15px"} fontWeight={"600"} margin={"0px 4px 0px 0px"}>{venue?.highest_total?.score || "NA"}-{venue?.highest_total?.wicket || "NA"}</Heading>
                                    <Heading color={"#737478"} fontSize={"12px"} fontWeight={"400"}>({venue?.highest_total?.over || "NA"}) Ov {venue?.highest_total?.overWithcmt || "NA"}</Heading>
                                </Row>
                            </Column>
                            <Column alignItems="end" justifyContent="center">
                                <Heading color={"#737478"} fontSize={"14px"} fontWeight={"500"}>{venue?.highest_total?.title || "NA"}</Heading>
                            </Column>
                        </Row>
                        <HorizontalLine margin={"14px 0px"}></HorizontalLine>
                        <Row justifyContent="space-between">
                            <Column>
                                <SubHeading>Lowest Total</SubHeading>
                                <Row>
                                    <Heading fontSize={"15px"} fontWeight={"600"} margin={"0px 4px 0px 0px"}>{venue?.lowest_total?.score || "NA"}-{venue?.highest_total?.wicket || "NA"}</Heading>
                                    <Heading color={"#737478"} fontSize={"12px"} fontWeight={"400"}>({venue?.lowest_total?.over || "NA"}) Ov {venue?.lowest_total?.overWithcmt || "NA"}</Heading>
                                </Row>
                            </Column>
                            <Column alignItems="end" justifyContent="center">
                                <Heading color={"#737478"} fontSize={"14px"} fontWeight={"500"}>{venue?.lowest_total?.title || "NA"}</Heading>
                            </Column>
                        </Row>
                        <HorizontalLine margin={"14px 0px"}></HorizontalLine>
                        <Row justifyContent="space-between">
                            <Column>
                                <SubHeading>Highest Chased</SubHeading>
                                <Row>
                                    <Heading fontSize={"15px"} fontWeight={"600"} margin={"0px 4px 0px 0px"}>{venue?.higest_chased?.score || "NA"}-{venue?.higest_chased?.wicket || "NA"}</Heading>
                                    <Heading color={"#737478"} fontSize={"12px"} fontWeight={"400"}>({venue?.higest_chased?.over || "NA"}) Ov  {venue?.higest_chased?.overWithcmt || "NA"}</Heading>
                                </Row>
                            </Column>
                            <Column alignItems="end" justifyContent="center">
                                <Heading color={"#737478"} fontSize={"14px"} fontWeight={"500"}>{venue?.higest_chased?.title || "NA"}</Heading>
                            </Column>
                        </Row>
                        <HorizontalLine margin={"14px 0px"}></HorizontalLine>

                        <Row justifyContent="space-between">
                            <Column>
                                <SubHeading>Lowest Defended</SubHeading>
                                <Row>
                                    <Heading fontSize={"15"} fontWeight={"600"} margin={"0px 4px 0px 0px"}>{venue?.lowest_defended?.score || "NA"}-{venue?.lowest_defended?.wicket || "NA"}</Heading>
                                    <Heading color={"#737478"} fontSize={"12px"} fontWeight={"400"}>({venue?.lowest_defended?.over || "NA"}) Ov  {venue?.lowest_defended?.overWithcmt || "NA"}</Heading>
                                </Row>
                            </Column>
                            <Column alignItems="end" justifyContent="center">
                                <Heading color={"#737478"} fontSize={"14px"} fontWeight={"500"}>{venue?.lowest_defended?.title || "NA"}</Heading>
                            </Column>
                        </Row>

                    </VenueInningsBlock>

                </VenueInfoRow>
            </Section>
        </Section>
    )
}
const VenueDetails = (venue) => {
    const paceData = [
        { name: 'pace', value: venue.pace, fill: venue.pace >= 50 ? '#DC3A40' : '#359846' },
        { name: 'default', value: 100 - (venue.pace), fill: "#CACACA" },
    ]
    const spinData = [
        { name: 'spin', value: venue.spin, fill: venue.spin >= 50 ? '#DC3A40' : '#359846' },
        { name: 'default', value: 100 - (venue.spin), fill: "#CACACA" },
    ]
    return (
        <>
            <Heading fontWeight={"500"} paddingBottom={"8px"} lgFont={"20px"} smFont={"16px"}>Venue Details</Heading>
            <Row alignItems="flex-start">
                <Image src="../../../../Assets/pinRed.svg" width={"20px"} marginTop={"3px"}></Image>
                <Heading fontSize={"16px"} lgFont={"20px"} margin={"0px 5px"} smFont={"14px"} fontWeight={"400"}>{venue?.name}, {venue?.location}, {venue?.country}</Heading>
            </Row>
            <PieChartDiv margin={"40px 40px 0px 40px"} smMargin={"40px  20px 0px 20px"} padding={"0px 50px"} smPadding={"0px 20px"}>
                <Row justifyContent="space-around" style={{ width: "100%" }}>
                    <Column justifyContent="center">
                        <SubHeading fontSize={"14px"} fontWeight={"500"} color={"#737478"} smFont={"13px"} smMarginRight={"12px"}   >OVERALL</SubHeading>
                        <Heading fontSize={"20px"} fontWeight={"600"} lgFont={"16px"} smFont={"14px"} >{venue?.over_all}</Heading>
                    </Column>
                    <VerticalLine></VerticalLine>
                    <Column justifyContent="center">
                        <SubHeading fontSize={"14px"} fontWeight={"500"} color={"#737478"} smFont={"13px"}   >BEST SUITED FOR</SubHeading>
                        <Heading fontSize={"20px"} fontWeight={"600"} lgFont={"16px"} smFont={"14px"}>{venue?.best_suited_for}</Heading>
                    </Column>
                </Row>
            </PieChartDiv>
            <Image width={"100%"} src="../../../../Assets/pitchnew.png"></Image>
            <PieChartDiv padding={"0px 25px"} smPadding={"0px"} margin={"15px auto"}>
                <Row justifyContent="space-between">
                    <Column justifyContent="center">
                        <SubHeading fontSize={"18px"} fontWeight={"400"} color={"#424965"} smFont={"14px"} smMarginRight={"20px"} xsMarginLeft={"10px"}>1st Batting AVS. Score</SubHeading>
                        <Heading fontSize={"26px"} fontWeight={"500"} smFont={"20px"} margin={"0px 0px 0px 10px"}>{venue?.first_bat}</Heading>
                    </Column>
                    <VerticalLine></VerticalLine>
                    <Column justifyContent="center">
                        <SubHeading fontSize={"18px"} fontWeight={"400"} color={"#424965"} smFont={"14px"} smMarginLeft={"20px"}>Highest Score Chased</SubHeading>
                        <Heading fontSize={"26px"} fontWeight={"500"} smFont={"20px"} margin={"0px 0px 0px 20px"}>{venue?.higher_score} </Heading>
                    </Column>
                </Row>
            </PieChartDiv >
            <BlueBackground smPadding={"0px 0px"}>
                <Row justifyContent="center" style={{ width: "100%" }}>
                    <Column>
                        <PieChart data={paceData} label={venue.pace} height={50} width={50} outerRadius={20} innerRadius={15} type={"wicket"}></PieChart>
                        <Heading marginLeft={"10px"} fontSize={"15px"} fontWeight={"300"} smFont={"10px"} margin={"0px auto"}>PACE</Heading>
                    </Column>

                    <HorizontalLineBlue ></HorizontalLineBlue>
                    <WicketSplit>Wicket Splite</WicketSplit>
                    <HorizontalLineBlue ></HorizontalLineBlue>
                    <Column>
                        <PieChart data={spinData} label={venue.spin} height={50} width={50} outerRadius={20} innerRadius={15} type={"wicket"}></PieChart>
                        <Heading marginLeft={"10px"} fontSize={"15px"} fontWeight={"300"} smFont={"10px"} margin={"0px auto"}>SPIN</Heading>
                    </Column>
                </Row>
            </BlueBackground>
        </>

    )
}
const selectUmpire = (umpires, third_umpire) => {
    if (third_umpire === "Not Available") {
        const findThirdUmpire = umpires?.split("),")[2]

        const umpire = umpires.split(findThirdUmpire)[0]
        if (findThirdUmpire) return {
            third_umpire: findThirdUmpire,
            umpires: umpire
        }
        else {
            return {
                third_umpire: third_umpire,
                umpires: umpires
            }
        }
    }
    return {
        third_umpire: third_umpire,
        umpires: umpires
    };
}


const Umpires = (props) => {
    const { match } = props;
    return (
        <UmpireSection>
            <Heading paddingBottom={"10px"} lgFont={"20px"} smFont={"16px"}>Umpires</Heading>
            <SubHeading color={"#424965"} fontWeight={"400"} >Umpire</SubHeading>
            <Heading fontSize={"18px"} fontWeight={"500"} smFont={"14px"} >

                {selectUmpire(match.umpires, match.third_umpire).umpires}
            </Heading>
            <HorizontalLine margin={"10px 0px"}></HorizontalLine>
            <SubHeading color={"#424965"} fontWeight={"400"} >Third Umpire</SubHeading>
            <Heading fontSize={"18px"} fontWeight={"500"} smFont={"14px"} >{selectUmpire(match.umpires, match.third_umpire).third_umpire}</Heading>
            <HorizontalLine margin={"12px 5px"}></HorizontalLine>
            <SubHeading color={"#424965"} fontWeight={"400"} >Refree</SubHeading>
            <Heading fontSize={"18px"} fontWeight={"500"} smFont={"14px"} >{match.referee}</Heading>
        </UmpireSection>
    )
}
const SquadInfo = () => {
    return (<>
        <BasicTabs></BasicTabs>
    </>)
}
const BasicTabs = () => {
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <CustomTabList>
                <CustomTab primary={tabIndex === 0 ? true : false}>JSK
                </CustomTab>
                <CustomTab primary={tabIndex === 1 ? true : false}>MICT</CustomTab>
            </CustomTabList>
            <TabPanel>
                <PlayerDetail></PlayerDetail>
            </TabPanel>
            <TabPanel>
                <PlayerDetail></PlayerDetail>
            </TabPanel>
        </Tabs>
    );
}
const PlayerDetail = () => {
    return (
        <PlayerDetailDiv>


            <Row>
                <img src="../../../../Assets/leaderboardflags.svg"></img>
                <Heading>Quinton de Kock</Heading>
            </Row>

            <img src="../../../../Assets/bat.svg"></img>


        </PlayerDetailDiv>
    )
}
export default InfoTab

const MainContainer = styled.div`
  font-size: 16px;
  text-align: center;
  Columnor: #000000;
  padding: 0% 1.5%;
`;
const Section = styled.div`
margin-top:${props => props.marginTop || "30px"};
margin-right: ${props => props.marginRight};
margin-left:  ${props => props.marginLeft};
@media(max-width: 1340px){
width:${props => props.smWidth};
};
@media(max-width: 1200px){
margin-top:${props => props.mdMarginTop};
width:${props => props.mdWidth};
};
@media(max-width: 500px){

width:${props => props.smWidth};
};
`
const MatchBanner = styled.div`
background-image:url("/images/matchInfoBack.svg");
background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
  display:flex;
  justify-content:space-evenly;
`
const MatchBannerTimerDiv = styled.div`
border: 1px solid #BDBDBD;
background:rgba(0,0,0,0.5);
align-self:center;
padding:60px 30px;
`
const ColumnDiv = styled.div`
  margin-top: 10px;
  background: #FFFFFF;
  padding: ${props => props.padding || "20px"};
margin-bottom: ${props => props.marginBottom};
  border-radius: 8px;
  max-width: 700px;
    width:100%;
    height: fit-content;
  @media(max-width: 1345px) and ( min-width: 1200px){
    max-width:470px;
    
    
  };
    @media(max-width: 1200px){

      margin-bottom: ${props => props.smMarginBottom};
  };
  @media(max-width: 506px){
    width:100%;
  
  };
`;
const LeaderboardBlock = styled.div`
     background:#EAEDF1;
     padding: 8px 30px;
     border-radius: 10px;
`
const LeaderboardText = styled.div`
    font-size:18px;
`
const LeaderboardImage = styled.img`
    height:${props => props.height || "auto"};
    width: ${props => props.width || "30px"};
    border-radius: ${props => props.borderRadius || "0px"};
    margin-right: ${props => props.marginRight || "0px"};
    @media(max-width:450px){
    width: ${props => props.xsWidth};
      height: ${props => props.xsHeight};
   
};
    
`
const PredictWinnerText = styled.div`
    padding-right: 40px;
`
const ShareImage = styled.img`
    max-width:100%;
    cursor: pointer;

    @media(max-width:1199px){
   display:none;
};
`
const ShareCodeContainer = styled.div`
    padding:10px
`
const ShareCodediv = styled.div`
    border : 2px dotted #BCBBBB;
    display:flex;
    justify-content:space-between;
    padding: 10px 5px;
    border-radius : 5px;
`
const ShareButton = styled.div`
    border : 2px solid #7DC6FC;
    display:flex;
    justify-content:space-between;
    border-radius : 35px;
    background: #E8F7FF;
    padding: 5px 20px;
    width:35%;
`
const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "22px"};
text-align: ${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
margin-left:${props => props.marginLeft || "0px"};
margin-right:${props => props.marginRight || "0px"};
display:${props => props.display || "flex"};

align-items :${props => props.alignItems || "center"};
width: ${props => props.width};
margin:${props => props.margin || "0px"};
@media(max-width:1345px){
    font-size: ${props => props.lgFont};
   
};
@media(max-width:1200px){
    font-size: ${props => props.mdFont};
    margin: ${props => props.margin};
};
@media(max-width:500px){
    font-size: ${props => props.smFont};
    margin:${props => props.margin || "0px"};
               margin-left: ${props => props.sxMarginLeft};
    
};
@media(max-width:450px){
    font-size: ${props => props.xsFont};
    margin:${props => props.margin || "0px"};
   
};
`
const SubHeading = styled(Heading)`
font-weight: ${props => props.fontWeight || "200"};
font-size:  ${props => props.fontSize || "16px"};
color: ${props => props.color || "#000000"};
text-align: ${props => props.textAlign || "left"};
margin-left: ${props => props.marginLeft || "0px"};
margin-right: ${props => props.marginRight};
@media(max-width:1340px){
    font-size: ${props => props.lgFont};
       margin-left: ${props => props.smMarginLeft};
margin-right: ${props => props.smMarginRight};
};
@media(max-width:1200px){
    font-size: ${props => props.mdFont};
};
@media(max-width:500px){
    font-size: ${props => props.smFont};
    display:${props => props.smDisplay};
           margin-left: ${props => props.xsMarginLeft};
 
};
@media(max-width:450px){
    
           margin-left: ${props => props.xsMarginLeft};
 
};

`
const ColumnHeading = styled.span`
font-weight: ${props => props.fontWeight || "200"};
font-size:  ${props => props.fontSize || "16px"};
color: ${props => props.color || "#000000"};
text-align: ${props => props.textAlign || "left"};
margin-left: ${props => props.marginLeft || "0px"};
margin-right: ${props => props.marginRight};
@media(max-width:1340px){
    font-size: ${props => props.lgFont}
};
@media(max-width:506px){
    font-size: ${props => props.smFont}
};
`
const MatchLogo = styled(LeaderboardImage)`
width:60px;
height:60px;
margin-right:20px
`
const MatchVenue = styled.div`
background:#F1F3F5;
border-radius: 5px;
padding : 5px 5px;
`
const MatchVenueDiv = styled.div`
padding:10px;
text-align:left;
display:flex;
align-items: flex-start;
`
const HorizontalLine = styled.div`
height:1px;
border: ${props => props.border || "0.7px solid #EFEFEF"};
margin: ${props => props.margin || "4px"};
`
const HorizontalLineBlue = styled.hr`
border:1.5px solid #BBE6FF;
width:${props => props.width || "120px"};
background:#BBE6FF;
margin : 22.5px 0;
`
const TopPlayerdiv = styled.div`
background-color:#F8FAFD;
border: 1px solid #E2E2E2;
padding: 10px 55px;

@media(max-width:506px){
    font-size: ${props => props.smFont};
    padding: 10px ;

width:${props => props.smWidth};
height:${props => props.smHeight};



};
@media(max-width:450px){
    font-size: ${props => props.xsFont}
};

`
const TopPlayerImage = styled(LeaderboardImage)`
width:50px
`
const PlayerScore = styled.span`
font-weight: 500;
margin-right: 5px;
@media(max-width:506px){
    font-size: ${props => props.smFont}
};
@media(max-width:450px){
    font-size: ${props => props.xsFont}
};
`
const TeamPlayerScore = styled(PlayerScore)`
text-align:left
`

const TeamScoreDiv = styled.div`

background:${props => props.background || "#E2E2E2"};
border: 1px solid #E2E2E2;
padding: ${props => props.padding || "10px 15px"};
margin-top:15px;
border-radius: 5px;
cursor :${props => props.cursor || "auto"};

@media(max-width: 1350px) {
    // padding: 10px 1px;
    padding: 10px 14px 10px 2px;
};
@media(max-width: 1200px) {
    padding: 10px 15px;
};
@media (max-width: 450px) {
    padding: ${props => props.smPadding};
}
@media(max-width: 400px) {
    // padding: 10px 1px;
    padding: 10px 14px 10px 10px;
};
`
const RecentMatch = styled.div`
background: #F5F5F5;
border-radius:30px;
padding: 8px 16px;
display:flex;
@media(max-width:506px){
    padding:4px 8px;
}
`
const LastMatchWinBlock = styled.div`
color:${props => props.color || "#FFFFFF"};
background:${props => props.background || "#359846"};
width:30px;
border-radius:5px;
padding: 2px;
border:${props => props.border || "none"};
// margin-left:15px;
margin-right: 10px;

@media(max-width: 1350px) {
    margin-left: ${props => props.smMarginLeft};
    margin-right: ${props => props.smMarginRight};
};
@media(max-width: 1200px) {
    // margin-left:15px;
    margin-right: 10px;
};

@media(max-width: 506px){
width:22px;
padding:1px 4px;
height: auto;
font-size: 15px;
};
@media(max-width: 430px) {
    margin-left: ${props => props.smMarginLeft};
    margin-right: ${props => props.smMarginRight};
};
  

`
const VenueTeamTotalBlock = styled.div`
color: #000000;
background:#F8F8F8;
width:40px;
border-radius:5px;
border: 1px solid #D4D4D4;
font-size:14px;
font-weight:600;
margin-right:6px;
`
const Button = styled.button`
border-radius:${props => props.borderRadius || "10px"};
color: ${props => props.venueToggle ? "#FFFFFF" : "#1890E8"};
border:${props => props.venueToggle ? "none" : "1px solid #0BA9DB"};
background: ${props => props.venueToggle ? "linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)" : "#FFFFFF"};
padding:${props => props.padding || "0px 20px"};
width:${props => props.width};
margin-right:${props => props.marginRight};
height: ${props => props.height || "auto"};
@media(max-width:506px){
    width:${props => props.smWidth};
height: ${props => props.smHeight || "auto"};
font-size:${props => props.xsFont || "10px"};
};
`
const SecondaryButton = styled(Button)`
background: ${props => props.venueToggle ? "linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)" : "#FFFFFF"}
color: ${props => props.venueToggle ? "#FFFFFF" : "#1890E8"};0BA9DB
border: ${props => props.venueToggle ? "none" : "1px solid #0BA9DB"};
height: ${props => props.height || "auto"};
@media(max-width:506px){
    width:${props => props.smWidth};
height: ${props => props.smHeight || "auto"};
font-size:12px;
};
`
const WicketSplit = styled.div`
background: #E8F7FF;
    color: #1890E8;
    border: 1px solid #7DC6FC;
    padding: 8px;
    margin: 7px 0px 24px 0px;
    border-radius: 38px;
    width: 50%;
  @media(max-width: 1340px){
  font-size:10px;
  }
    @media(max-width: 375px){
     width: 100%;
  }
`
const VenueInfoHead = styled.div`
background: linear-gradient(90deg, rgba(255, 255, 255, 0) 1.51%, #E6E6E6 48.07%, rgba(245, 245, 245, 0) 98.33%);
display:flex;
justify-content:center;
`
const PieChartDiv = styled.div`
background:#FDFDFD;
border: 0.5px solid #E2E2E2;
border-radius : 5px;
padding : ${props => props.padding || "25px"};
height: 100%;
margin:${props => props.margin};
 @media(max-width: 1340px){
 display :flex;
 justify-content :space-evenly;
 align-items :center;
 flex-direction :column;
  }
   @media(max-width: 1200px){

 display :flex;
 justify-content :space-evenly;
 align-items :center;
 flex-direction :row;
  }
  @media(max-width: 506px){
 padding : ${props => props.smPadding || "25px"};
 display :flex;
 justify-content :space-evenly;
 align-items :center;
 flex-direction : column ;
 margin:${props => props.smMargin};
  }

`
const BlueBackground = styled(PieChartDiv)`
background:#F5FCFF;
border-color:#7DC6FC;
margin:0px auto;
@media (max-width: 506px) {

padding:${props => props.smPadding};
}


`
const VerticalLine = styled.div`
background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%);
width:1px;
height:100px;
`
const CustomTab = styled(Tab)`
color:${props => props.primary ? "#FFFFFF" : "#0BA9DB"};
border:${props => props.primary ? "none" : "1px solid #0BA9DB"};
background: ${props => props.primary ? "linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)" : "#FFFFFF"} ;
cursor:pointer;

`;
const CustomTabList = styled(TabList)`
list-style-type:none;
display:flex;
justify-content:space-between;
width:100%;
`
const PlayerDetailDiv = styled(PieChartDiv)`
display:flex;
justify-content:space-between;
padding: 15px 40px;
border-radius: 6px;
`
const Image = styled.img`
width:${props => props.width || "30px"};

margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
border-radius:${props => props.borderRadius || "0px"};
margin-left:${props => props.marginLeft || "0px"};
margin-top: ${props => props.marginTop || "0px"};
padding: ${props => props.padding || "0px"};
  @media(max-width: 1340px){
    width:${props => props.lgWidth};
    height:${props => props.lgHeight};
  };
    @media(max-width: 506px){
    width:${props => props.smWidth};
    height:${props => props.smHeight};
  };
      @media(max-width: 450px){
    width:${props => props.xsWidth};
    height:${props => props.xsHeight};

  }
`
const VenueInningsBlock = styled.div`
display:flex;
width:60%;
flex-flow:column;
margin-left:25px;
  @media(max-width: 1340px){
    width:100%;
  justify-content:center;
  margin:0px;
  }

     @media(max-width: 506px){
  margin-top:${props => props.smMarginTop};
  };

`
const CustomRow = styled.div`
display:flex;
width:${props => props.width};
justify-content:space-between;
flex-flow:row;
margin:${props => props.margin};
`
const CustomColumn = styled.div`
display:flex;
width:${props => props.width};
flex-flow:column;
margin:${props => props.margin};

`;
const VenueInfoRow = styled.div`
display:flex;
flex-flow:row;
justify-content:space-between;
@media(max-width: 1340px){
    flex-flow:column;
justify-content:center;
}

`;
const ReferAndEarnSpan = styled.span`
@media(max-width:1200px){
 display: ${props => props.display}
};
`;
const RowDiv = styled.div`
display: flex;
justify-content: space-between;
@media(max-width:500px){
flex-direction:column;
row-gap:10px;
};
`