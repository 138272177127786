import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Row } from 'simple-flexbox';
import { openPlayStore } from '../../../utility';


const MainBox = styled.div`
padding-top: 494px;
margin-bottom: 48px;
background-image:url("/Assets/cricfuninstallimage.svg");
background-size: cover;
background-position: center;
width: 100%;
display:flex;
justify-content:space-evenly;
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:left;
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
margin-left: 16px;
@media(max-width:580px){
    font-size:  ${props => props.smFontSize};
    // margin-right: 16px;
};
@media(max-width:450px){
    font-size:  ${props => props.xsFontSize};
    // margin-right: 16px;
};
`

const Image = styled.img`
width:${props => props.width || "100%"};
height:${props => props.height || "auto"};
margin:${ props => props.margin || "10px auto"};
cursor:pointer;
@media(max-width:900px){
    width:  ${props => props.mdWidth };
  };
@media(max-width:500px){
    width:  ${props => props.smWidth };
  };
`

const CricFunInstallSpinDialog = (props) => {
    return (
        <Dialog open={props?.toggleDialog?.spin}
        
        sx={{
            "& .MuiDialog-paper": {
                margin: "20px 10px",
                borderRadius:"4px"
            },
        }}>
            <Row justifyContent='end'>
                    <Image width={"20px"}smWidth={"12px"} mdWidth={"17px"}  margin={"10px 10px 0px 0px "} src="../Assets/cross.svg" onClick={() => props.handleDialogToggle("spin", false)}></Image>
            </Row>
            <Row>
            <Heading fontSize={"20px"} smFontSize={"20px"} xsFontSize={"18px"} fontWeight={"500"}>To utilise the spin and wheel feature and claim free coins, please install our app from the Play Store.</Heading>
            </Row>
           <Image src={"/Assets/cricfuninstallspin.svg"}  onClick={openPlayStore}></Image>
        </Dialog>
    )
}
export default CricFunInstallSpinDialog