import moment from "moment";
import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function FooterStrip(){
    return(
        <>
                <Container fluid>
                    <Row>
                       <div style={{width:"100%",background:"#fff",padding:"10px",color:"#AFAFAF"}} className="footerStrip">
                       <div style={{float:"left"}} className="reservedBX">
                            {moment(Date.now()).format('YYYY-MM-DD')} © Som Multimedia Co. All rights reserved
                            </div>
                            <div  className="termsbx" style={{float:"right"}}>
                                <ul>
                                    <li><Link to={{ pathname: `/all/terms-conditions`}}>Terms & Conditions</Link> </li>
                                    <li><Link to={{ pathname: `/all/privacy-policy`}}>Privacy Policy</Link> </li>
                                    <li><Link to={{ pathname: `/feedback`}}>Feedback</Link> </li>
                                </ul>
                            </div>
                       </div>
                    </Row>
                </Container>
        </>
    )
}
export default FooterStrip