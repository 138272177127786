import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { DownloadText, GetFreeCoins, MainLogo, NavBarBrand, NavbarDiv, NavbarLink, Image, CustomContainer, MainHeaderRow, BurgerIcon, MobileSideBarDiv, MainHeaderRightRow } from "../MenuStyle";
import '../menuStyle.css'
import { apiBaseUrl, ApiKey } from "../../../config";
import axios from "axios";
import { Column, Row } from "simple-flexbox";
import JoinTheCommunityDialog from "../../pages/LoginAndSIgnup/index";
import MyBalanceDialog from "../../pages/Home/User.js/WalletInfoDialog";
import GetFreeCoinDialog from "../../pages/Coin/GetFreeCoin";
import MyReferTab from "../../pages/Home/User.js/HowItWorkDialog";
import LeaderboardDialog from "../../pages/Leaderboard/leaderboard";
import { openPlayStore } from "../../../utility";
import { NavLink } from "react-router-dom";
import AuthContext from "../../pages/LoginAndSIgnup/context";
import { sessionManager } from "../../../managers/sessionManager";
import { cookiesConstants } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { currentRoutePath } from "../../../reducers/match";

function MainMenu() {
    const [HeaderData, SubHeaderData] = useState([])
    const { handleDialogToggle } = useContext(AuthContext);
    let navigate = useNavigate();
    const dispatchCurrentPath = useDispatch();
    const currentPath = useSelector((state) => { return state.match.currentPath })
    console.log("currentPath", currentPath);
    const [toggleDialog, setToggleDialog] = useState({
        wallet: false,
        freeCoins: false,
        refer: false
    })
    const handleToggleAfterLoginComp = (name, value) => {
        const isLoggedIn = sessionManager.getDataFromCookies(cookiesConstants.IS_LOGGED_IN)
        if (!isLoggedIn) {
            handleDialogToggle("join", true)
            return;
        }
        handleDialogToggleHead(name, value)
    }
    const handleDialogToggleHead = (name, value) => {
        setToggleDialog({
            ...toggleDialog,
            [name]: value
        })

    }
    const [mobileView, setMobileView] = useState(false)
    const [path, setPath] = useState(window.location.pathname)

    const navigateOnClick = (name) => {
        console.log("navigate")
        navigate(name);
        dispatchCurrentPath(currentRoutePath(name));
    }

    useEffect(() => {
        // window.scrollTo(0, 0)
        // SubHeader();
        setPath(window.location.pathname)
        console.log("currentPath", currentPath);

    }, [window.location.pathname, currentPath])
    function SubHeader() {
        var config = {
            method: 'get',
            url: `${apiBaseUrl}/getHeaders`,
            headers: {
                'api-key': ApiKey
            }
        };

        axios(config)
            .then(function (response) {
                SubHeaderData(response.data.result);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <>
            <NavbarDiv>
                <CustomContainer>
                    <Row>
                        <BurgerIcon src="https://storage.googleapis.com/cricfun-images/img-1708425176025.png" onClick={() => setMobileView(!mobileView)} ></BurgerIcon>

                        <NavBarBrand href="/" >
                            <MainLogo src="https://storage.googleapis.com/cricfun-images/img-1708423371381.png" />
                        </NavBarBrand>
                    </Row>
                    <MainHeaderRightRow>
                        <MainHeaderRow justifyContent="space-between">
                            {/* <NavLink to="/"> */}
                            {/* <NavbarLink href="/" active={path === "/" ? true : false} > */}
                            <NavbarLink onClick={() => navigateOnClick("/")} active={currentPath === "/" ? true : false} >
                                Home
                            </NavbarLink>
                            {/* </NavLink> */}

                            {/* <NavLink to="/live"> */}
                            <NavbarLink onClick={() => navigateOnClick("/live")} active={currentPath === "/live" ? true : false} >
                                <Image src="https://storage.googleapis.com/cricfun-images/img-1708423520951.png" width={"23px"} height={"18px"} style={{ margin: "0px 3px 7px 0px" }} mdWidth={"18px"}></Image>
                                Live
                            </NavbarLink>

                            {/* </NavLink> */}
                            {/* <NavLink to="/matches"> */}
                            <NavbarLink onClick={() => navigateOnClick("/live-cricket-score")} active={currentPath === "/live-cricket-score" ? true : false}>
                                Matches
                            </NavbarLink>
                            {/* </NavLink> */}
                            {/* <NavLink to="/game"> */}
                            <NavbarLink onClick={() => navigateOnClick("/game")} active={currentPath === "/game" ? true : false}>
                                Games
                            </NavbarLink>
                            {/* </NavLink> */}
                            {/* <NavLink to="/blog"> */}
                            <NavbarLink onClick={() => navigateOnClick("/blog")} active={currentPath === "/blog" ? true : false}>
                                Blogs
                            </NavbarLink>
                            {/* </NavLink > */}
                            <NavbarLink onClick={() => navigateOnClick("/fantasy-prediction")} active={currentPath === "/fantasy-prediction" ? true : false}>
                                Fantasy Prediction
                            </NavbarLink>
                        </MainHeaderRow>
                        <Row>
                            <NavbarLink onClick={openPlayStore}>
                                <Row>
                                    <DownloadText > Download the app: </DownloadText>
                                    <Column justifyContent="end"><Image smDisplay={"none"} src="https://storage.googleapis.com/cricfun-images/img-1718168804580.png" width={"25px"} xsWidth={"20px"}></Image></Column>
                                </Row>
                            </NavbarLink>
                            <NavbarLink  >
                                <GetFreeCoins onClick={() => handleToggleAfterLoginComp("freeCoins", true)}>
                                    <Image src="https://storage.googleapis.com/cricfun-images/img-1716976427090.png" width={"15px"} height={"15px"} smWidth={"15px"} xsWidth={"8px"} marginRight={"4px"} ></Image>
                                    <DownloadText fontSize={"15px"}> Get Free Coins</DownloadText>
                                </GetFreeCoins>
                            </NavbarLink>
                            <NavbarLink to={{ pathname: "/" }} >
                                <Image src="https://storage.googleapis.com/cricfun-images/img-1717999263508.png" width={"28px"} mdWidth={"25px"} smWidth={"20px"} smPaddingBottom={"0px"} xsWidth={"15px"} marginRight={"10px"} paddingBottom={"6px"} onClick={() => handleToggleAfterLoginComp("wallet", true)}></Image>
                            </NavbarLink>

                            <JoinTheCommunityDialog></JoinTheCommunityDialog>
                            <MyBalanceDialog handleDialogToggle={handleDialogToggleHead} toggleDialog={toggleDialog}></MyBalanceDialog>
                            <GetFreeCoinDialog handleDialogToggle={handleDialogToggleHead} toggleDialog={toggleDialog}></GetFreeCoinDialog>
                            <MyReferTab handleDialogToggle={handleDialogToggleHead} toggleDialog={toggleDialog}></MyReferTab>
                            <LeaderboardDialog></LeaderboardDialog>
                        </Row>
                    </MainHeaderRightRow>
                </CustomContainer>
            </NavbarDiv>
            <Row>
                {MobileSideBar(mobileView, handleToggleAfterLoginComp)}
            </Row>
        </>
    )
}
const MobileSideBar = (mobileView, handleToggleAfterLoginComp) => {
    const path = window.location.pathname;
    return (
        <>
            {mobileView ?
                <MobileSideBarDiv>
                    <Column justifyContent="space-between" >
                        <NavbarLink to="/" href="/" >
                            Home
                        </NavbarLink>
                        <NavbarLink to="/live" href="/live"  >
                            <Image src="https://storage.googleapis.com/cricfun-images/img-1708423520951.png" width={"23px"} height={"18px"} style={{ margin: "0px 3px 7px 0px" }} mdWidth={"18px"}></Image>
                            Live
                        </NavbarLink>
                        <NavbarLink to="/live-cricket-score" href="/live-cricket-score">
                            Matches
                        </NavbarLink>
                        <NavbarLink to="/game" href="/game" >
                            Games
                        </NavbarLink>
                        <NavbarLink to="/blog" href="/blog">
                            Blogs
                        </NavbarLink>
                        <NavbarLink to="/fantasy-prediction" href="/fantasy-prediction">
                            Fantasy Prediction
                        </NavbarLink>

                        <GetFreeCoins onClick={() => handleToggleAfterLoginComp("freeCoins", true)} type={"mobile"}>
                            <Image src="https://storage.googleapis.com/cricfun-images/img-1716976427090.png" width={"15px"} smWidth={"20px"} smHeight={"20px"} marginRight={"5px"}></Image>
                            Get Free Coins
                        </GetFreeCoins>
                    </Column>


                </MobileSideBarDiv>
                : ""}
        </>
    )
}

export default MainMenu