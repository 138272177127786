import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import './Footer.css';
import FooterStrip from "./FooterStrip";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import { CompanyInfo, CompanyName, FollowDiv, FooterLogo, FooterMainContainer, FooterRow, FooterText, Image, PlayStoreDiv } from "./FooterStyle";
import { Column, Row } from "simple-flexbox";
import { Container } from "react-bootstrap";
import { CustomContainer, MainLogo } from "../MenuStyle";
import { Grid } from "@mui/material";
import { openPlayStore } from "../../../utility";
function Footer() {
    const [fantasyCricket] = useState('ALL NEWS');
    const [football] = useState('BLOGS')
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (<>
        <ScrollToTop smooth />
        <FooterMainContainer>
            <CustomContainer flexFlow={"column"}>
                <FooterRow>
                    <Grid container columnSpacing={{ xs: 2, sm: 1, md: 2 }}>
                        <Grid item xs={6} sm={3} md={3} style={{ marginBottom: "20px" }}>
                            <FooterText xsFont={"12px"} color={"#77777D"} size={"20px"} weight={"400"}>CRICFUN</FooterText>
                            <FooterText xsFont={"12px"}><Link to="/AboutUs" onClick={handleScrollToTop} >About Us</Link></FooterText>
                            <FooterText xsFont={"12px"}><Link to="/privacypolicy" onClick={handleScrollToTop} >Privacy Policy</Link></FooterText>
                            <FooterText xsFont={"12px"}><Link to="/termsAndConditions" onClick={handleScrollToTop} >Terms and Conditions</Link></FooterText>
                            <FooterText xsFont={"12px"}><Link to="/resposiveGaming" onClick={handleScrollToTop} >Responsible Gaming</Link></FooterText>
                            <FooterText xsFont={"12px"}><Link to="/rulesAndRegulations" onClick={handleScrollToTop} >Rules & Regulations</Link></FooterText>
                        </Grid>
                        <Grid item xs={6} sm={3} md={3} style={{ marginBottom: "20px" }}>
                            <FooterText xsFont={"12px"} color={"#77777D"} size={"20px"} weight={"400"}>OUR GAMES</FooterText>
                            <FooterText xsFont={"12px"}><a href="/online-ludo-game.html" onClick={handleScrollToTop}>Master Ludo</a></FooterText>
                            <FooterText xsFont={"12px"}><a href="/snakes-and-ladders.html" onClick={handleScrollToTop}>Snakes & Ladders</a></FooterText>
                            <FooterText xsFont={"12px"}><a href="/odds-play.html" onClick={handleScrollToTop}>Odds Play</a></FooterText>
                            <FooterText xsFont={"12px"}><a href="/In-play.html" onClick={handleScrollToTop}>Inplay</a></FooterText>
                            {/* <FooterText><Link to="/game">Snakes & Ladders</Link></FooterText> */}
                            {/* <FooterText>Rummy</FooterText> */}
                            {/* <FooterText><Link to="/game">Odds Play</Link></FooterText>
                    <FooterText><Link to="/game">Inplay</Link></FooterText> */}
                        </Grid>
                        <Grid item xs={6} sm={3} md={3} style={{ marginBottom: "20px" }}>
                            <FooterText xsFont={"12px"} color={"#77777D"} size={"20px"} weight={"400"}>FIND OUT MORE</FooterText>
                            <FooterText xsFont={"12px"}><Link to="/blog" onClick={handleScrollToTop}>Blogs</Link></FooterText>
                            <FooterText xsFont={"12px"}><Link to="/fantasy-prediction" onClick={handleScrollToTop}> Fantasy Prediction</Link></FooterText>
                            {/* <FooterText>Cricket News</FooterText>
                    <FooterText>Football News</FooterText>
                    <FooterText>Fantasy Tips</FooterText>
                    <FooterText>Betting Tips</FooterText> */}
                        </Grid>
                        <Grid item xs={6} sm={3} md={3} style={{ marginBottom: "20px" }}>
                            <FooterText xsFont={"12px"} color={"#77777D"} size={"20px"} weight={"400"}>SUPPORT</FooterText>
                            <FooterText xsFont={"12px"}><Link to="/frequentlyAskedQuestions" onClick={handleScrollToTop}>FAQ</Link></FooterText>
                            <FooterText xsFont={"12px"}><Link to="/contactUS" onClick={handleScrollToTop}>Contact Us</Link></FooterText>
                            <FooterText xsFont={"12px"} color={"#77777D"} size={"20px"} weight={"400"} padding={"10px 0px 0px 0px"}>Email Us</FooterText>
                            <FooterText xsFont={"12px"}><a href="mailto:info@criktok.com">Support@cricfun.com</a></FooterText>
                        </Grid>
                    </Grid>
                </FooterRow>
                <FooterRow>
                    <Grid container columnSpacing={{ xs: 2, sm: 1, md: 2 }}>
                        <Grid item xs={12} sm={4} md={4} style={{ marginBottom: "20px" }}>
                            <CompanyInfo>
                                <Link to="/"><FooterLogo src="https://storage.googleapis.com/cricfun-images/img-1708423371381.png" /></Link>
                                <CompanyName color={"#77777D"} size={"16px"} weight={"400"} padding={"10px 0px 0px 0px"} xsFont={"12px"}>SOM MULTIMEDIA CO. ALL RIGHTS RESERVED.</CompanyName>
                            </CompanyInfo>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4}>
                            <PlayStoreDiv>
                                <FooterText color={"#77777D"} size={"20px"} weight={"400"} padding={"0px 0px 10px 0px"} smFont={"14px"} xsFont={"11px"} >Experience CricFun App</FooterText>
                                <MainLogo src="https://storage.googleapis.com/cricfun-images/img-1716967666839.png" onClick={openPlayStore} />
                            </PlayStoreDiv>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4}>
                            <FollowDiv>
                                <FooterText color={"#77777D"} size={"20px"} weight={"400"} padding={"0px 0px 10px 0px"} smFont={"14px"} xsFont={"11px"} >Keep in touch</FooterText>
                                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                                    <Image cursor={"pointer"} src="https://storage.googleapis.com/cricfun-images/img-1716968935756.png" onClick={() => window.open("https://www.facebook.com/CricFunApp?mibextid=ZbWKwL")}></Image>
                                    <Image cursor={"pointer"} src="https://storage.googleapis.com/cricfun-images/img-1716969057825.png" onClick={() => window.open("https://instagram.com/cricfunapp?igshid=OGQ5ZDc2ODk2ZA==")}></Image>
                                    <Image cursor={"pointer"} src="https://storage.googleapis.com/cricfun-images/img-1716969025830.png" onClick={() => window.open("https://x.com/CricFunApp?t=pgGaL61g277wQfKi1JoOIg&s=09")}></Image>
                                    <Image cursor={"pointer"} src="https://storage.googleapis.com/cricfun-images/img-1716968997057.png" onClick={() => window.open("https://t.me/CricFunApp")}></Image>
                                </Row>
                            </FollowDiv>
                        </Grid>
                    </Grid>
                </FooterRow>
            </CustomContainer>
        </FooterMainContainer>
    </>)
    // return (
    //     <>
    //         <ScrollToTop smooth />
    //         <Container fluid style={{ background: "#EDEEF1", borderTop: "12px solid #0180F0", marginTop: "60px", paddingBottom: "40px", boxShadow: "0px 0px 4px #ccc" }}>
    //             <Container>
    //                 <div className="list-crick">
    //                     <Row>
    //                         <Col xl={4} md={6} sm={12}>
    //                             <ul>
    //                                 <li>Disclaimer</li>
    //                                 <li className="footerTextDecl"><span >The Criktok site is owned and operated by Criktok (“the Company”), and contains material which is derived in whole or in part from material supplied by the Company, its group companies, various news agencies and other sources (including content partners) and is protected by international copyright and trademark laws.</span></li>
    //                                 {/* <li><Link to={{ pathname: `/${football}`}} ><p>Fantasy Football</p></Link></li> */}
    //                             </ul>
    //                         </Col>
    //                         <Col xl={3} md={6} sm={12}>
    //                             <ul>
    //                                 <li>Find Out More</li>
    //                                 <li><NavLink to="/all-news" ><p>All News</p></NavLink></li>
    //                                 <li><NavLink to="/blogs" ><p>Blogs</p></NavLink></li>
    //                                 <li><NavLink to="/fantasy-tips" ><p>Fantasy Tips</p></NavLink></li>
    //                                 {/* <li><Link to={{ pathname: `/${football}`}} ><p>Fantasy Football</p></Link></li> */}
    //                             </ul>
    //                         </Col>
    //                         <Col xl={2} md={6} sm={12}>
    //                             <ul>
    //                                 <li className="contwrapper">Support</li>
    //                                 <li><a href="mailto:info@criktok.com">info@criktok.com</a></li>    
    //                                 <li><Link to={{ pathname: `/all/about-us`}}>About Us</Link> </li>
    //                                 <li><Link to={{ pathname: `/contact`}}>Contact Us</Link> </li>
    //                             </ul>
    //                         </Col>
    //                         <Col xl={3} md={6} sm={12}>
    //                             <ul>
    //                                 <li><li><Image src={footerLogo} className="footerlogo" /></li></li>
    //                             </ul>
    //                             <ul className="fottersocial">
    //                             <li></li>
    //                             <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/CriktokOfficial/"><Image src={FacebookLogo} /></a></li>
    //                             <li><a target="_blank" rel="noreferrer" href="https://twitter.com/criktok"><Image src={twitterLogo} /></a></li>
    //                             <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/criktok/?next=%2F"><Image src={instagramLogo} /></a></li>
    //                             <li><a target="_blank" rel="noreferrer" href="https://t.me/criktok"><Image src={TeleLogo} /></a></li>
    //                         </ul>
    //                         </Col>
    //                     </Row>
    //                     {/* <Row>
    //                         <ul className="fottersocial">
    //                             <li></li>
    //                             <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/criktok/"><Image src={FacebookLogo} /></a></li>
    //                             <li><a target="_blank" rel="noreferrer" href="https://twitter.com/Criktok/"><Image src={twitterLogo} /></a></li>
    //                             <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/Criktok/"><Image src={instagramLogo} /></a></li>
    //                         </ul>
    //                     </Row> */}
    //                 </div>
    //             </Container>
    //         </Container>
    //         <FooterStrip />
    //     </>
    // )
}

export default Footer;