import styled from "styled-components";

export const FooterMainContainer = styled.div`
background:#050A26;
color: #FFFFFF;
padding: 40px 8%;

`
export const FooterText = styled.div`
font-size: ${props => props.size || "24px"};
color: ${props => props.color || "#FFFFFF"};
font-weight: ${props => props.weight || "400"};
padding: ${props => props.padding || "0px 0px 5px 0px"};
font-family: Outfit;
margin-left: ${props => props.marginLeft || "0px"};
@media (max-width:1050px) {
    font-size:20px;
  };
  @media (max-width:840px) {
    font-size:14px;
  };
    @media (max-width:600px) {
    font-size:${props => props.smFont};
  };
    @media (max-width:375px) {
    font-size:${props => props.xsFont};
  };
`
export const CompanyName = styled(FooterText)`
font-family: Outfit;
  @media (max-width:600px) {
    text-align:center;
  };
   @media (max-width:375px) {
    font-size:${props => props.xsFont};
  };
`
export const CompanyInfo = styled.div`
font-family: Outfit;
@media (max-width:650px) {
   display:flex;
   flex-flow:column;
  };
  @media (max-width:650px) {
 align-items:center;
  };
  @media (max-width:415px) {
    text-align: center;
  };
`

export const FooterLogo = styled.img`
max-width:${props => props.width || "140px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
@media (max-width:1399px) {
  max-width: 100px;
};
@media (max-width:630px) {
  max-width: 85px;
  margin: 0 auto;
  
};
@media (max-width:60px) {
  margin: 0 auto;
};
@media (max-width:415px) {
  max-width: 70px;
  margin: 0 auto;
};

`
export const FooterHeaderText = styled.div`
font-size: ${props => props.size || "20px"};
color: ${props => props.color || "#77777D"};
font-weight: ${props => props.weight || "400"};
padding: ${props => props.padding || "0px 0px 5px 0px"};
`
export const FooterRow = styled.div`
display:flex;
justify-content:space-between;
padding-bottom:25px;
width:100%;

`
export const Image = styled.img`
width:${props => props.width || "40px"};
cursor:${props => props.cursor};
margin-right:5px;

@media (max-width:600px) {
   width:30px;
  };
  @media (max-width:370px) {
   width: 25px;
  };
`
export const FollowDiv = styled.div`
text-align: center;
`
export const PlayStoreDiv = styled.div`
text-align: center;

`
