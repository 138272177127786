import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import { sessionManager } from '../../../managers/sessionManager';
import { cookiesConstants } from '../../../constants';
import { openPlayStore } from '../../../utility';

export const CoinDiv = styled.div`
background-color:#FFFFFF;
display: flex;
justify-content: center;
align-items: center;
padding: 17px 0px;
border-radius: 5px;
`

const MainBox = styled.div`
padding: 0px 40px 30px 40px;
max-width: 900px;
@media(max-width:550px){
    padding:0px 10px 0px 10px;
}
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
margin-top: ${props => props.marginTop || "0px"};
margin-right: ${props => props.marginRight || "0px"};
@media(max-width:600px){
    font-size:  ${props => props.smFontSize};
    margin-left: ${props => props.mdMarginLeft};
};
@media(max-width:500px){
    font-size:  ${props => props.xsFontSize};
};
`
const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.marginRight || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
margin-left:${props => props.marginLeft || "0px"};
margin: ${props => props.margin};
padding-bottom :${props => props.paddingBottom || "0px"};
position: ${props => props.position || "static"};
cursor : ${props => props.cursor || "auto"};



@media(max-width:600px){
    width:  ${props => props.smWidth};
};
@media(max-width:500px){
    width:  ${props => props.xsWidth};
    margin-top:${props => props.smMarginTop};
    margin-right:${props => props.smMarginRight || "0px"};
};
`
const ColoumDiv = styled.div`
background-color: #F1F3F4;
padding: 10px;

`
export const EarnExtraColumnDiv = styled.div`
  margin-top: 25px;
  background: #FFFFFF;
  padding:20px 22px;
  border-radius: 8px;
  margin-bottom:10px;
`
export const ExtraEarnDiv = styled.div`
background: #F7F7F8;
border-radius:5px;
display:flex;
justify-content:space-between;
padding: 10px;
width:100%;
margin-bottom:20px;
`
export const ExtraEarnTextDiv = styled.div`
display:flex;
justify-content:space-between;
padding-right: 4px;
`
export const Button = styled.button`
border-radius: 20px;
color: #FFFFFF;
border: none;
font-size: 15px;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
width: 105px;
font-weight: 400;
height: 32px;
@media(max-width:600px){
    height:30px;
    font-size:10px;
};
@media(max-width:500px){
    height:25px;
    padding:0px 10px;
}
`

const EarnExtra = () => {
    const data = [{
        image: "../Assets/youtube.svg",
        text: "Watch a video for",
        secondaryText: "10 Coins",
        buttonText: "Watch Now"
    },
    {
        image: "../Assets/quiz.svg",
        text: "Play a short quiz & get",
        secondaryText: "10 Coins",
        buttonText: "Watch Now"
    },
    {
        image: "../Assets/spin.svg",
        text: "Spin and win",
        secondaryText: "10 Coins",
        buttonText: "Watch Now"
    },
    {
        image: "../Assets/facebookshare.svg",
        text: "Follow us on Facebook",
        secondaryText: "10 Coins",
        buttonText: "Follow Now"
    },
    {
        image: "../Assets/twitterwhite.svg",
        text: "Follow us on Twitter X",
        secondaryText: "100 Coins",
        buttonText: "Follow Now"

    },
    {
        image: "../Assets/instagram.svg",
        text: "Follow us on Instagram",
        secondaryText: "10 Coins",
        buttonText: "Follow Now"

    },
    {
        image: "../Assets/telegram.svg",
        text: "Join us on Telegram",
        secondaryText: "10 Coins",
        buttonText: "Join Now"

    },
    {
        image: "../Assets/playgame.svg",
        text: "Play Games & Earn",
        secondaryText: "Unlimited Coins",
        buttonText: "Play Now"

    }
    ];
    return (
        <EarnExtraColumnDiv>
            <Heading paddingBottom={"15px"} color={"#22213A"} fontSize={"16px"} fontWeight={"500"} textAlign={"center"} style={{ lineHeight: "19.5px" }}>Earn extra coins by completing any of the following activities:</Heading>

            {data.map((item) => (
                <ExtraEarnDiv>
                    <ExtraEarnTextDiv>
                        <Image marginRight={"20px"} src={item.image} smWidth={"25px"} xsWidth={"30px"}></Image>

                        <Heading fontSize={"16px"} fontWeight={"500"} smFontSize={"14px"} xsFontSize={"12px"} marginRight={"5px"} mdMarginLeft={"7px"}>{item.text}
                            <span style={{ color: "#F59400" }}> {item.secondaryText}</span>
                        </Heading>

                    </ExtraEarnTextDiv>
                    <Button onClick={openPlayStore}>{item.buttonText}</Button>
                </ExtraEarnDiv>

            ))}
        </EarnExtraColumnDiv>
    )
}

const GetFreeCoinDialog = (props) => {

    return (

        <Dialog open={props?.toggleDialog?.freeCoins && sessionManager.getDataFromCookies(cookiesConstants.IS_LOGGED_IN)}
            sx={{
                "& .MuiDialog-paper": {
                    margin: "20px 10px",
                    borderRadius: "4px"
                },
            }}>

            <ColoumDiv>
                <Row justifyContent='end'>
                    <Image width={"15px"} position={"fixed"} smMarginTop={"0px"} smMarginRight={"0px"} marginRight={"10px"} marginTop={"10px"} cursor={"pointer"} src="../Assets/cross.svg" onClick={() => props.handleDialogToggle("freeCoins", false)}></Image>
                </Row>
                <MainBox>

                    <Row justifyContent='center'>
                        <Heading paddingBottom={"10px"} textAlign={"center"} fontSize={"18px"} smFontSize={"14px"} xsFontSize={"14px"} fontWeight={"500"} >Get Free Coins</Heading>
                    </Row>
                    <CoinBalance></CoinBalance>
                    <EarnExtra ></EarnExtra>
                </MainBox>
            </ColoumDiv>
        </Dialog>
    )
}

const CoinBalance = () => {
    const walletInfo = sessionManager.getDataFromCookies(cookiesConstants.WALLET_INFO)
    return (
        <>
            <CoinDiv>
                <Column>
                    <Row >
                        <Heading textAlign={"center"} color={"#000000"} fontSize={"24px"} smFontSize={"16px"} xsFontSize={"18px"} fontWeight={"500"}>Your Coin Balance</Heading>
                    </Row>
                    <Row justifyContent='center' alignItems='center'>
                        <Image width={"30px"} height={"30px"} margin={"18px 10px"} src="https://storage.googleapis.com/cricfun-images/img-1716976427090.png"></Image>
                        <Heading textAlign={"center"} color={"#000000"} fontSize={"35px"} marginRight={"17px"} smFontSize={"40px"} xsFontSize={"25px"}>{walletInfo?.rewards + walletInfo?.winning}</Heading>
                    </Row>
                </Column>
            </CoinDiv>
        </>
    )
}
export default GetFreeCoinDialog