import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import AuthContext from './context';
import { axiosRequest } from '../../../utility/axios';
import { failureToast, getDeviceType, successToast } from '../../../utility';
import { redisApiBaseUrl } from '../../../config';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateUserState, updateUserWallet } from '../../../reducers/user';
import CreateOrForgotPassword from './CreatePasswordDialog';



const MainBox = styled.div`
padding:0px 50px 30px 50px;
max-width:450px;
 @media (max-width:450px) {
padding:0px 40px 30px 40px;
  }
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:left;
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
padding:  ${props => props.padding || "0px"};
width: ${props => props.width || "auto"};
cursor: ${props => props.cursor || "none"};
margin-right: ${props => props.marginRight};
@media (max-width:1399px) {
    font-size: ${props => props.mdFont}
  }
  @media (max-width:450px) {
    font-size: ${props => props.smFont};
    width:${props => props.smWidth}
  }
`
const Input = styled.input`
font-size:16px;
border:1px solid #CFCFD3;
width:100%;
border-radius:25px;
height: 50px;
padding: 0px 20px;
margin: 20px 0px 0px 0px;
  @media (max-width:450px) {
    font-size: ${props => props.smFont};
    height:40px;
  }
`
const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border:none;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
height: 50px;
width: 100%;
border-radius: 25px;
font-size:18px;
margin-bottom: 10px;
  @media (max-width:450px) {
    font-size: ${props => props.smFont};
    height:40px;
  }
`
const HorizontalLine = styled.div`
border:1px solid #DEDEDE;
width:100%;
height:1px;
margin:20px 2px 0px 2px;
  @media (max-width:450px) {
   margin:10px 2px 0px 2px;
  }
`
const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
cursor:${props => props.cursor || "auto"};
  @media (max-width:450px) {
width : ${props =>props.mdWidth};
  }
`
const Section = styled.div`
margin-bottom:25px;
`
const ForgotPassword = styled.div`
display:flex;
justify-content:end;
font-size:16px;
cursor: pointer;
 font-weight:400;
  color:#555454;
  margin:10px 0px 20px 0px;

`
const LoginDialog = () => {
    const { handleDialogToggle, toggleDialog, closeAllDialogs } = useContext(AuthContext);
    const dispatch = useDispatch();
    const [loginCredentials, setLoginCredentials] = useState({
        teamName: '',
        password:''
    })

    const logIn = async () => {
        const url = `${redisApiBaseUrl}/userRedisLogin`;
        const request = {
            "username": loginCredentials.teamName,
            "password": loginCredentials.password,
            "device_type": getDeviceType() || "Desktop",
            "device_id":`${ getDeviceType() || "Desktop"}-${moment.now()}`
        }
        const signupDetail = await axiosRequest("POST", url, request)
        if (!signupDetail.status || !signupDetail || signupDetail.errors || !signupDetail.data) {
            failureToast(signupDetail?.message || "Unable to Signup")
            return
        }
        dispatch(updateUserState(signupDetail))
        successToast("Log In Successfully")
        closeAllDialogs()
        const userWalletInfo =  await getUserWalletInfo(signupDetail.data.user_id)
        dispatch(updateUserWallet(userWalletInfo))
        
        setLoginCredentials({ teamName: '',
        password:''})
    }
    const getUserWalletInfo = async (userId) => {
       let url = `${redisApiBaseUrl}/getUserWalletRedis/${userId}`;
        const response = await axiosRequest("GET", url);
        if (!response || response.errors || !response.status) {
            return;
        }
        return response;
    }
    const handleInput = (event) => {
        setLoginCredentials({
            ...loginCredentials,
            [event.target.name]:event.target.value
        })
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            logIn();
        }
    };
   
    return (
        <>
        <Dialog open={toggleDialog.login} sx={{
                "& .MuiDialog-paper": {
                    margin: "20px 10px",
                borderRadius:"5px"
                },

          
               
             
            }}>
            <Row justifyContent='end'>
                <Image width={"20px"}  mdWidth={"15px"} margin={"10px"}  cursor={"pointer"} marginTop={"10px"} src="../Assets/cross.svg" onClick={() => { closeAllDialogs() }}></Image>
                    </Row>
            <MainBox>
                
                    <Heading fontSize={"32px"} fontWeight={"700"} mdFont={"28px"} smFont={"20px"}>Lets Log in</Heading>
                    <Heading fontSize={"24px"} width={"80%"} smWidth={"90%"} mdFont={"20px"} smFont={"16px"} fontWeight={"300"}>Sign in today to embark on your journey with us</Heading>
                <Input placeholder='Mobile/Email Team name' smFont={"14px"} value={loginCredentials.teamName} name='teamName' onChange={handleInput} onKeyPress={handleKeyPress} ></Input>
                <Input placeholder='Password' type="password" smFont={"14px"} value={loginCredentials.password} name='password' onChange={handleInput} onKeyPress={handleKeyPress} ></Input>
                    <ForgotPassword onClick={() => handleDialogToggle('forgotPassword', true)}>Forgot Password</ForgotPassword>
                <Button onClick={logIn} smFont={"14px"}>Login</Button>
                <Section>
            <Row justifyContent='space-between' >
              
                    <HorizontalLine></HorizontalLine>
                    <Heading fontSize={"14px"} smFont={"14px"} padding={"10px"}>Or</Heading>
                    <HorizontalLine></HorizontalLine>
                    </Row>
                </Section>
                {/* <Section>
                    <Row justifyContent='center'>
                <Image height={"30px"} margin={"25px"} src="../Assets/Googlelogo.svg"></Image>
                    <Image height={"30px"} src="../Assets/facebook.svg"></Image>
                    </Row>
                    </Section> */}

                <Row justifyContent='center'>
                    <Heading cursor={"pointer"} fontSize={"20px"} fontWeight={"400"} smFont={"14px"} marginRight = {"10px"}>Don't have an account?</Heading>
                    <Heading fontSize={"20px"} cursor={"pointer"} color={"rgb(22, 138, 192)"} smFont={"14px"} fontWeight={"400"} onClick={() => handleDialogToggle('join', true)}>Signup</Heading>
                </Row> 
            </MainBox>
            </Dialog>
           <CreateOrForgotPassword></CreateOrForgotPassword>
            </>
    )
}
export default LoginDialog