import React, { createContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [toggleDialog, setToggleDialog] = useState({
        join: false,
        otpVerification: false,
        personalDetails: false,
        login: false,
        forgotPassword:false,
        profile: false,
        verifyEmail : false,
        signupCoins: false
        
    })
    const handleDialogToggle = (name, value) => {
      
        if (name === 'forgotPassword' || name === 'join')
        setToggleDialog({
            ...toggleDialog,
            [name]: value,
            login:false
        }) 
        else
        setToggleDialog({
            ...toggleDialog,
            [name]:value
        })
       
    }
    const closeAllDialogs = () => {
        setToggleDialog({
            join: false,
            otpVerification: false,
            personalDetails: false,
            login: false,
            forgotPassword:false,
            profile: false,
            verifyEmail : false,
            signupCoins:false
        })
    }

  return (
    <AuthContext.Provider value={{ toggleDialog, handleDialogToggle , closeAllDialogs}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
