
import React, { useEffect, useState } from "react";
import { Column, Row as FlexRow, Row } from "simple-flexbox";
import styled from "styled-components";
import { MainContainer } from "../MatchInfo/MatchInfoStyle";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";
import { axiosRequest } from "../../../utility/axios";
import { redisApiBaseUrl } from "../../../config";
import { failureToast, favouriteTeamData, getColorForPercentage, getRemainingTime } from "../../../utility";
import SubHeader from "../../components/Header/SubHeader";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { currentScore, selectMatch } from "../../../reducers/match";
import { setLoader } from "../../../reducers/user";
import { Grid } from "@mui/material";

const ReferAndEarnGrid = styled(Grid)`
display:flex;
justify-content:space-between;
@media (max-width:900px) {
  display:none;
   }
`

const LiveBettingMatchList = () => {
    const [matchList, setMatchList] = useState([])
    const dispatchMatch = useDispatch()
    const getLiveBettingMatches = async () => {

        dispatchMatch(setLoader({ data: true }))
        const url = `${redisApiBaseUrl}/matchesList?match_type=bet`;
        const response = await axiosRequest("GET", url, {})
        if (!response || response.errors || !response.status) {
            failureToast(response?.message || "Unable to update")
            dispatchMatch(setLoader({ data: false }))
            setMatchList([])
            return
        }
        setMatchList(response?.data?.matchGroup || [])
        dispatchMatch(setLoader({ data: false }))
    }
    useEffect(() => {
        getLiveBettingMatches()
    }, [])

    return (
        <>
            <SubHeader></SubHeader>

            <MainContainer padding={"1.5% 2%"}>
                <Grid container>
                    <ReferAndEarnGrid item md={3.5} lg={3.5} >
                        {/* {ReferAndEarn()} */}
                        <ReferAndEarn shareButtonMarginLeft={"20px"} ></ReferAndEarn>
                    </ReferAndEarnGrid>
                    <Grid item md={5} lg={5} display={"flex"} justifyContent={"center"} width={"100%"}>
                        <LiveMatchList matchList={matchList}></LiveMatchList>
                    </Grid>
                    <ReferAndEarnGrid md={3.5} lg={3.5}  >

                    </ReferAndEarnGrid>
                </Grid>
            </MainContainer>
        </>
    )
}


const LiveMatchList = (props) => {
    const { matchList } = props;
    return (
        <>
            <ColumnDiv width={"100%"} padding={"15px"}>
                {matchList && matchList.length ? matchList.map((group, index) => (
                    <>
                        {
                            index !== 0 ?
                                <HorizontalLine ></HorizontalLine>
                                : " "
                        }
                        <LiveMatch matchGroup={group}></LiveMatch>
                    </>
                )) : <> <ColumnDiv mdMaxWidth={"700px"} height={"15.5%"} marginRight={"0px"} alignItems={"center"} justifyContent={"center"}>
                    <Heading textAlign={"center"} fontWeight={"400px"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"auto"} paddingBottom={"22px"} fontSize={"18px"}> No Live Matches Available</Heading>
                </ColumnDiv></>}

            </ColumnDiv>
        </>
    )
}


const LiveMatch = ({ matchGroup }) => {
    const dispatch = useDispatch()
    let { matches } = matchGroup;

    matches = matches && matches.length && matches.map((item) => {
        if (item.match_status === "Not Started" || item.match_status === "In Progress") {
            if (item.favourite_team)
                item.favouriteTeamData = favouriteTeamData(item)

            return item
        }
    })
    const onMatchClick = (data) => {
        dispatch(selectMatch({ data }))
        let score = {}
        score.localteam_score = data?.localteam_score ? data?.localteam_score.split("(")[0] : null;
        score.visitorteam_score = data.visitorteam_score ? data?.visitorteam_score.split("(")[0] : null;
        score.localteam_overs = data.localteam_score ? data?.localteam_score.split("(")[1].replace(")", "") : null;
        score.visitorteam_overs = data.visitorteam_score ? data?.visitorteam_score.split("(")[1].replace(")", "") : null;
        score.crr = data?.crr;
        score.rrr = data?.rrr;
        score.target = data?.target;
        score.powerplay = data?.powerplay;
        dispatch(currentScore({ data: score }))
    }
    return (
        <>
            <Heading marginLeft={"10px"} fontSize={"18px"}>{matchGroup.seriesName}</Heading>
            {matches && matches.length && matches.map((match, index) => (
                <>
                    {match.match_status === "In Progress" ?
                        <div onClick={() => { onMatchClick(match) }}>
                            <Link to={{ pathname: `/match-info/?series=${match.series_id}&match=${match.match_id}&localTeam=${match.localteam_id}&visitorTeam=${match.visitorteam_id}&crickSeries=${match?.crick_series_id || 0}&crickMatch=${match?.crick_match_id || 0}&bet=${match?.betting_match}${match?.favourite_team ? `&fav=${match?.favourite_team}` : ""}` }}>
                                <MatchInfoColumn>
                                    <MatchInfoBlock>
                                        <Section marginTop={"25px"} marginBottom={"22px"}>
                                            <FlexRow justifyContent="space-between">

                                                <Section width={"50%"} marginBottom={"0px"} smWidth={"60%"}>
                                                    <Column justifyContent="space-between" >
                                                        <FlexRow justifyContent="space-between" alignItems="center" style={{ marginBottom: "10px" }}>
                                                            <>
                                                                {/* <CountryLogo width={"30px !important"} height={"auto"} margin={"10px"} src={match.localteam_image || "../Assets/dummy1.png"}
                                                                onError={(e) => {
                                                                    if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                                                                }}
                                                            ></CountryLogo>
                                                            <Heading marginRight={"30px"} fontSize={"16px"} margin={"auto 0px"}>{match.localteam_short_name}</Heading> */}
                                                                <FlexRow alignItems="center" >
                                                                    <CountryLogo width={"30px !important"} smWidth={"25px !important"} height={"auto"} margin={"10px"} src={match.localteam_image || "../Assets/dummy1.png"}
                                                                        onError={(e) => {
                                                                            if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                                                                        }}
                                                                    ></CountryLogo>
                                                                    <Heading marginRight={"30px"} fontWeight={"500"} fontSize={"16px"} smFont={"14px"} >{match.localteam_short_name}</Heading>
                                                                </FlexRow>
                                                            </>

                                                            <FlexRow style={{ alignItems: "end" }}>
                                                                <Heading >{match?.localteam_score?.split("(")[0]?.replace("/", "-")}</Heading>
                                                                <Heading color={match?.localteam_score ? "#00000" : "#A2A5B0"} marginLeft={match?.localteam_score ? "0px" : "10px"} fontWeight={"400"} >{match?.localteam_score ? `(${match?.localteam_score?.split("(")[1]}` : match?.visitorteam_score ? "Yet To Bat" : ""}</Heading>
                                                            </FlexRow>
                                                        </FlexRow>

                                                        <FlexRow justifyContent="space-between" alignItems="center">
                                                            {/* <>
                                                            <CountryLogo width={"30px !important"} height={"auto"} margin={"10px"} src={match.visitorteam_image || "../Assets/dummy1.png"}
                                                                onError={(e) => {
                                                                    if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                                                                }}
                                                            ></CountryLogo>
                                                            <Heading marginRight={"30px"} fontSize={"16px"}>{match.visitorteam_short_name}</Heading>
                                                        </> */}
                                                            <>
                                                                <FlexRow alignItems="center">
                                                                    <CountryLogo width={"30px !important"} smWidth={"25px !important"} height={"auto"} margin={"10px"} src={match.visitorteam_image || "../Assets/dummy1.png"}
                                                                        onError={(e) => {
                                                                            if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                                                                        }}
                                                                    ></CountryLogo>
                                                                    <Heading marginRight={"30px"} fontWeight={"500"} fontSize={"16px"} smFont={"14px"} >{match.visitorteam_short_name}</Heading>
                                                                </FlexRow>
                                                            </>

                                                            <FlexRow style={{ alignItems: "end" }}>
                                                                <Heading  >{match?.visitorteam_score?.split("(")[0]?.replace("/", "-")}  </Heading >
                                                                {/* <Heading fontWeight={"400"}>{`(${match?.visitorteam_score?.split("(")[1]}`}</Heading> */}
                                                                <Heading color={match?.visitorteam_score ? "#00000" : "#A2A5B0"} marginLeft={match?.visitorteam_score ? "0px" : "0px"} fontWeight={"400"}>{match?.visitorteam_score ? `(${match?.visitorteam_score?.split("(")[1]}` : match?.localteam_score ? "Yet To Bat" : ""}</Heading>
                                                            </FlexRow>
                                                        </FlexRow>
                                                    </Column>
                                                </Section>
                                                <Column>

                                                </Column>

                                                <Column justifyContent="center">
                                                    <Row alignItems="center">
                                                        <VerticalLine></VerticalLine>
                                                        <CountryLogo width={"120px !important"} height={"40px"} smHeight={"30px"} xsWidth={"95px !important"} src="../Assets/livered.svg"></CountryLogo>

                                                    </Row>
                                                </Column>

                                            </FlexRow>
                                        </Section>
                                        <Heading fontSize={"14px"} fontWeight={"400"} paddingBottom={"8px"}>{match.comment}</Heading>
                                    </MatchInfoBlock>
                                    <LiveScoreDiv >
                                        <FlexRow alignItems="center">
                                            <Heading color={"#686B78"} marginRight={"4px"}>{match?.favourite_team} </Heading>

                                            <RateBlock margin={"0px 4px 0px 7px"} background={"#DC3A40"}>{match?.line_green}</RateBlock>
                                            <RateBlock>{match?.line_red}</RateBlock>
                                        </FlexRow>
                                        <FlexRow> <Heading color={"#686B78"} marginRight={"5px"}>CRR: </Heading><Heading>{match?.crr}</Heading></FlexRow>
                                        <FlexRow>  <Heading color={"#686B78"} marginRight={"5px"}>RRR: </Heading><Heading>{match?.rrr}</Heading></FlexRow>
                                    </LiveScoreDiv>
                                    {index == matches.length - 1 ? "" :
                                        <HorizontalLine ></HorizontalLine>
                                    }


                                </MatchInfoColumn>
                            </Link>
                        </div>
                        :
                        <UpComingDiv onMatchClick={onMatchClick} match={match} matches={matches} index={index}></UpComingDiv>
                        // <div onClick={() => { onMatchClick(match) }}>
                        //     <Link to={{ pathname: `/match-info/?series=${match.series_id}&match=${match.match_id}&localTeam=${match.localteam_id}&visitorTeam=${match.visitorteam_id}&crickSeries=${match?.crick_series_id || 0}&crickMatch=${match?.crick_match_id || 0}&bet=${match?.betting_match}${match?.favourite_team ? `&fav=${match?.favourite_team}` : ""}` }}>
                        //         <MatchInfoBlock>
                        //             <Section marginTop={"25px"} marginBottom={"0px"}>
                        //                 <FlexRow justifyContent="space-between">
                        //                     <Column justifyContent="space-between">

                        //                         <FlexRow style={{ marginBottom: "10px" }}>
                        //                             <>
                        //                                 <CountryLogo width={"30px !important"} smWidth={"25px !important"} height={"auto"} margin={"10px"} src={match.localteam_image || "../Assets/dummy1.png"}
                        //                                     onError={(e) => {
                        //                                         if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                        //                                     }}
                        //                                 ></CountryLogo>
                        //                                 <Heading marginRight={"30px"} fontWeight={"500"} fontSize={"16px"} smFont={"14px"} margin={"auto 0px"}>{match.localteam_short_name}</Heading>
                        //                             </>
                        //                         </FlexRow>

                        //                         <FlexRow >
                        //                             <>
                        //                                 <CountryLogo width={"30px !important"} smWidth={"25px !important"} height={"auto"} margin={"10px"} src={match.visitorteam_image || "../Assets/dummy1.png"}
                        //                                     onError={(e) => {
                        //                                         if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                        //                                     }}
                        //                                 ></CountryLogo>
                        //                                 <Heading marginRight={"30px"} fontWeight={"500"} fontSize={"16px"} smFont={"14px"} margin={"auto 0px"}>{match.visitorteam_short_name}</Heading>
                        //                             </>
                        //                         </FlexRow>

                        //                     </Column>


                        //                     <Row style={{ margin: "auto 0px" }}>
                        //                         <VerticalLine marginRight={"20px"}></VerticalLine>
                        //                         <Column>
                        //                             <Heading fontSize={"16px"} fontWeight={"400"} marginRight={"40px"}>{getRemainingTime(match.start_date).text}</Heading>
                        //                             <Heading fontSize={"16px"} fontWeight={"600"}>{getRemainingTime(match.start_date).time}</Heading>
                        //                         </Column>
                        //                     </Row>

                        //                 </FlexRow>
                        //             </Section>
                        //             <Section marginTop={"20px"} marginBottom={"0px"}>
                        //                 <FlexRow justifyContent="space-between">
                        //                     <Column style={{ width: "40%" }}>
                        //                         <FlexRow justifyContent="space-between">
                        //                             <Heading fontSize={"16px"} fontWeight={"500"} color={"#686B78"} marginLeft={"5px"} marginRight={"15px"} smFont={"14px"} >Market : </Heading>
                        //                             <Heading fontSize={"16px"} fontWeight={"500"} textAlign={"right"} smFont={"14px"} > {match?.line_green}-{match?.line_red}</Heading>

                        //                         </FlexRow>

                        //                         <FlexRow justifyContent="space-between">
                        //                             <>
                        //                                 <Heading fontSize={"16px"} fontWeight={"500"} color={"#686B78"} marginRight={"15px"} marginLeft={"5px"} smFont={"14px"}  >{match?.over_session_one?.overs} Overs : </Heading>
                        //                                 <Heading color={"#686B78"} fontSize={"16px"} fontWeight={"500"} textAlign={"right"} smFont={"14px"} >{match?.over_session_one?.no}-{match?.over_session_one?.yes}</Heading>
                        //                             </>
                        //                         </FlexRow>
                        //                     </Column>
                        //                     <VerticalLine></VerticalLine>
                        //                     <Column style={{ width: "40%" }}>
                        //                         <FlexRow justifyContent="space-between">
                        //                             <>
                        //                                 <Heading fontSize={"16px"} fontWeight={"500"} color={"#686B78"} marginRight={"15px"} smFont={"14px"} >Market : </Heading>
                        //                                 <Heading fontSize={"16px"} fontWeight={"500"} textAlign={"right"} smFont={"14px"} >00-00</Heading>
                        //                             </>
                        //                         </FlexRow>

                        //                         <FlexRow justifyContent="space-between">
                        //                             <>
                        //                                 <Heading fontSize={"16px"} fontWeight={"500"} color={"#686B78"} marginRight={"15px"} smFont={"14px"} >{match?.over_session_two?.overs} Overs : </Heading>
                        //                                 <Heading fontSize={"16px"} fontWeight={"500"} textAlign={"right"} smFont={"14px"} >{match?.over_session_two?.no}-{match?.over_session_two?.yes}</Heading>
                        //                             </>
                        //                         </FlexRow>
                        //                     </Column>
                        //                 </FlexRow>
                        //             </Section>
                        //             {match.favouriteTeamData ?
                        //                 <Section marginTop={"20px"} marginBottom={"0px"}>
                        //                     <FlexRow justifyContent="space-between">
                        //                         <Column >
                        //                             <Heading color={"#6F6F6F"} fontSize={"14px"} width={"max-content"} fontWeight={"500"}>{match.favouriteTeamData.team_a.name}</Heading>
                        //                             <Heading fontSize={"14px"} fontWeight={"500"}>{match.favouriteTeamData.team_a.percentage || 0}%</Heading>
                        //                         </Column>
                        //                         <WinPercentageDiv background={() => getColorForPercentage(match.favouriteTeamData.team_a.percentage, match.favouriteTeamData.team_a.color, match.favouriteTeamData.team_b.color)} ></WinPercentageDiv>
                        //                         <Column >
                        //                             <Heading color={"#6F6F6F"} fontSize={"14px"} width={"max-content"} fontWeight={"500"}>{match.favouriteTeamData.team_b.name}</Heading>
                        //                             <Heading fontSize={"14px"} textAlign={"right"} fontWeight={"500"}>{match.favouriteTeamData.team_b.percentage || 0}%</Heading></Column>
                        //                     </FlexRow>
                        //                 </Section>
                        //                 : ""}


                        //             {index == matches.length - 1 ? "" :
                        //                 <HorizontalLine ></HorizontalLine>
                        //             }
                        //         </MatchInfoBlock>
                        //     </Link>
                        // </div>
                    }
                </>
            ))}

        </>
    )
}

const UpComingDiv = ({ onMatchClick, match, matches, index }) => {
    const [time, setTime] = useState({
        text: getRemainingTime(match.start_date).text,
        time: getRemainingTime(match.start_date).time
    })
    useEffect(() => {
        const matchStartInterval = setInterval(() => {
            const temp = getRemainingTime(match.start_date);
            setTime({
                text: temp.text,
                time: temp.time
            })
            console.log("calling -->  ")
        }, 1000)
        return () => clearInterval(matchStartInterval);
    })
    return (
        <div onClick={() => { onMatchClick(match) }}>
            <Link to={{ pathname: `/match-info/?series=${match.series_id}&match=${match.match_id}&localTeam=${match.localteam_id}&visitorTeam=${match.visitorteam_id}&crickSeries=${match?.crick_series_id || 0}&crickMatch=${match?.crick_match_id || 0}&bet=${match?.betting_match}${match?.favourite_team ? `&fav=${match?.favourite_team}` : ""}` }}>
                <MatchInfoBlock>
                    <Section marginTop={"25px"} marginBottom={"0px"}>
                        <FlexRow justifyContent="space-between">
                            <Column justifyContent="space-between">

                                <FlexRow style={{ marginBottom: "10px" }}>
                                    <>
                                        <CountryLogo width={"30px !important"} smWidth={"25px !important"} height={"auto"} margin={"10px"} src={match.localteam_image || "../Assets/dummy1.png"}
                                            onError={(e) => {
                                                if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                                            }}
                                        ></CountryLogo>
                                        <Heading marginRight={"30px"} fontWeight={"500"} fontSize={"16px"} smFont={"14px"} margin={"auto 0px"}>{match.localteam_short_name}</Heading>
                                    </>
                                </FlexRow>

                                <FlexRow >
                                    <>
                                        <CountryLogo width={"30px !important"} smWidth={"25px !important"} height={"auto"} margin={"10px"} src={match.visitorteam_image || "../Assets/dummy1.png"}
                                            onError={(e) => {
                                                if (e.target.src !== "../Assets/dummy1.png") { e.target.onerror = null; e.target.src = "../Assets/dummy1.png" }
                                            }}
                                        ></CountryLogo>
                                        <Heading marginRight={"30px"} fontWeight={"500"} fontSize={"16px"} smFont={"14px"} margin={"auto 0px"}>{match.visitorteam_short_name}</Heading>
                                    </>
                                </FlexRow>

                            </Column>


                            <Row style={{ margin: "auto 0px" }}>
                                <VerticalLine marginRight={"20px"}></VerticalLine>
                                <Column>
                                    <Heading fontSize={"16px"} fontWeight={"400"} marginRight={"40px"}>{time.text}</Heading>
                                    <Heading fontSize={"16px"} fontWeight={"600"}>{time.time}</Heading>
                                </Column>
                            </Row>

                        </FlexRow>
                    </Section>
                    <Section marginTop={"20px"} marginBottom={"0px"}>
                        <FlexRow justifyContent="space-between">
                            <Column style={{ width: "40%" }}>
                                <FlexRow justifyContent="space-between">
                                    <Heading fontSize={"16px"} fontWeight={"500"} color={"#686B78"} marginLeft={"5px"} marginRight={"15px"} smMarginRight={"8px"} smFont={"14px"} xsMarginRight={"8px"}>Market : </Heading>
                                    <Heading fontSize={"16px"} fontWeight={"500"} textAlign={"right"} smFont={"14px"} > {match?.line_green}-{match?.line_red}</Heading>

                                </FlexRow>

                                <FlexRow justifyContent="space-between">
                                    <>
                                        <Heading fontSize={"16px"} fontWeight={"500"} color={"#686B78"} marginRight={"15px"} marginLeft={"5px"} smFont={"14px"} xsMarginRight={"8px"} >{match?.over_session_one?.overs} Overs : </Heading>
                                        <Heading color={"#686B78"} fontSize={"16px"} fontWeight={"500"} textAlign={"right"} smFont={"14px"} >{match?.over_session_one?.no}-{match?.over_session_one?.yes}</Heading>
                                    </>
                                </FlexRow>
                            </Column>
                            <VerticalLine></VerticalLine>
                            <Column style={{ width: "40%" }}>
                                <FlexRow justifyContent="space-between">
                                    <>
                                        <Heading fontSize={"16px"} fontWeight={"500"} color={"#686B78"} marginRight={"15px"} smFont={"14px"} xsMarginRight={"8px"} >Market : </Heading>
                                        <Heading fontSize={"16px"} fontWeight={"500"} textAlign={"right"} smFont={"14px"} >00-00</Heading>
                                    </>
                                </FlexRow>

                                <FlexRow justifyContent="space-between">
                                    <>
                                        <Heading fontSize={"16px"} fontWeight={"500"} color={"#686B78"} marginRight={"15px"} smFont={"14px"} xsMarginRight={"8px"}>{match?.over_session_two?.overs} Overs : </Heading>
                                        <Heading fontSize={"16px"} fontWeight={"500"} textAlign={"right"} smFont={"14px"} >{match?.over_session_two?.no}-{match?.over_session_two?.yes}</Heading>
                                    </>
                                </FlexRow>
                            </Column>
                        </FlexRow>
                    </Section>
                    {match.favouriteTeamData ?
                        <Section marginTop={"20px"} marginBottom={"0px"}>
                            <FlexRow justifyContent="space-between">
                                <Column >
                                    <Heading color={"#6F6F6F"} fontSize={"14px"} width={"max-content"} fontWeight={"500"}>{match.favouriteTeamData.team_a.name}</Heading>
                                    <Heading fontSize={"14px"} fontWeight={"500"}>{match.favouriteTeamData.team_a.percentage || 0}%</Heading>
                                </Column>
                                <WinPercentageDiv background={() => getColorForPercentage(match.favouriteTeamData.team_a.percentage, match.favouriteTeamData.team_a.color, match.favouriteTeamData.team_b.color)} ></WinPercentageDiv>
                                <Column >
                                    <Heading color={"#6F6F6F"} fontSize={"14px"} width={"max-content"} fontWeight={"500"}>{match.favouriteTeamData.team_b.name}</Heading>
                                    <Heading fontSize={"14px"} textAlign={"right"} fontWeight={"500"}>{match.favouriteTeamData.team_b.percentage || 0}%</Heading></Column>
                            </FlexRow>
                        </Section>
                        : ""}


                    {index == matches.length - 1 ? "" :
                        <HorizontalLine ></HorizontalLine>
                    }
                </MatchInfoBlock>
            </Link>
        </div>
    )
}

export const Arrow = styled.img`
width: 7px;
`
export const MatchNamediv = styled.div`
display:flex;
justify-content:space-between;
margin-left:${props => props.marginLeft || "0px"};
margin-right:${props => props.marginRight || "0px"};
// padding-bottom:10px;
`

export const Section = styled.div`
margin-bottom: ${props => props.marginBottom || "20px"};
margin-top: ${props => props.marginTop};
width:${props => props.width};
@media (max-width:500px) {
width:${props => props.smWidth};
  
     }
`

export const MatchInfoBlock = styled.div`
padding: 0px 10px;
background: ${props => props.background || ""};
margin-top: ${props => props.marginTop || ""};
margin-bottom: ${props => props.marginBottom || ""};
`

export const Time = styled.div`
display: flex;
flex-flow: column nowrap;
justify-content: ${props => props.justifyContent || "center"};
padding: ${props => props.padding || "20px"}
`

export const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "14px"};
text-align:  ${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
margin-right:${props => props.marginRight || "0px"};
margin-left: ${props => props.marginLeft || "0px"};
width:${props => props.width};
margin-top: ${props => props.marginTop || "0px"};
border-bottom:${props => props.border || "none"};
padding:${props => props.padding};
margin: ${props => props.margin};
@media (max-width:540px) {
    font-size:${props => props.smFont};
     }
    @media (max-width: 375px) {
margin-right:${props => props.xsMarginRight};
     }
`
export const HorizontalLine = styled.div`
background: ${props => props.background || "linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%)"};
width:${props => props.width || "100%"};
height: ${props => props.height || "1px"};
border-top: ${props => props.borderTop || "0px"};
border-bottom: ${props => props.borderBottom || "0px"};
border: ${props => props.border || "0px"};
margin:20px 0px;
`
export const CountryLogo = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};

  @media(max-width:500px){
    width:${props => props.smWidth};
   height:${props => props.smHeight};
  };
    @media(max-width:500px){
width:${props => props.xsWidth};

   
  };




`
export const MarketNamediv = styled.div`
display:flex;
justify-content:space-between;
padding-left:${props => props.marginLeft || "0px"};
padding-right:${props => props.marginRight || "0px"};
`;
export const VerticalLine = styled.div`
background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%);
width:1px;
height: ${props => props.height || "60px"};
margin-right: ${props => props.marginRight};
`
const LiveScoreDiv = styled.div`
display:flex;
justify-content: ${props => props.justifyContent || "space-between"};
padding:12px 20px;
background: linear-gradient(90.02deg, rgba(245, 245, 245, 0.4) 2.05%, #F5F5F5 55.35%, rgba(245, 245, 245, 0.4) 102.02%);
align-items :center;
width:100%;
`
export const RateBlock = styled.div`
color:#FFFFFF;
background: ${props => props.background || "#359846"};
width:35px;
height : 35px;
border-radius:5px;
padding: 5px 0px 0px 0px;
margin: ${props => props.margin};
 @media(max-width:500px){
   width:24px;
height : 24px;
padding: 0px 0px 0px 0px;
  };
`

export const MatchMarketBlock = styled.div`
padding: 0px 10px;
width: 500px;
`

const WinPercentageDiv = styled.div`
height: 3px !important;
width: 85%;
background:${props => props.background};
margin-top:30px;
`

export const ColumnDiv = styled.div`
  margin: 10px 0px;
  background: #FFFFFF;
  padding-top:20px;
  border-radius: 8px;
  max-width:600px;
  width:95%;
  padding: ${props => props.padding};
  @media (max-width:900px) {
    width:100%;
     }
 
`;
export const MatchHeader = styled.div`
color: #A2A5B0;
`

const MatchInfoColumn = styled.div`
`
export default LiveBettingMatchList
// export default   Matches