import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { Row } from 'simple-flexbox';
import { openPlayStore } from '../../../utility';


const MainBox = styled.div`
padding-top: 494px;
margin-bottom: 48px;
background-image:url("/Assets/cricfuninstallimage.svg");
background-size: cover;
background-position: center;
width: 100%;
display:flex;
justify-content:space-evenly;
`;

const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
font-size:  ${props => props.fontSize || "24px"};
text-align:${props=>props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
width: ${props => props.width || "auto"};
margin-left: 16px;
@media(max-width:580px){
    font-size:  ${props => props.smFontSize};
    margin:right: 16px;
};
@media(max-width:450px){
    font-size:  ${props => props.xsFontSize};
    margin:right: 16px;
};
`

const Image = styled.img`
width:${props => props.width || "100%"};
height:${props => props.height || "auto"};

margin:${ props => props.margin || "10px auto"};
@media(max-width:900px){
    width:  ${props => props.mdWidth };
  };
@media(max-width:500px){
    width:  ${props => props.smWidth };
  };
cursor:pointer;
`

const CricFunInstallCoinBurnDialog = (props) => {
    return (
        <Dialog open={props?.toggleDialog?.burnCoins}
        sx={{
            "& .MuiDialog-paper": {
           margin: "20px 10px",
                borderRadius:"4px"
            },
        }}>
            <Row justifyContent='end'>
                    <Image   width={"20px"} smWidth={"12px"} mdWidth={"17px"} margin={"10px 10px 0px 0px "} src="../Assets/cross.svg" onClick={() => props.handleDialogToggle("burnCoins", false)}></Image>
            </Row>
            <Row justifyContent='center'>
            <Heading fontSize={"17px"}  textAlign={"center"} smFontSize={"16px"} xsFontSize={"14px"} fontWeight={"500"}>To utilise coins install our app from the Play Store.</Heading>
            </Row>
           <Image src={"/Assets/cricfuninstallcoinburn.svg"} onClick={openPlayStore} ></Image>
        </Dialog>
    )
}
export default CricFunInstallCoinBurnDialog
