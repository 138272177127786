import { createSlice } from '@reduxjs/toolkit'
import { sessionManager } from "../managers/sessionManager";
import { cookiesConstants } from '../constants';
import { act } from 'react-dom/test-utils';

const initialState = {
  profileDetails: {},
  isLoggedIn: false,
  currentMatch: {},
  walletInfo: {},
  loading:false
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateUserState: (state, action) => {
      state.profileDetails = action.payload.data;
      sessionManager.setDataInCookies(action.payload.data, cookiesConstants.PROFILE_DETAILS);
      sessionManager.setDataInCookies(true, cookiesConstants.IS_LOGGED_IN);
    },
      updateSignUpUserState: (state, action) => {
      state.profileDetails = action.payload.data;
      sessionManager.setDataInCookies(action.payload.data, cookiesConstants.PROFILE_DETAILS);
    },
    updateLoginState: (state, action) => {
      state.isLoggedIn = true;
      state.profileDetails = action.payload.data;
      sessionManager.setDataInCookies(action.payload.data, cookiesConstants.PROFILE_DETAILS);
      sessionManager.setDataInCookies(true, cookiesConstants.IS_LOGGED_IN);
    },
    updateUserWallet: (state, action) => {
      state.walletInfo = action.payload.data;
      sessionManager.setDataInCookies(action.payload.data, cookiesConstants.WALLET_INFO);
    },
    clearProfileData: (state) => {
      state.profileDetails = {}
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.profileDetails = {};
      sessionManager.removeDataFromCookies(cookiesConstants.PROFILE_DETAILS);
      sessionManager.removeDataFromCookies(cookiesConstants.IS_LOGGED_IN);
      sessionManager.removeDataFromCookies(cookiesConstants.WALLET_INFO);
    },
    setLoader: (state, action) => {
      state.loading = action.payload.data
    },
  },
})

export const { loginState, clearProfileData, updateUserState, logout, updateUserWallet, updateSignUpUserState, updateLoginState, setLoader } = userSlice.actions

export default userSlice.reducer   