import React, { useEffect, useState } from "react";
import { ColumnDiv, ShareCodeContainer, ShareCodediv, ShareImage, ShareButton, ReferAndEarnDiv, ReferAndEarnBottomSection, Image, Heading } from "../pagesStyle";
import { Row } from "simple-flexbox";
import CopyToClipboard from "react-copy-to-clipboard";
import { WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon, TwitterShareButton, TwitterIcon } from "react-share";
import { WebAppUrl } from "../../../config";
import { sessionManager } from "../../../managers/sessionManager";
import { cookiesConstants } from "../../../constants";
import { openPlayStore, successToast } from "../../../utility";
import { Grid } from "@mui/material";
import copyicon from "../../../image/copyicon.svg"
const ReferAndEarn = (props) => {
    const [shareUrl, setShareUrl] = useState('');
    const referCode = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS)?.refer_id || "";

    const handleCopy = () => {

        successToast("Copied!!")
    };

    const handleShare = async () => {

        successToast("Copied Share Link!!")
        // if (navigator.share) {

        //   try {
        //     await navigator.share({
        //       title: 'Join My App!',
        //       text: `Use this invite code to join: ${referCode}`,
        //       url: window.location.href
        //     });
        //     console.log('Invite code shared successfully');
        //   } catch (error) {
        //     console.error('Error sharing invite code:', error);
        //   }
        // } else {
        //   alert('Web Share API is not supported in your browser.');
        // }
    };
    useEffect(() => {
        const baseUrl = "https://play.google.com/store/apps/details?id=com.app.sports.cricketlive&pcampaignid=web_share";
        const shareCode = referCode;
        const completeUrl = `${baseUrl}&shareCode=${shareCode}`;
        setShareUrl(completeUrl);
    }, [referCode])

    return (
        <>
            {sessionManager.getDataFromCookies(cookiesConstants.IS_LOGGED_IN) ?
                <ReferAndEarnDiv maxWidth={props?.maxWidth || "700px"} width={props?.width} margin={props?.margin} padding={props?.padding} marginTop={props?.marginTop}>
                    <ShareImage src="../Assets/frame2.png" display={"block"} padding={props?.paddingImage}></ShareImage>

                    <Row>
                        <div style={{ paddingLeft: "15px", marginTop: "20px" }}>
                            Share your Code
                        </div>
                    </Row>
                    <ShareCodeContainer>
                        <ShareCodediv>
                            <span>{referCode}</span>
                            <CopyToClipboard
                                text={referCode}
                                onCopy={handleCopy}

                            >
                                <img
                                    style={{ cursor: "pointer" }}
                                    src="../Assets/copyIcon.svg"
                                />
                            </CopyToClipboard>
                        </ShareCodediv>
                    </ShareCodeContainer>


                    <ShareCodeContainer padding={props.padding || "10px 20px 25px 15px"}>
                        <Grid container>
                            <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3} display={"flex"} justifyContent={"center"}>
                                <WhatsappShareButton
                                    url={WebAppUrl}
                                    title={`Join CricFun`}
                                >
                                    <Image width="60%" src="https://storage.googleapis.com/cricfun-images/img-1718696834766.png" cursor={"pointer"}></Image>
                                    {/* <WhatsappIcon size={"40px"} borderRadius={"10px"}  ></WhatsappIcon> */}
                                </WhatsappShareButton>
                            </Grid>
                            <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3} display={"flex"} justifyContent={"center"} >
                                <TelegramShareButton

                                    url={WebAppUrl}
                                    title={`Join CricFun`}>
                                    <Image width="60%" src="https://storage.googleapis.com/cricfun-images/img-1718696946655.png" cursor={"pointer"}></Image>
                                    {/* <TelegramIcon size={"40px"} borderRadius={"10px"}></TelegramIcon> */}
                                </TelegramShareButton>
                            </Grid>
                            <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} display={"flex"} justifyContent={"center"} >
                                {/* <Image  src={"https://storage.googleapis.com/cricfun-images/img-1716987393517.png"} width={"41px"}></Image> */}
                                <TwitterShareButton
                                    url={WebAppUrl}
                                    title={`Join CricFun`}>
                                    <Image width="60%" src="https://storage.googleapis.com/cricfun-images/img-1718696900563.png" cursor={"pointer"}></Image>
                                    {/* <TwitterIcon size={"40px"} borderRadius={"10px"}>

                                </TwitterIcon> */}
                                </TwitterShareButton>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} display={"flex"} justifyContent={"right"} >
                                <CopyToClipboard
                                    text={shareUrl}
                                    onCopy={handleShare}

                                >
                                    <ShareButton padding={props?.shareButtonPadding ? props.shareButtonPadding : "6px 25px"} width={props?.shareButtonWidth ? props.shareButtonWidth : "95%"} marginLeft={props?.shareButtonMarginLeft}>
                                        <Image width={"17px"} margin={"5px"} src={copyicon} ></Image>
                                        <Heading fontWeight={"500"} fontSize={"18px"}>Copy</Heading>

                                    </ShareButton>
                                </CopyToClipboard>

                            </Grid>

                        </Grid>
                    </ShareCodeContainer>

                </ReferAndEarnDiv> : ""}
        </>)
}

export default ReferAndEarn;


