import moment from "moment";
import ToastService from "react-material-toast";
import Snackbar from '@mui/material/Snackbar';


const toast = ToastService.new({
  place: "topRight",
  duration: 1,
  maxCount: 2,
  pauseOnHover: false, // Add this line to pause the toast on hover
});

export const getColorForPercentage = (percentage, color1, color2) => {
  return `linear-gradient(90deg, ${color1} ${percentage}%, ${color2} ${100 - percentage}%)`;
}

export const getDate = (date) => {
  return {
    time: moment(date).format('lll'),
    text: ""
  }
}

export const getRemainingTime = (target) => {
  let duration = moment.duration(moment(target)?.diff(moment()));
  
  if(duration.days()>= 1 && duration.days()<= 3){
    if(duration.days() == 3 && duration.minutes() > 0) return getDate(target);
    return {
      time: `${duration.hours()+(24*duration.days())}h : ${duration.minutes()}m : ${duration.seconds()}s`,
      text: "Starting in: "
    }
  }
  else if(duration.days()> 3) return getDate(target);
  
  return {
    time: `${duration.hours()}h : ${duration.minutes()}m : ${duration.seconds()}s`,
    text: "Starting in: "
  }
}

export const getDeviceType = () => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.match(/Macintosh/i)) {
    return "macOS";
  } else if (userAgent.match(/Windows/i)) {
    return "Windows";
  } else if (userAgent.match(/Android/i)) {
    return "Android";
  }
}
export const validatePhoneNumber = (phone) => {
  const phonePattern = /^[6-9]\d{9}$/;
  if (phonePattern.test(phone)) {
    return true
  } else {
    return false
  }
};
export const showToast = (message) => {
  toast(message, {
    duration: 4000,
    position: 'top-center',
    // Styling
    style: {},
    className: '',
    // Custom Icon
    icon: '👏',
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },
    // Aria
    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });
}

export const failureToast = (message) => {
  toast.error(message ? message : "apiConstant.API_FAILURE");
}

export const successToast = (msg) => {
  toast.success(msg ? msg : "apiConstant.API_SUCCESS");
}

export const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (emailPattern.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const favouriteTeamData = (item) => {
  let team_a = {}, team_b = {}, obj1 = {}, obj2 = {}

  obj1 = {
    name: item.favourite_team,
    percentage: item.fav_percentage,
    color: item.fav_percentage > 50 ? "#89C693" : "#FF7272",
  }
  obj2 = {
    name: item.favourite_team === item.localteam_short_name ? item.visitorteam_short_name : item.localteam_short_name,
    percentage: 100 - item.fav_percentage,
    color: (100 - item.fav_percentage) > 50 ? "#89C693" : "#FF7272",
  }
  if (item.fav_percentage > 50) {
    team_a = obj1;
    team_b = obj2;
  }
  else {
    team_b = obj1;
    team_a = obj2;
  }
  return {
    team_a,
    team_b
  }


}

export const getAnalysisTextAndColor = (data) => {
  let text = "";
  let color = "#000000"
  switch (data) {
    case "very_strong":
      text = "Very Strong";
      color = "#29B541"
      break;
    case "strong":
      text = "Strong";
      color = "#A5C236"
      break;
    case "Average":
      text = "Average";
      color = "#C57A36"
      break;
    case "weak":
      text = "Weak";
      color = "#FC2F2F"
      break;
    default:
      break;
  }
  return {
    text,
    color
  }
}
export const getPathParam = (param) => {
  const queryParameters = new URLSearchParams(window.location.search)
  return queryParameters.get(param)
  
}

export const addOrdinalSuffix = (num) => {
  if (num % 100 >= 11 && num % 100 <= 13) {
    return num + 'th';
  } else {
    switch (num % 10) {
      case 1:
        return num + 'st';
      case 2:
        return num + 'nd';
      case 3:
        return num + 'rd';
      default:
        return num + 'th';
    }
  }
}
export const openPlayStore = () => {
  const isAndroid = /Android/.test(navigator.userAgent);
  const packageName = 'com.app.sports.cricketlive';
  
  let playStoreUrl = `https://play.google.com/store/apps/details?id=${packageName}`;
  // if (isAndroid) {
  //     console.log("isAndroid", isAndroid);
  //     playStoreUrl = `market://details?id=${packageName}`;
  //     window.location.href = playStoreUrl;
  //     console.log("playStoreUrl", playStoreUrl);
  //     window.open(playStoreUrl, "_blank")
  // }
  // else
      window.open(playStoreUrl, "_blank")
}




export const longToShortDescription = (description , size = 200, limit = 180 , value = true ) => {
  if (value){

    const string = description.length > size ?  `${description.substring(0, limit)}...` : description;
    console.log("string")
    return string;

  }
  else{
    return description;
  }
 
}