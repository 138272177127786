import React from "react";
import JoinTheCommunityDialog from "./JoinTheCommunity";
import { AuthProvider } from './context';

const LoginAndSignupDialogs = () => {

    return (
        // <AuthProvider>
        <JoinTheCommunityDialog>
            </JoinTheCommunityDialog>
        // </AuthProvider>
    )
}
export default LoginAndSignupDialogs;