export const MATCH_TYPES = {
    "Not Started": "UPCOMING",
    "In Progress": "LIVE",
    "Finished": "FINISHED"
}
export const cookiesConstants = {
    IS_LOGGED_IN: "IS_LOGGED_IN",
    PROFILE_DETAILS: "PROFILE_DETAILS",
    SELECTED_MATCH: "SELECTED_MATCH",
    WALLET_INFO: "WALLET_INFO",
    CURRENT_SCORE: "CURRENT_SCORE",
    CURRENT_BLOG: "CURRENT_BLOG"
};
  
export const eventConstants = {
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_LOADER: "SHOW_LOADER",
    IS_LOGGED_IN: "LOGGEDIN",
    LOGIN_SUCCESS:"LOGIN_SUCCESS"
}
export const mainHeaderTabPanel = {
    LIVEBET: "ALL",
    UPCOMING: "UPCOMING",
    LIVE: "LIVE",
    FINISHED:"FINISHED"
}
export const mainHeaderTabHead = {
   
    UPCOMING: "Upcoming",
    LIVE: "Live",
    FINISHED:"Finished"
}