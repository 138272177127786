
import * as React from 'react';
import { Heading } from '../../LoginAndSIgnup/LoginAndSIgnUpStyle';
import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';
import { useState } from 'react';

export const MainDiv = styled.div`
background: #F1F3F5;
padding: 25px 15px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const AvatarDiv = styled.div`
padding: 10px;
margin: 10px;
background-color:#FFFFFF;
flex-wrap: wrap;
border-radius: 6px;
`
const Button = styled.button`
background: ${props => props.background || ""};
color: ${props => props.color || ""};
padding: 10px;
margin: ${props => props.margin || "0px"};
border-radius: 20px;
border:none;
width: 230px;
font-size: 15px;
`
const Image = styled.img`
max-width : 100%;
border-radius: 50%;
margin: ${props => props.margin || "0px"};
border: none;
&:focus:{
  outline:none;
  border:1px solid #0BA9DB;
};
`
const avatarImage = [
  {
    image: "../Assets/avatar1.svg",
    avatarName: "avatar1"
  },
  {
    image: "../Assets/avatar2.svg",
    avatarName: "avatar2"
  },
  {
    image: "../Assets/avatar3.svg",
    avatarName: "avatar3"
  },
  {
    image: "../Assets/avatar4.svg",
    avatarName: "avatar4"
  },
  {
    image: "../Assets/avatar5.svg",
    avatarName: "avatar5"
  },
  {
    image: "../Assets/avatar6.svg",
    avatarName: "avatar6"
  },
  {
    image: "../Assets/avatar7.svg",
    avatarName: "avatar7"
  },
  {
    image: "../Assets/avatar8.svg",
    avatarName: "avatar8"
  },
  {
    image: "../Assets/avatar9.svg",
    avatarName: "avatar9"
  },
  {
    image: "../Assets/avatar10.svg",
    avatarName: "avatar10"
  },
  {
    image: "../Assets/avatar11.svg",
    avatarName: "avatar11"
  },
  {
    image: "../Assets/avatar12.svg",
    avatarName: "avatar12"
  },
  {
    image: "../Assets/avatar13.svg",
    avatarName: "avatar13"
  },
  {
    image: "../Assets/avatar14.svg",
    avatarName: "avatar14"
  },
  {
    image: "../Assets/avatar15.svg",
    avatarName: "avatar15"
  }

] 

const UpdateImageDialog = () => {
  const [ userAvater ,setUserAvater] = useState(false);

  const handleAvater = () => {
    setUserAvater(true);

  }
    return (
        <>
        
        <Dialog open={true}   sx={{
            "& .MuiDialog-paper": {
              borderRadius: "25px",
                         margin: "20px 10px",
           
            },
          }}>
        <MainDiv >
            <AvatarDiv>
            {avatarImage.map((avatar) => (
                    <>
                  <Image width={"90px"} margin={"8px 8px"} src={avatar.image} border={userAvater} value={userAvater}  ></Image>
                    </>
                ))}
            </AvatarDiv>
            <Button background={"linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)"} color={"#FFFFFF"} margin={"10px 25px"}>Update Image</Button>
        </MainDiv>
        </Dialog>
        
        </>
    )
}

export default UpdateImageDialog