import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Row } from "simple-flexbox";
import styled from "styled-components";
import { redisApiBaseUrl } from "../../../config";
import { axiosRequest } from "../../../utility/axios";

const Heading = styled.div`
font-size:  ${props => props.fontSize || "18px"};
font-weight:600;
color: ${props => props.color || "#000000"};
padding-top:${props => props.paddingTop || "0px"};
padding-bottom:${props => props.paddingBottom || "0px"};
@media(max-width:480px){
    font-size:  ${props => props.smFontSize}
};
`
const MainContainer = styled.div`
background: #F1F3F4;
padding: 25px 35px;
min-width:500px;
@media(max-width:600px){
    min-width:400px;
};
@media(max-width:600px){
    min-width:400px;
};
@media(max-width:460px){
    min-width:300px;
};
`
const CustomTab = styled(Tab)`
color:${props => props.primary ? "#FFFFFF" : "#0BA9DB"};
background: ${props => props.primary ? "linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)" : "#FFFFFF"} ;
padding:5px 40px;
border:${props => props.primary ? "none" : "1px solid #0BA9DB"};
border-radius:30px;
&:focus : {
    border:none
}
font-size:18px;
cursor:pointer;
@media(max-width:600px){
    font-size:15px;
    padding:3px 30px;
};
@media(max-width:460px){
  font-size:12px;
  padding:3px 20px;
};
`;

const CustumMainTab = styled(Tab)`
color:${props => props.primary ? "#0BA9DB" : "#525B7C"}; 
font-size: 16px;
font-weight:600;
text-align:center;
border-bottom: ${props => props.primary ? "3px solid transparent" : "none"};
border-image: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
border-image-slice: 1;
width:100%;
cursor:pointer;
@media(max-width:500px){
    font-size: 13px;
};

`

const CustomMainTabList = styled(TabList)`
list-style-type:none;
display:flex;
justify-content:space-between;
width:100%;
background:#FFFFFF;
padding: 10px 30px 0px 30px;


`;
const CustomTabList = styled(TabList)`
list-style-type:none;
display:flex;
justify-content:space-between;
width:100%;
padding: 0px 0px;
`;
const Table = styled.table`
border-collapse: separate;
width:100%;
margin:0 auto;
border-spacing:0 15px;

` 
const TableHeadRow = styled.tr`
width:100%;
margin:0 auto;
text-align:center;


` 
const TableHeadColumn = styled.th`
margin:0 auto;
text-align:center;
font-size:16px;
font-weight:600;
&:nth-child(2) {
    padding-right: 150px;
  }
    @media(max-width:480px){
    &:nth-child(2) {
    padding-right: 100px;
  };
};
` 
const TableBodyRow = styled.tr`
width:100%;
margin:0 auto;
text-align:center;
background: #FFFFFF;
box-shadow: 1px 4px 11px 0px #00000005;
&:hover:{
    background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
};


` 
const TableBodyColumn = styled.td`
margin:0 auto;
text-align:center;
padding: 14px 0px;
&:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
&:nth-child(2) {
    padding-right: 150px;
  };
  @media(max-width:480px){
    &:nth-child(2) {
    padding-right: 100px;
  };
};


` 
export const Image = styled.img`
width:${props => props.width || "30px"};
margin:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
cursor:${props => props.cursor || "auto"};
border-radius:${props => props.borderRadius || "0px"};
  @media(max-width:480px){
  width:20px;
  };
`
export const HeaderDiv = styled.div`
margin-bottom:15px;
display:flex;
justify-content:space-between;
`
export const PrizeTab = styled.div`
background:#FFFFFF;
padding: 10px;
`
export const PrizeHeader = styled.div`
padding: 0px 5% 15px 5%;
display:flex;
justify-content:space-between;
`
export const PrizeDiv = styled.div`
background:#F8F8F8;
display:flex;
justify-content:space-between;
padding: 8px 15px;
border-radius:5px;
`

const LeaderboardDialog = (props) => {
    const [leaderBoardData, setLeaderBoardData] = useState( [
            {
                "_id": "64e8a486ba684d224942bb61",
                "user_id": "64e1a5a5c160a9145ea7ec2f",
                "point": 100,
                "match_id": 111,
                "sport": 3,
                "team_name": "testReal11",
                "avatar": "",
                "rank": 3
            },
            {
                "_id": "64e8a494ba684d224942bb62",
                "user_id": "64d47386e857f3ffbe798d3d",
                "point": 150,
                "match_id": 111,
                "sport": 3,
                "team_name": "KingKohli 👑",
                "avatar": "",
                "rank": 1
            },
            {
                "_id": "64e8a423ba684d224942bb5e",
                "user_id": "64e333948614135ca799912e",
                "point": 120,
                "match_id": 111,
                "sport": 3,
                "team_name": "RajTeam",
                "avatar": "avatar11",
                "rank": 2
            }
    ]
    )
    const getLeaderBoardDetails = async() => {
        const url = `${redisApiBaseUrl}/othergame-daily-leaderboard/60211`
        // const response = await axiosRequest("GET", url);
        // if (!response || !response.status)
        //     return
        let sortedData = leaderBoardData.sort((a, b) => { return a.rank - b.rank; })   
        setLeaderBoardData(sortedData)
    }

    useEffect(() => {
        getLeaderBoardDetails()
    },[props?.toggleDialog?.leaderboard ])
    return(
    <Dialog
            open={props?.toggleDialog?.leaderboard}
            sx={{
                "& .MuiDialog-paper": {
                    margin: "20px 10px",
                borderRadius:"5px"
                },

          
               
             
            }}
            >
            <MainContainer>
               <HeaderDiv>
            <Heading>
                 Leaderboard
                    </Heading>
                    <Image width={"20px"} cursor={"pointer"} src="../Assets/cross.svg" onClick={()=>{props.handleDialogToggle("leaderboard", false)}}></Image>
                    </HeaderDiv>
                {TabComponent(leaderBoardData)}
                </MainContainer>
        </Dialog>
    )

}
const TabComponent = (leaderBoardData) => {
    const [tabIndex, setTabIndex] = useState(0)
    return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <CustomMainTabList >
                <CustumMainTab primary={tabIndex === 0 ? true : false}><Heading paddingBottom={"10px"} smFontSize={"14px"} color={ tabIndex === 0 ? "#0BA9DB" : "#525B7C"} >Leaderboard</Heading></CustumMainTab>
                <CustumMainTab  primary={tabIndex === 1 ? true : false}><Heading paddingBottom={"10px"} smFontSize={"14px"}  color={ tabIndex === 1 ? "#0BA9DB" : "#525B7C"} >Prizes</Heading></CustumMainTab>
            </CustomMainTabList>
            <TabPanel>
            {FilterTabComponent(leaderBoardData)}

            </TabPanel>
            <TabPanel>
                <PrizeSection></PrizeSection>
            </TabPanel>
    </Tabs>
)
}
const FilterTabComponent = (leaderBoardData) => {
    const [tabIndex, setTabIndex] = useState(0)
    return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <CustomTabList >
            <CustomTab primary={tabIndex === 0 ? true : false}>Daily</CustomTab>

                <CustomTab primary={tabIndex === 1 ? true : false}>Weekly</CustomTab>
                <CustomTab  primary={tabIndex === 2 ? true : false}>Monthly</CustomTab>

            </CustomTabList>
            <TabPanel>
            {LeaderboardTable(leaderBoardData)}
            </TabPanel>
            <TabPanel>
            <LeaderboardTable></LeaderboardTable>
            </TabPanel>
            <TabPanel>
            <LeaderboardTable></LeaderboardTable>
            </TabPanel>
    </Tabs>
)
}
const LeaderboardTable = (leaderBoardData) => {
    return (
        <>
         <Table>
            <TableHeadRow>
                <TableHeadColumn>Rank</TableHeadColumn>
                <TableHeadColumn>Players</TableHeadColumn>
                <TableHeadColumn>Points</TableHeadColumn>
            </TableHeadRow>
       
            {leaderBoardData && leaderBoardData.length && leaderBoardData.map((data)=>(
            <TableBodyRow>
                    <TableBodyColumn ><Heading fontSize={"14px"} smFontSize={"12px"} color={"#22213A"}>#{data?.rank }</Heading></TableBodyColumn>
                    <TableBodyColumn>
                        <Row justifyContent="center">
                        <Image width={"40px"} borderRadius={"50%"} src={"../Assets/avatar.svg"} margin={"0px 5px 0px 0px"}></Image>
                            <Heading fontSize={"14px"} smFontSize={"12px"} color={"#22213A"} paddingTop={"5px"}>{data?.team_name}</Heading>
                            </Row>
                    </TableBodyColumn>
                            
                    <TableBodyColumn><Heading fontSize={"14px"} smFontSize={"12px"} color={"#22213A"}>{data?.point}</Heading></TableBodyColumn>
                </TableBodyRow>
                ))}
            
        </Table>
</>
    )
}
const PrizeSection = () => {
    return (
        <>
            <PrizeHeader >
                <Heading>Rank</Heading>
                <Heading>Prize</Heading>
        </PrizeHeader>
            <PrizeTab>
        <PrizeDiv>
                <Heading color={"#232539"} fontSize={"16px"}>#1</Heading>
                <Row>
                    <Heading color={"#232539"} fontSize={"16px"}>20</Heading>
                    <Image src="../Assets/coin.svg" width={"14px"} margin={"0px 0px 0px 5px"}></Image>
                    </Row>
            </PrizeDiv>
        </PrizeTab>
        </>

    )
}
export default LeaderboardDialog