import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/user'
import matchReducer from './reducers/match'


 const store = configureStore({
  reducer: {
     user: userReducer,
    match:matchReducer
   
  }
 })
export default store;