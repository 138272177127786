import styled from "styled-components";

export const MainContainer = styled.div`
   display : flex ; 
   flex-direction: row;
   padding:${props => props.padding || "0px"} ;
   border-radius: 10px;
  //  margin-left: 62px;
     @media  screen and (max-width: 500px) {
  
    flex-direction : column;
   }
   @media  screen and (max-width: 415px) {
    margin-left: 0px;

   }
`
export const StyledSpan = styled.span`
color: #0BA9DB;
font-weight: bold;
/* Add any other CSS styles you want */
`;
export const MessageContainer = styled.div`
  display : flex ; 
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
  padding: ${props => props.padding || "0px 20px"};
  // max-width:700px;
  min-width: 100%;
  border-radius: 10px;
 
    @media(max-width:500px){
    padding:${props => props.smPadding};

};
`;

export const HeaderContainer = styled.div`
  font-family: Outfit;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 1.51%, #E6E6E6 48.07%, rgba(245, 245, 245, 0) 98.33%);
  padding: 12px 0px;
  margin : ${props => props.margin || "5px 5px"};
  @media(max-width:1080px){
  font-size:22px;

};
@media(max-width:600px){
  font-size:20px;
};

@media(max-width:450px){
  font-size: 14px;
};
`
export const BodyContainer = styled.div`
  display : flex ; 
  flex-direction: column;
  margin-top: 10px ;
  padding: ${props => props.padding || "0px 15px"};
  margin-top: ${props => props.topMargin || "10px"};
  padding-bottom: 20px;
`;
export const SubHeader = styled.li`
  font-family: Outfit;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  padding-top : ${props => props.topSpace || "10px"};

  @media(max-width:450px){
  font-size: 16px;
};
`
export const TextHeader = styled.li`
  font-family: Outfit;
  font-size: ${props => props.fontSize || "16px"};
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-top : ${props => props.topSpace || "10px"};
  margin : ${props => props.margin || ""};
  @media(max-width:450px){
  font-size: 14px;
};
`

export const OrderList = styled.ol`
list-style-type : ${props => props.type || "auto"};
`
export const UnOrderList = styled.ul`
list-style-type : ${props => props.type || "disc"};
padding: ${props => props.padding || "10px 10px"};
`

export const AccordianBanner = styled.div`
border-radius: 5px;
background: rgba(253, 253, 253, 1);
padding: 12px 15px 12px 10px;
font-family: Outfit;
font-size: 22px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0em;
text-align: left;
margin : ${props => props.margin || "5px 5px"};
display : flex ;
justify-content:space-between;
// padding-right: 15px;
// padding-left: 10px;
@media(max-width:1080px){
  font-size:18px
};
@media(max-width:600px){
  font-size:16px
};

@media(max-width:600px){
padding: 8px;
};

`
export const AccordianInnerDiv = styled.div`
font-family: Outfit;
font-size: 18px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
background: rgba(72, 73, 80, 1);
margin-top:-10px;
background: rgba(253, 253, 253, 1);
padding-right: 15px;
padding-left: 10px;
margin-left: 5px;
margin-right: 5px;
padding-bottom: 25px;
padding-top: 10px;
@media(max-width:1080px){
  font-size:16px
};
@media(max-width:600px){
  font-size:14px
};
`

export const ImageDiv = styled.img`
width: ${props => props.width};
@media(max-width:500px){
  width: ${(props) => props.mdWidth};
};
@media(max-width:415px){
  width: ${(props) => props.smWidth};
};
`
export const AccordianMainDiv = styled.div`
display:flex;
flex-direction: column;
border: 0.5px solid rgba(226, 226, 226, 1);
margin-top: 20px;
margin-bottom: 0px;
border-radius: 5px;
`
export const MessageLine = styled.div`
font-family: Outfit;
font-size: 20px;
font-weight: 600;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
margin : ${props => props.margin || "5px 5px"};
 @media(max-width: 500px){
 font-size: 16px;

  };
`
export const FormInput = styled.input`
margin : ${props => props.margin || "30px 5px 0px 0px"};
border-radius: 6px;

font-size: 16px;
font-weight: 300;
line-height: 20px;
letter-spacing: 0em;
height: 60px;
text-align: left;
background: rgba(245, 245, 245, 1);
padding: 15px;
border: unset;
 @media(max-width:500px){
 height: 48px;

  };
`

export const TextArea = styled.textarea`
margin : ${props => props.margin || "30px 5px 0px 0px"};
width: 100% ;
height: 200px ;
top: 722px ;
left: 339px ;
border-radius: 6px;
background: rgba(245, 245, 245, 1);
border : none;
    font-size: 16px;
    font-weight: 300;
    box-shadow :none !important;
padding: 15px;
 @media(max-width:500px){
 height: 117px;

  };

`
export const Submit = styled.button`
width: 250px;
height: 60px ;
top: 991px ;
left: 339px ;
border-radius: 66px;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
margin : ${props => props.margin || "25px 0px 0px 0px"};
font-family: Outfit;
font-size: 22px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
border:none;
outline:none;

 @media(max-width:500px){
height: 52px;
width : 313px;
margin: 25px auto 0px;
font-size: 18px;

  };
`

export const CustmorCareContainer = styled.div`
  display : flex ; 
  flex-direction: row;
  background: rgba(255, 255, 255, 1);
  padding: ${props => props.padding || "0px 20px"};
  max-width:700px;
  min-width: 75%;
  align-items: center;
  border-radius: 10px;
  column-gap :40px
  
  @media(max-width:600px){
    flex-direction: column;
  };
  @media(max-width:415px){
    flex-direction: column;
  };
`;

export const Text = styled.div`
  font-family: Outfit;
  font-size: ${props => props.fontSize || "16px"};
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  padding-top : ${props => props.topSpace || "10px"};
  margin-bottom: 10px;

  @media(max-width:450px){
  font-size: 14px;
};
`