import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Image, MainContainer, BlogMainContainer, BlogBody, RelatedBlog, AdsDiv } from "../MatchInfo/MatchInfoStyle";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";
import { BlogDetailsDiv, BlogImage, ColumnDiv, Heading, HorizontalLine, PaginationContainer, TopBlog, TopBlogImage } from "../pagesStyle";
import { redisApiBaseUrl } from "../../../config";
import { axiosRequest } from "../../../utility/axios";
import { useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { setLoader } from "../../../reducers/user";

import Pagination from "@mui/material/Pagination";
import { sessionManager } from "../../../managers/sessionManager";
import { cookiesConstants } from "../../../constants";
import { currentBlog } from "../../../reducers/match";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { longToShortDescription } from "../../../utility";


export const VerticalLine = styled.div`
border: 0.75px solid #F1F3F5;
`

const ParticularBlogsComponent = () => {
    const [blog, setBlog] = useState([])
    const [relatedBlog, setRelatedBlog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const location = useLocation();
    const dispatchMatch = useDispatch()
    const blogData = useSelector(state => state.match.selectBlog);
    let navigate = useNavigate();

    const getBlog = async (id) => {

        dispatchMatch(setLoader({ data: true }))
        window.scrollTo(0, 0)
        let url = `${redisApiBaseUrl}/rssFeedDetails?id=${id}`;
        const BlogDetails = await axiosRequest("GET", url);

        if (BlogDetails && BlogDetails.data) {
            setBlog(BlogDetails.data);
            let relUrl = `${redisApiBaseUrl}/rssFeedList?types=AllNews`;
            const RelatedBlogs = await axiosRequest("GET", relUrl);

            if (RelatedBlogs && RelatedBlogs.data) {
                setRelatedBlog(RelatedBlogs.data)
            }
            dispatchMatch(setLoader({ data: false }))
        }
    }
    const getRelatedBlog = async (title, id) => {
        const cleanedStr = title.replace(/[^\w\s]/gi, '');
        title = cleanedStr.replace(/\s+/g, '-');
        // console.log("titile", title);
        dispatchMatch(currentBlog({ id: id, title: title }));
        navigate(`/blog-info/${title}/${id}`);
        dispatchMatch(setLoader({ data: true }))
        window.scrollTo(0, 0)
        let url = `${redisApiBaseUrl}/rssFeedDetails?id=${id}`;
        const BlogDetails = await axiosRequest("GET", url);

        if (BlogDetails && BlogDetails.data) {
            setBlog(BlogDetails.data);
            dispatchMatch(setLoader({ data: false }))
        }
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentBlogs = relatedBlog.slice(indexOfFirstItem, indexOfLastItem);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {

        const id = location.pathname.split("/")[3];
        getBlog(id);

        console.log("test->");
    }, [])
    return (
        <BlogMainContainer>

            <Row justifyContent="space-between">
                <Column>
                    <BlogBody >
                        <div className="content" dangerouslySetInnerHTML={{ __html: blog.contentData }}></div>
                    </BlogBody>
                    <Heading margin={"10px 0px"} color={"#22213A"} marginTop={"10px"} fontSize={"20px"} fontWeight={"500"} width={"90%"} >{blog.contentData ? "Related Articles" : ""}</Heading>
                    <RelatedBlog padding={"30px 20px"}>

                        <Grid container style={{ justifyContent: "center" }} >
                            {currentBlogs && currentBlogs.length && currentBlogs.map((blog, index) => (
                                <>
                                    <Grid item md={5.9}  >
                                        <ColumnDiv marginTop={"0px"} padding={"0px 10px"} style={{ cursor: "pointer" }}>
                                            <BlogDetailsDiv onClick={() => getRelatedBlog(blog.title, blog.id)} padding={"10px"} >
                                                <BlogImage src={blog.imageContent} width={"200px"} mdWidth={"210px"} margin={"25px"} height={"153px"}></BlogImage>
                                                <Column style={{ width: "50%" }}>
                                                    <Heading color={"#22213A"} fontSize={"17px"} fontWeight={"500"} mdFont={"14px"} padding={"10px"} margin={"0px 0px 8px"}>{longToShortDescription(blog.title, 100, 80)}</Heading>
                                                </Column>

                                            </BlogDetailsDiv>
                                            {/* {console.log(index == currentBlogs.length - 1 || index == currentBlogs.length - 2)} */}
                                            {index == currentBlogs.length - 1 || index == currentBlogs.length - 2 ? "" : <HorizontalLine width={"96%"} margin={"20px 0px 20px 10px"} mdMargin={"5px 0px 5px 10px"} />}

                                        </ColumnDiv>

                                    </Grid>
                                    {
                                        index % 2 !== 0 ? "" : <VerticalLine></VerticalLine>
                                    }


                                </>
                            ))}
                        </Grid>
                    </RelatedBlog>
                    <PaginationContainer>
                        <Pagination
                            count={Math.ceil(relatedBlog.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handleChange}
                            color="primary"
                        />
                    </PaginationContainer>
                </Column>
                <Column>
                    <AdsDiv>
                        {/* <Image width={"100%"} marginTop={"4px"} src="../Assets/dummyad.svg"></Image> */}
                    </AdsDiv>
                </Column>
            </Row>
        </BlogMainContainer>
    )
}

export default ParticularBlogsComponent;
