import React, { useState } from "react";

import { MainContainer, MessageContainer, HeaderContainer, BodyContainer, Text, OrderList, UnOrderList } from './PrivacyPolicyStyle'
import { Grid } from "@mui/material";
import { ColumnDiv, ShareImage } from "../pagesStyle";
import CricFunInstallSpinDialog from "../Games/CricFunInstallSpinDialog";
import { Column } from "simple-flexbox";
import SubHeader from "../../components/Header/SubHeader";

const AboutUs = () => {
  return (


    <>
      <SubHeader />
      <MainContainer padding={"1.5% 2%"} >
        <Grid container columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
          <Grid item sm={12} md={12} lg={9}  >
            <ColumnDiv padding={"0px"} marginTop={"0px"} >

              <MainContainer>
                <MessageContainer>
                  <HeaderContainer margin="15px 5px">About Us</HeaderContainer>
                  <BodyContainer>
                    <Text>
                      Cricfun is a one-stop platform for all cricket enthusiasts around the world. Our mission is to provide cricket lovers with the most up-to-date and accurate information on cricket matches happening around the globe. We offer instant score updates that are even faster than TV, so you never miss a single moment of the action.</Text>
                    <Text>
                      At Cricfun, we understand that cricket is not just a sport, but a passion that brings people together. We are dedicated to creating a community of cricket fans who can share their love for the game with each other. Our platform is designed to cater to all cricket lovers, whether you are a casual fan or a die-hard enthusiast.  </Text>
                    <Text>
                      Apart from our extensive coverage of cricket matches, we also offer a variety of fun games such as Ludo, Snakes and Ladders, Inplay and Oddsplay. You can play these games of your choice and stand a chance to win big daily. Our games are designed to provide an immersive and entertaining experience to our users.
                    </Text>
                    <Text>
                      At Cricfun, we understand that our users love to earn rewards and prizes for their engagement on our platform. That's why we have developed a unique online credit system, where users can earn coins by playing games and completing daily tasks. These coins can be redeemed for cash prizes, which makes our platform even more exciting for our users. </Text>
                    <Text>
                      The more users play games on Cricfun, the more coins they earn, which can be utilised to play further games on the platform. This creates a cycle of engagement where users are incentivised to keep playing more games and earning more coins.</Text>
                    <Text>
                      To further enhance the user experience, we have introduced daily tasks that users can complete to earn additional coins. These tasks are designed to be simple and easy to complete, and provide users with an additional source of coins.
                    </Text>
                    <Text>
                      We also have a spin-the-wheel feature, where users can spin the wheel of coins every day to earn additional coins. This adds an element of surprise and excitement to the platform, and provides users with a chance to earn more coins without having to play games.
                    </Text>
                    <Text>
                      In addition to these features, we also have a refer-and-earn program, where users can invite their friends to join Cricfun. When their friends sign up using their referral code, both the user and their friend earn additional coins.
                    </Text>
                    <Text>
                      At Cricfun, we are committed to providing our users with the best possible experience. We understand the importance of engagement and rewards in keeping our users coming back to our platform. Our online credit system, daily tasks, spin-the-wheel, and refer-and-earn programs are just some of the ways we strive to enhance the user experience on our platform.
                    </Text>
                    <Text>
                      At Cricfun, we take pride in providing our users with a seamless and user-friendly experience. Our platform is designed to be easy to navigate, and our content is curated to provide the most relevant and engaging information to our users. We also offer personalised recommendations based on your preferences, so you can stay up-to-date with the latest news and scores from your favourite teams and players.
                    </Text>
                    <Text>
                      With our instant score updates, extensive coverage of cricket matches, fun games and online credits we aim to create a community of gaming enthusiasts who can share their passion for the game with each other. Join us today and experience the thrill of gaming like never before.
                    </Text>
                  </BodyContainer>
                </MessageContainer>
              </MainContainer>
            </ColumnDiv>

          </Grid>
          <Grid item sm={12} md={12} lg={3}  >
            {/* <ShareImage src="../Assets/spinwheel.svg" onClick={() => { handleDialogToggle("spin", true) }}></ShareImage> */}
            <ThirdColumn />
          </Grid>
        </Grid>
      </MainContainer>


    </>
  );
};

const ThirdColumn = () => {

  const [toggleDialog, setToggleDialog] = useState({
    spin: false
  })
  const handleDialogToggle = (name, value) => {
    setToggleDialog({
      ...toggleDialog,
      [name]: value
    })
  }
  return (

    <>

      <CricFunInstallSpinDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></CricFunInstallSpinDialog>
      <Column style={{ width: "100%" }}>

        <ShareImage src="../Assets/spinwheel.svg" marginTop={"20px"} mdMargin={"10px 0px 10px 8px"} onClick={() => { handleDialogToggle("spin", true) }}></ShareImage>

      </Column>
    </>

  )
}




export default AboutUs