
import React, {  useState } from 'react';
import { Heading } from '../../LoginAndSIgnup/LoginAndSIgnUpStyle';
import styled from 'styled-components';
import { Column, Row } from "simple-flexbox";
import Dialog from '@mui/material/Dialog';
import { axiosRequest } from '../../../../utility/axios';
import { failureToast, successToast } from '../../../../utility';
import { redisApiBaseUrl } from '../../../../config';

export const MainDiv = styled.div`
background: #FFFFFF;
padding: 10px 17px;
border-radius:30px ;
`
const Input = styled.input`
font-size:14px;
border: none;
border-radius:6px;
background-color:#F5F5F5;
padding: 10px 100px 10px 10px;
margin: 10px;
`
const Button = styled.button`
background: ${props => props.background || ""};
color: ${props => props.color || ""};
width: 125px;
margin: ${props => props.margin || "0px"};
border-radius: 20px;
border:${props => props.border || "none"};
font-size: 15px;
padding: 3px;
`
const Image = styled.img`
max-width : 100%;
cursor:${props => props.cursor || "auto"};

`
const SetPasswordDialog = (props) => {
 const [password , setPassword]=useState({
    old_password:"" ,
    new_password:"",
    confirm_password:"" 
 });
 const handleChange = (event) => {
    setPassword({
        ...password,
        [event.target.name]: event.target.value
    });
    
};
    
const onSave = async()=>{

    if(password.new_password == password.confirm_password){
       const user_profile = props.userProfile;
      
        const input ={
            user_id: user_profile.user_id, 
            old_password: password.old_password ,
            new_password:password.new_password 
            }
            
        const url = `${redisApiBaseUrl}/changePwdRedis`;
    
        const response = await axiosRequest("POST", url, input);
        if (!response || response.errors || !response.status) {
          failureToast(response?.message || "Unable to update");
          return;
        }

        successToast(response.message);
        
    }
    else{
        failureToast("Password and Confirm Password is not Same");   
    }
   
}
 
    return (
        <>

            <Dialog open={props.toggle}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "10px",
                                   margin: "20px 10px"
      
                    },
                }}
            >
                <MainDiv >

                    <Row justifyContent={"space-between"}>
                        <Heading fontWight={"600"} fontSize={"22px"} marginLeft={"15px"} fontFamily={"Outfit"}> Set Password</Heading>
                        <Image width={"15px"} marginRight={"10px"} cursor={"pointer"} marginTop={"10px"} marginLeft={"25px"} src="../Assets/cross.svg"  onClick={props.handleChangePassword}></Image>
                    </Row>
                    <Row>
                        <Input placeholder='Old Password' name='old_password' onChange={(event)=>handleChange(event)}></Input>
                    </Row>
                    <Row>
                        <Input placeholder='New Password' name='new_password' onChange={(event)=>handleChange(event)}></Input>
                    </Row>
                    <Row>
                        <Input placeholder='Confirm Password' name='confirm_password' onChange={(event)=>handleChange(event)}></Input>
                    </Row>


                    <Row justifyContent={"space-between"}>

                        <Button  background={"white"}color={"#0BA9DB"} margin={"10px 5px"} border={"1px solid #0BA9DB"} onClick={props.handleChangePassword}>Skip</Button>
                        <Button background={"linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)"} color={"#FFFFFF"} margin={"10px 5px"} onClick={onSave }>Save</Button>
                    </Row>

                </MainDiv>
            </Dialog>

        </>
    )
}

export default SetPasswordDialog