import React, { useContext, useEffect, useState } from "react";
import { AccordianBanner, AccordianOnBanch, ColumnDiv, CustomTab, CustomTabList, FavTeamBlock, Heading, Image, LastMatchLoseBlock, LastMatchWinBlock, LineContainer, PlayerDetailDiv, Section, SquadTab, VerticalLine } from "./MatchInfoStyle";
import { Row } from "simple-flexbox";
import { TabPanel, Tabs } from "react-tabs";
import { redisApiBaseUrl } from "../../../config";
import { axiosRequest } from "../../../utility/axios";
import { failureToast, longToShortDescription } from "../../../utility";
import MatchInfoContext from "./matchInfoContext";
import { isEmpty } from "lodash";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../reducers/user";



const Squad = (props) => {

    const [squad, setSquad] = useState([])
    const { matchInfo } = useContext(MatchInfoContext);
    const [tabIndex, setTabIndex] = useState(0);
    const dispatch = useDispatch();
    const getSquadInfo = async (series_id, match_id) => {
        const url = `${redisApiBaseUrl}/matchSquad?series_id=${series_id}&match_id=${match_id}`
        dispatch(setLoader({ data: true }))
        const response = await axiosRequest("GET", url);
        if (!response || !response.status) {
            dispatch(setLoader({ data: false }))
            return;
        }
        // failureToast(response?.message)

        response.data.team1.reverse()
        response.data.team2.reverse()
        setSquad(response.data)
        dispatch(setLoader({ data: false }))
    }
    const team1 = squad.team1;
    let onBanch = 1
    if (team1) {
        const checkOnBanchPlayers = team1.filter((item) => item.is_playing > 0 || item.probable > 0);
        if (isEmpty(checkOnBanchPlayers))
            onBanch = 0;
    }
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const series_id = queryParameters.get("series")
        // queryParameters.get("series")
        const match_id = queryParameters.get("match")
        // queryParameters.get("match")

        getSquadInfo(series_id, match_id)
    }, [])
    return (
        <div style={{ width: "100%", maxWidth: "700px", margin: "0 auto" }}>



            {props?.matchData?.max_rate && props?.matchData?.min_rate ?
                <LineContainer padding={"10px"} background={"#FFFFFF"} >

                    <Row justifyContent="space-between">
                        <Row>
                            <FavTeamBlock margin={"10px 5px 10px 10px"}>{String(props?.matchData?.fav_team) ? String(props?.matchData?.fav_team).toUpperCase() : ""}</FavTeamBlock>

                        </Row>


                        <Row>
                            <VerticalLine height={"inherit"} marginRight={"20px"} />
                            <LastMatchWinBlock margin={"5px 5px 5px 10px"} >{String(props?.matchData?.min_rate)?.split(".")[1] || "00"}</LastMatchWinBlock>
                            <LastMatchLoseBlock margin={"5px 5px 5px 10px"}>{String(props?.matchData?.max_rate)?.split(".")[1] || "00"}</LastMatchLoseBlock>
                        </Row> </Row>
                </LineContainer>
                : ""}

            <ColumnDiv mdMaxWidth={"700px"} marginRight={"0px"} padding={"12px"} >
                <Row alignItems="center">
                    <img src="../Assets/noUpdate.svg" width={"45px"}></img>
                    <Heading fontSize={"17px"} smFont={"14px"} fontWeight={"500"} marginLeft={"15px"} >{matchInfo?.toss}</Heading>
                </Row>
            </ColumnDiv>



            {
                squad?.team2_short_name ?
                    <ColumnDiv>
                        <BasicTabs squad={squad} tabIndex={tabIndex} setTabIndex={setTabIndex} isFull={props.isFull}></BasicTabs>     </ColumnDiv>
                    : ""
            }


            {
                onBanch && squad?.team2_short_name ?
                    <ColumnDiv mdMaxWidth={"700px"} smPadding={"10px 20px"}
                    >
                        <Accordian squad={squad} tabIndex={tabIndex} setTabIndex={setTabIndex} isFull={props.isFull}></Accordian>
                    </ColumnDiv>
                    : ""
            }

        </div >

    )
}

const BasicTabs = (props) => {
    const { squad, type, tabIndex, setTabIndex, isFull } = props;
    // const [tabIndex, setTabIndex] = useState(0);
    let squadHeading = "Squad";
    let checkPlayers

    if (squad.team1) {
        checkPlayers = squad.team1.find((player) => player.probable == 1);
        if (checkPlayers)
            squadHeading = "Probable 11"

    }
    if (squad.team1) {
        checkPlayers = squad.team1.find((player) => player.is_playing == 1);
        if (checkPlayers)
            squadHeading = "Playing 11"
    }



    if (type === "bench")
        squadHeading = ""
    return (
        <>
            {
                squad?.team2_short_name ? <>
                    <Heading paddingBottom={"10px"} smFont={"16px"}  >{squadHeading}</Heading>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>


                        <CustomTabList >
                            <SquadTab margin={"0px 10px 0px 0px"} primary={tabIndex === 0 ? true : false}>{squad?.team1_short_name}
                            </SquadTab>
                            <SquadTab primary={tabIndex === 1 ? true : false}>{squad?.team2_short_name}</SquadTab>
                        </CustomTabList>
                        <TabPanel>
                            {PlayerDetail(squad.team1, type, squadHeading, isFull)}
                        </TabPanel>
                        <TabPanel>
                            {PlayerDetail(squad.team2, type, squadHeading, isFull)}
                        </TabPanel>
                    </Tabs> </>
                    : ""

            }
        </>
    );
}

const PlayerDetail = (team, playersType, squadHeading, isFull) => {

    let isPlayingFlag = 1;
    let isProbable = 1;
    let finalPlayers = 0;
    let probablePlayers = 0;
    if (team)
        finalPlayers = team.find((player) => player.is_playing == 1);
    if (team) {
        probablePlayers = team.find((player) => player.probable == 1);
        if (probablePlayers) {
            probablePlayers = 1
        }
    }

    if (playersType && playersType === "bench") {
        isPlayingFlag = 0;
        probablePlayers = 0;
    }
    if (squadHeading === "Squad") {
        isPlayingFlag = 0;
        probablePlayers = 0;
    }

    const getImageType = (type) => {
        let image = "../Assets/dummy1.png"
        switch (type) {
            case "Bowler":
                image = "../Assets/bowler.svg"
                break;
            case "Allrounder":
                image = "../Assets/allrounder.svg"
                break;
            case "Batsman":
                image = "../Assets/bluebat.svg"
                break;
            case "Wicketkeeper":
                image = "../Assets/wicket.svg"
                break;
            default:
                break;
        }
        return image;
    }
    return (
        <>
            {finalPlayers ? <>
                {team && team.length && team.map((item) => (
                    <>
                        {item.is_playing === isPlayingFlag ?
                            <PlayerDetailDiv smPadding={"10px 10px"}>
                                <Row style={{ alignItems: "center" }}>
                                    <Image src={item?.player_image || "../Assets/avatar.svg"} margin={"4px"} height={"45px"} width={"45px"} smWidth={"35px"} smHeight={"35px"} borderRadius={"50%"} marginRight={"5px"}
                                        onError={(e) => {
                                            if (e.target.src !== "../Assets/avatar.svg") { e.target.onerror = null; e.target.src = "../Assets/avatar.svg"; }
                                        }}
                                    ></Image>
                                    <Heading fontSize={"17px"} xsFont={"14px"} textAlign={"center"} >{longToShortDescription(item?.player_name, 15, 15, isFull)}</Heading>
                                </Row>
                                <Image src={getImageType(item?.player_role)} width={"43px"}></Image>
                            </PlayerDetailDiv>
                            :
                            <> </>
                        }
                    </>
                ))}
            </> :
                <>
                    {probablePlayers ? <>
                        {team && team.length && team.map((item) => (
                            <>
                                {item.probable === isProbable ?
                                    <PlayerDetailDiv smPadding={"10px 10px"}>
                                        <Row style={{ alignItems: "center" }}>
                                            <Image src={item?.player_image || "../Assets/avatar.svg"} margin={"8px"} height={"45px"} width={"45px"} smWidth={"35px"} smHeight={"35px"} borderRadius={"50%"} marginRight={"5px"}
                                                onError={(e) => {
                                                    if (e.target.src !== "../Assets/avatar.svg") { e.target.onerror = null; e.target.src = "../Assets/avatar.svg"; }
                                                }}
                                            ></Image>
                                            <Heading fontSize={"17px"} xsFont={"14px"} textAlign={"center"}>{longToShortDescription(item?.player_name, 15, 15, isFull)}</Heading>
                                        </Row>
                                        <Image src={getImageType(item?.player_role)} width={"43px"}></Image>
                                    </PlayerDetailDiv>
                                    :
                                    <>
                                    </>
                                }
                            </>
                        ))}
                    </> : <>
                        {team && team.length && team.map((item) => (
                            <>
                                {item.probable === isPlayingFlag && item.probable === probablePlayers ?
                                    <PlayerDetailDiv smPadding={"10px 10px"}>
                                        <Row style={{ alignItems: "center" }}>
                                            <Image src={item?.player_image || "../Assets/avatar.svg"} margin={"8px"} height={"45px"} width={"45px"} smWidth={"35px"} smHeight={"35px"} borderRadius={"50%"} marginRight={"5px"}
                                                onError={(e) => {
                                                    if (e.target.src !== "../Assets/avatar.svg") { e.target.onerror = null; e.target.src = "../Assets/avatar.svg"; }
                                                }}
                                            ></Image>
                                            <Heading fontSize={"17px"} xsFont={"14px"} textAlign={"center"} >{longToShortDescription(item?.player_name, 15, 15, isFull)}</Heading>
                                        </Row>
                                        <Image src={getImageType(item?.player_role)} width={"43px"}></Image>
                                    </PlayerDetailDiv>
                                    :
                                    <>

                                    </>
                                }
                            </>
                        ))}
                    </>}

                </>
            }

        </>
    )
}



const Accordian = (props) => {
    const { squad, tabIndex, setTabIndex, isFull } = props;
    const [toggle, setToggle] = useState(false);
    return (
        <>
            <AccordianOnBanch >
                <Row justifyContent="space-between" alignItems={"center"} onClick={() => setToggle(!toggle)}>
                    <Row justifyContent="space-between" >
                        <Heading fontSize={"20px"} fontWeight={"500"} marginRight={"8px"} textAlign={"center"} smFont={"18px"}>On Bench</Heading>
                    </Row>
                    <Image src={!toggle ? "../Assets/down.png" : "../Assets/up.png"} borderRadius={" 50%"} width={"35px"} padding={"10px"} background={"#F5F5F5"} cursor={"pointer"}></Image>
                </Row>
                {toggle ?
                    <Section>
                        <BasicTabs squad={squad} type="bench" tabIndex={tabIndex} setTabIndex={setTabIndex} isFull={isFull}></BasicTabs>
                    </Section>
                    : ""}
            </AccordianOnBanch>

        </>
    )
}

export default Squad;