import React, { createContext, useEffect, useState } from 'react';
import { axiosRequest } from '../../../utility/axios';
import { redisApiBaseUrl } from '../../../config';

const MatchInfoContext = createContext();

export const MatchInfoProvider = ({ children }) => {
  const [matchInfo, setMatchInfo] = useState("");

  const getMatchInfo = async () => {
    const queryParameters = new URLSearchParams(window.location.search)
    // const series_id = "127854"
    const series_id =  queryParameters.get("series")
    // const match_id = "66418"
    const match_id = queryParameters.get("match")
      const url = `${redisApiBaseUrl}/matchInfo?series_id=${series_id}&match_id=${match_id}`
      const matchInfo = await axiosRequest("GET", url);
      setMatchInfo(matchInfo.data)
  }
  useEffect(() => {
    getMatchInfo()
  },[])

  return (
    <MatchInfoContext.Provider value={{getMatchInfo,matchInfo}}>
      {children}
    </MatchInfoContext.Provider>
  );
};

export default MatchInfoContext;
