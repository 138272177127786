import React, { useEffect, useState } from 'react';
import { Heading, Image } from '../../LoginAndSIgnup/LoginAndSIgnUpStyle';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import Dialog from '@mui/material/Dialog';
import { logout } from '../../../../reducers/user';
import { useDispatch } from 'react-redux';
import { failureToast, successToast } from "../../../../utility";
import VerifyYourEmailDialog, { ImageDiv } from './VerifyYourEmail';
import { apiBaseUrl, redisApiBaseUrl } from "../../../../config";
import { axiosRequest } from "../../../../utility/axios";
import SetPasswordDialog from './UserSetPassword';


export const ProfileHeaderDiv = styled.div`
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
padding-left: 10px;
padding-right: 30px;
padding-top: 15px;
padding-bottom: 15px;
// min-width: 370px;
border-radius: 5px;
`
export const SubDiv = styled.div`
padding-top: 5px;
padding-left: 10px;
padding-right: 10px;
background-color: #F1F3F4;
padding-bottom: 10px;

`
export const InviteButton = styled.button`
color: #000000;
background-color: #FCFCFC;
padding: 10px 32px;
border: 1px solid #D2D1D1;
border-radius: 5px;
font-size: 10px;
font-weight: 600;
`
export const HorizontalLine = styled.hr`
border:1px solid #E4E4E4;
width:100%;
margin-left: 0px;
margin-bottom: 10px;
margin-top: 0px;
`
export const ProfileTabDiv = styled.div`
 background: linear-gradient(179.87deg, #FFFFFF 0.11%, rgba(255, 255, 255, 0.78) 109.04%, rgba(255, 255, 255, 0) 136.7%);
padding: 10px 0px;


`
export const ChangePasswordButton = styled.button`
color: #0BA9DB;
padding: 8px 25px;
border: 1px solid #0BA9DB;
border-radius: 20px;
font-size: 14px;
font-weight: 500;
background: inherit;
@media(max-width:450px){
   
    padding: ${props => props.smPadding};
};

`
export const MainDiv = styled.div`
padding: 12px 50px 30px 50px;
max-width: 470px;
background-color: #F1F3F4;
@media(max-width:600px){
    // font-size:${props => props.smLFont}
};
@media(max-width:530px){
   
    padding: ${props => props.smPadding};
};
@media(max-width:400px){
    // font-size:${props => props.xsFont}
};

`
export const ProfileBodyDiv = styled.div`
background-color: #FFFFFF;
padding-left: 10px;
padding-right: 30px;
padding-top: 15px;
margin-top: 10px;
border-radius: 5px;
position: relative;
`
const Input = styled.input`
font-size:14px;
border: none;
width:100%;
border-radius:6px;
height: 40px;
padding: 0px 20px;
margin: 10px 10px;
outline : ${props => props.outline || "inherit"};
color :${props => props.color || "inherit"};
background-color:#F5F5F5;
// ::placeholder {
//     color: deepPink;
//   }


@media(max-width:450px){
   
font-size:${props => props.smFontsize};
};
`
const Label = styled.label`
font-size:14px;
color:${props => props.color || "#5F5F5F"};
padding-left:${props => props.paddingLeft || "0px"};
font-size: ${props => props.fontSize || ""};
padding-right: ${props => props.PaddingRight || "0px"};
padding: 0px 5px;
margin-left: ${props => props.marginLeft || "10px"};
cursor : ${props => props.cursor || "auto"};
`
const Button = styled.button`
background: ${props => props.background || ""};
color: ${props => props.color || ""};
padding: 10px;
margin: ${props => props.margin || "0px"};
border-radius: 20px;
border:none;
width: 90%;
border :${props => props.border || "none"};
font-size: 15px;

@media(max-width:450px){
   
    margin: ${props => props.smMargin};
};

`


const UserUpdateProfileDialog = (props) => {
    const [userProfile, setUserProfile] = useState(props.profileDetails);
    const [email, setEmail] = useState(userProfile.email);
    const handleEmail= (updateEmail)=>{
       setEmail(updateEmail); 
    }

    const handleClose = () => {
        props.handleDialogToggle("userUpdateProfile",false);
       
      } 

   
    const userLogout = () => {
        props.handleDialogToggle("logout", true);
    }

    useEffect(() => {

    }, [props?.toggleDialog?.userUpdateProfile])
   

    const updateProfile =async()=>{
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            failureToast("Invalid Email Format!!");
        }
        else{
            const input ={user_id:userProfile.user_id,email:email, full_name:userProfile.full_name ,team_name:userProfile.team_name ,gender:userProfile.gender}
            const url = `${redisApiBaseUrl}/userRedisUpdateProfile`;
    
            const response = await axiosRequest("POST", url, input);
            if (!response || response.errors || !response.status) {
              failureToast(response?.message || "Unable to update");
              return;
            }
            successToast(response.message);
            props.handleDialogToggle("userUpdateProfile",false);
           

        }
       
    };
    return (
        <>
            <Dialog open={props?.toggleDialog?.userUpdateProfile} sx={{
                "& .MuiDialog-paper": {
                    margin: "20px 10px",
                borderRadius:"5px"
                },

          
               
             
            }}>
                <ProfileTab userProfile={userProfile}></ProfileTab>
                <MainDiv smPadding={"12px 10px 30px 10px"}> 
                    <ProfileHeader userProfile={userProfile}></ProfileHeader>
                    <ProfileBody userProfile={userProfile} handleEmail={handleEmail} email={email} toggle={props.handleDialogToggle}></ProfileBody>
                    <Button background={"linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)"} color={"#FFFFFF"} margin={"10px 20px"} smMargin={"10px 15px"} onClick={updateProfile }>Update Profile</Button>
                    <Button  border={"1px solid #0BA9DB"}  background={"#FFFFFF"} color={"#0BA9DB"} margin={"0px 20px"} smMargin={"0px 15px"}  onClick={userLogout}>Logout</Button>
                </MainDiv>
                <ImageDiv smTop={"3px"} smRight={"5px"} >
            <Image width={"16px"} smWidth={"12px"} cursor={"pointer"} src="../../Assets/cross.svg"  onClick={handleClose}></Image>
            </ImageDiv>
            </Dialog>
           
        </>

    );
}

const ProfileHeader = ({ userProfile }) => {
    return (

        <>
            <ProfileHeaderDiv>
                <Row >
                    <Column>
                        <Row>
                            <Image src="../Assets/avatar.svg" width={"40px"} margin={"15px"} top={"5px"} left={"15px"}></Image>
                            {/* <Image src="../Assets/camara.svg" width={"23px"} position={"absolute"} top={"107px"} left={"84px"}></Image> */}
                        </Row>
                    </Column>
                    <Column>
                        <Heading fontWight={"500"} fontSize={"17px"} color={"#FFFFFF"} paddingLeft={"10px"}>{userProfile.full_name}</Heading>
                        <Heading fontWight={"500"} fontSize={"14px"} color={"#C6ECFF"} paddingLeft={"10px"}>{userProfile.phone}</Heading>
                    </Column>
                </Row>
            </ProfileHeaderDiv>
        </>
    )
}
const ProfileTab = ({ userProfile }) => {
    const [changePassword , setChangePassword]= useState(false);

    const handleChangePassword=()=>{
        setChangePassword(!changePassword);
    };

    return (
        <>
            <ProfileTabDiv>
                <Column >
                    <Row justifyContent='space-around' alignItems='center'>
                        <Heading fontSize={"18px"} fontWight={"600"} smpaddingTop={"10px"}>Profile</Heading>
                        <ChangePasswordButton smPadding={"5px 20px"} onClick={()=> handleChangePassword()}  >Change Password </ChangePasswordButton>
                    </Row>
                </Column>
            </ProfileTabDiv>
   <SetPasswordDialog  toggle={changePassword}  handleChangePassword={handleChangePassword} userProfile={ userProfile } />
        </>
    )
}
const ProfileBody = ({ userProfile , email ,handleEmail, toggle }) => {

    const [verifyEmail, setVerifyEmail] = useState(false);
  

    const [isReadOnly, setIsReadOnly] = useState(true);

 
    const toggleReadOnly = () => {
        console.log("isReadOnly" , isReadOnly);
      setIsReadOnly(!isReadOnly);
    };
    const handleTogle = () => {
        setVerifyEmail(!verifyEmail);
    };
    // const handleEmail = (e) => {
    //     setEmail(e.target.value); 
    // };
    const clickVerifyEmail =async()=>{
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            failureToast("Invalid Email Format!!");
        }
        else{
            const input ={user_id:userProfile.user_id,email:email}
            const url = `${redisApiBaseUrl}/sendOtpForEmailRedis`;
    
            const response = await axiosRequest("POST", url, input);
            if (!response || response.errors || !response.status) {
              failureToast(response?.message || "Unable to update");
              return;
            }
            setVerifyEmail(!verifyEmail);
  
        }
       
    };

  
   
    
    return (
        <>
            <ProfileBodyDiv>
                <Heading fontSize={"16px"} fontWeight={"600"} marginLeft={"12px"} fontFamily={"Outfit"} >Profile Info</Heading>
                <Label>Team Name</Label>
                <Input placeholder={userProfile.team_name} name='full_name' color={"black"} outline={"none"} readOnly></Input>
                <Image src="../Assets/lock.svg" width={"15px"} borderRadius={"none"} position={"absolute"} top={"85px"} left={"318px"} smLeft={"85%"} ></Image>

                <Column>
                    <Row justifyContent='space-between'>
                        <Label>Email</Label>
                        <Image src="../Assets/editpen.svg" width={"15px"} position={"absolute"} left={"318px"} smLeft={"85%"}  top={"167px"} cursor={"pointer"}  onClick={toggleReadOnly}></Image>
                        <Row onClick={() => clickVerifyEmail()} >
                            <Label color={"#BFBFBF"}>Not verified </Label>
                            <Label color={"#FF4C00"} paddingLeft={"5px"} PaddingRight={"5px"} marginLeft={"0px"}  cursor={"pointer"}> Verify </Label>
                            <Image src="../Assets/redrightarrow.svg" width={"10px"} ></Image>
                        </Row>

                    </Row>
                </Column>
                <Input  smFontsize={"11.5px"}placeholder={email} value={email} name='email'  color={isReadOnly?"#6a6767c2": "inherit"}onChange={(event) => handleEmail(event.target.value)}  readOnly={isReadOnly} ></Input>
                <Label>Mobile</Label>
                <Input placeholder={userProfile.phone} value={userProfile.phone} name='mobile' color={"#6a6767c2"} outline={"none"} readOnly></Input>
                <Image src="../Assets/lock.svg" width={"15px"} borderRadius={"none"} position={"absolute"} left={"318px"} smLeft={"85%"} top={"250px"}></Image>
            </ProfileBodyDiv>
            <VerifyYourEmailDialog verifyEmail={verifyEmail} handleTogle={handleTogle} toggle={toggle} email={email} userProfile={userProfile} />
            
        </>

    )
}






export default UserUpdateProfileDialog
