import * as React from 'react';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Heading, Image } from '../../LoginAndSIgnup/LoginAndSIgnUpStyle';
import styled from 'styled-components';
import { Column, Row } from 'simple-flexbox';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../reducers/user';
import { openPlayStore, successToast } from '../../../../utility';
import { sessionManager } from '../../../../managers/sessionManager';
import { cookiesConstants } from '../../../../constants';
import MyReferTab from './HowItWorkDialog';
import MyBalanceDialog, { MyBalance } from './WalletInfoDialog';
import GetFreeCoinDialog from '../../Coin/GetFreeCoin';
import UserUpdateProfileDialog from "./UserUpdateProfileDialog";
import UserUpdateImageDialog from "./UserUpdateImageDialog"
import UserLogout from './UserLogout';
import { Link } from 'react-router-dom';
import LeaderboardDialog from '../../Leaderboard/leaderboard';
import pencilicon from "../../../../image/pencilicon.png"
import CricFunInstallSpinDialog from '../../Games/CricFunInstallSpinDialog';
// import userImage  from '../../../../../public/Assets/'


export const ProfileHeaderDiv = styled.div`
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
padding-left: 10px;
padding-right: 30px;
padding-top: 15px;
padding-bottom: 15px;
min-width:270px;
`
export const SubDiv = styled.div`
padding-top: 5px;
padding-left: 10px;
padding-right: 10px;
background-color: #F1F3F4;
padding-bottom: 10px;
cursor:pointer;


`
export const InviteButton = styled.button`
color: #000000;
background-color: #FCFCFC;
padding: 10px 32px;
border: 1px solid #D2D1D1;
border-radius: 5px;
font-size: 10px;
font-weight: 600;
margin-top : 8px;
`
export const HorizontalLine = styled.div`
border:0.7px solid #E4E4E4;
width:100%;
margin-left: 0px;
margin-bottom: 10px;
margin-top: 0px;
`

const UserProfileDialog = ({ openPop, anchorEl, handlePopoverClose }) => {
    const dispatch = useDispatch();
    const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
    // const userLogout = () => {
    //     dispatch(logout());
    //     successToast("Logout Successfully");
    //     handlePopoverClose()
    // }
    const [toggleDialog, setToggleDialog] = React.useState({
        myReferrals: false,
        transactions: false,
        wallet: false,
        freeCoins: false,
        leaderboard: false,
        userUpdateProfile :false,
        updateImage :false,
        logout : false
    })
    const handleDialogToggle = (name, value) => {
        setToggleDialog({
            ...toggleDialog,
            [name]: value
        })

    }

    return (
        <>

            {/* <Image src="../Assets/avatar.svg" width={"30px"} margin={"5px"} onClick={handleClick}></Image> */}
            <MyReferTab toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></MyReferTab>
            <CricFunInstallSpinDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></CricFunInstallSpinDialog>

            <UserLogout toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle} handlePopoverClos={handlePopoverClose} ></UserLogout>
            <MyBalanceDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></MyBalanceDialog>
            <GetFreeCoinDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></GetFreeCoinDialog>
            <LeaderboardDialog  toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></LeaderboardDialog>
            <UserUpdateProfileDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}  profileDetails={profileDetails} ></UserUpdateProfileDialog>
           {/* <UserUpdateImageDialog toggleDialog={toggleDialog} handleDialogToggle={handleDialogToggle}></UserUpdateImageDialog> */}
           {/* <Popover
                id={"simple-popover"}
                open={openPop && sessionManager.getDataFromCookies(cookiesConstants.IS_LOGGED_IN)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorPosition={{ top: 400 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            > */}
            <Popover 
              id={"simple-popover"}
              open={openPop && sessionManager.getDataFromCookies(cookiesConstants.IS_LOGGED_IN)}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 500, left: 1220 }}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              
            {/*  </Popover>  */}
                <ProfileHeaderDiv onClick={() => { handleDialogToggle("userUpdateProfile", true); handlePopoverClose(); }}>
                    <Row style={{cursor:"pointer"}}>
                        <Column>
                            <Row style={{cursor:"pointer"}}>
                                <Image src="/Assets/avatar.svg" width={"40px"} margin={"5px"} cursor={"pointer"} ></Image>
                                <Image src={pencilicon}  width={"20px"}   padding={"3px"} position={"absolute"} top={"36px"} left={"35px"} background={"white"} cursor={"pointer"} ></Image>
                            </Row>
                        </Column>
                        <Column  >

                            <Heading fontWeight={"500"} fontSize={"17px"} color={"#FFFFFF"} paddingLeft={"10px"}  >{profileDetails?.full_name}</Heading>
                            <Heading fontWeight={"500"} fontSize={"14px"} color={"#C6ECFF"} paddingLeft={"10px"}>{profileDetails?.phone}</Heading>

                        </Column>
                    </Row>
                </ProfileHeaderDiv>
                <>
                    <SubDiv>
                        <Row justifyContent='space-between'>
                            <Row>
                                <Image src="/Assets/refer&earn.svg" width={"20px"} margin={"5px"} ></Image>
                                <Heading fontSize={"15px"} fontWeight={"500"} paddingLeft={"4px"} color={"#070A11"} paddingTop={"14px"}> Refer & Earn</Heading>
                            </Row>
                            <Row>
                                <InviteButton onClick={() => { handleDialogToggle("myReferrals", true); handlePopoverClose(); }}>INVITE </InviteButton>
                            </Row>
                        </Row>

                    </SubDiv>
                    <Link to={"/game"}>
                    <SubDiv>
                        <HorizontalLine width={"100px"} height={"2px !important"}></HorizontalLine>
                        <Row justifyContent='space-between'>
                            <Row >
                                <Image src={"/Assets/game.svg"} width={"23px"} margin={"5px"} ></Image>
                                <Heading fontSize={"15px"} fontWeight={"500"} paddingLeft={"4px"} paddingBottom={"5px"}   color={"#070A11"} marginTop={"5px"}>Games</Heading>
                            </Row>
                        </Row>

                        </SubDiv>
                        </Link>
                    <SubDiv onClick={() => { handleDialogToggle("spin", true) }}>
                        <HorizontalLine width={"100px"} height={"2px !important"}></HorizontalLine>
                        <Row justifyContent='space-between'>
                            <Row >
                                <Image src={"/Assets/lottery.svg"} width={"23px"} margin={"5px"} ></Image>
                                <Heading fontSize={"15px"} fontWeight={"500"} paddingLeft={"4px"} paddingBottom={"5px"} color={"#070A11"} marginTop={"5px"}>Daily Spin</Heading>
                            </Row>
                        </Row>

                    </SubDiv>
                    <SubDiv onClick={() => { handleDialogToggle("freeCoins", true); handlePopoverClose(); }}>
                        <HorizontalLine width={"100px"} height={"2px !important"}></HorizontalLine>
                        <Row justifyContent='space-between'>
                            <Row >
                                <Image src={"/Assets/getfreecoin.svg"} width={"23px"} margin={"5px"} ></Image>
                                <Heading fontSize={"15px"} fontWeight={"500"} paddingLeft={"4px"} paddingBottom={"5px"} color={"#070A11"} marginTop={"5px"}>Get Free Coins</Heading>
                            </Row>
                        </Row>

                    </SubDiv>

                    {/* <SubDiv onClick={() => { handleDialogToggle("leaderboard", true); handlePopoverClose(); }}>
                        <HorizontalLine width={"100px"} height={"2px !important"}></HorizontalLine>
                        <Row justifyContent='space-between'>
                            <Row >
                                <Image src={"/Assets/predictleaderboard.svg"} width={"23px"} margin={"5px"} ></Image>
                                <Heading fontSize={"14px"} fontWeight={"500"} paddingLeft={"4px"} paddingBottom={"5px"} marginTop={"5px"}>Prediction Leaderboard</Heading>
                            </Row>
                        </Row>

                    </SubDiv> */}
                    <SubDiv onClick={() => { handleDialogToggle("wallet", true); handlePopoverClose(); }}>
                        <HorizontalLine width={"100px"} height={"2px !important"}></HorizontalLine>
                        <Row justifyContent='space-between'>
                            <Row >
                                <Image src={"/Assets/walletapp.svg"} width={"23px"} margin={"5px"} ></Image>
                                <Heading fontSize={"15px"} fontWeight={"500"} paddingLeft={"4px"}  color={"#070A11"} paddingBottom={"5px"} marginTop={"5px"}>Wallet</Heading>
                            </Row>
                        </Row>

                    </SubDiv>


                    <SubDiv onClick={() => { handleDialogToggle("logout", true); handlePopoverClose(); }}>
                        <HorizontalLine width={"100px"} height={"2px !important"}></HorizontalLine>
                        <Row justifyContent='space-between'>
                            <Row >
                                <Image src={"/Assets/logout.svg"} width={"23px"} margin={"5px"} ></Image>
                                <Heading  fontSize={"15px"} fontWeight={"500"} color={"#070A11"} paddingLeft={"4px"} paddingBottom={"5px"} marginTop={"5px"}>Logout</Heading>
                                {/* onClick={userLogout} */}
                            </Row>
                        </Row>

                    </SubDiv>

                </>
            </Popover>

        </>

    );
}

export default UserProfileDialog
