

import React from 'react';
import { PieChart, Pie } from 'recharts';
import { Heading } from '../pages/pagesStyle';


const MyPieChart = (props) => {
    const { data, label, height, width, outerRadius, innerRadius } = props;
    return (
        <div style={{ position: 'relative', width: width || 150 }}>
            <PieChart width={width || 150} height={height || 150}>
                <Pie
                    data={data}
                    dataKey="value"
                    outerRadius={outerRadius || 70}
                    stroke=""
                    innerRadius={innerRadius || 58}

                />
            </PieChart>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                   
                }}
            >
                {props?.type === "wicket"
                    ? <Heading textAlign={"center"} fontWeight={"500"} fontSize={"14px"}>{label}%</Heading>
                    :
                    <>
                        <Heading textAlign={"center"}> {label || 0}</Heading>
                        <Heading textAlign={"center"} color={"#737478"} fontWeight={"400"} fontSize={"17px"}>Matches</Heading>
                    </>
                }
            </div>
        </div>
    );
}



export default MyPieChart;
