import axios from "axios";
import { apiBaseUrl, ApiKey } from "../config";
import { sessionManager } from "../managers/sessionManager";
import { cookiesConstants } from "../constants";

export const axiosRequest = async(method, url, request) => {
    let config = {
        method: method,
        url: url,
        headers: {
            'api-key': ApiKey
        },
        data: request || {}

    };  
    const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS);
    if (profileDetails && profileDetails.token)
        config.headers["authorization"] = profileDetails.token;
   
    try {
        const data = await axios(config);
        if (data.status === 200)
        return data.data;
    } catch (error) {
        console.log("error",error.response.status)
        error.response.data.statusCode = error.response.status
        return error.response.data
    }
   

}