import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { apiBaseUrl, ApiKey } from "../../../config";
import { SubHeaderDiv, SubHeaderUl, VerticalLine } from "../MenuStyle";
import { Column, Row as FlexRow } from "simple-flexbox";

function SubHeader() {
    const [stripHeader, setStripHeader] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        SubHeader();
    }, [])

    function SubHeader() {
        
        var config = {
            method: 'get',
            url: `${apiBaseUrl}/getStripHeader`,
            headers: {
                'api-key':ApiKey
            }
        };

        axios(config)
            .then(function (response) {
                setStripHeader(response.data.result);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    return (
        <>
            <div style={{ background: "#FEFEFE", boxShadow: "0px 0px 6px #ccc", }}>
                {/* <Container> */}
                    <div className="subHeader">
                        <Row>
                        <SubHeaderDiv>
                            <SubHeaderUl>
                                 {
                                    stripHeader && stripHeader.map((data, index) =>
                                        <FlexRow >
                                            <Column justifyContent="center">
                                            <NavLink to={{ pathname: `/${data.slug}/${data.id}` }} key={index}>
                                                <li className="tabName">{data.name}</li></NavLink>
                                            </Column>
                                            <Column justifyContent="center">
                                            {index !== (stripHeader.length - 1) ?
                                                    <VerticalLine margin={"10px 40px"}></VerticalLine> : ""}
                                                </Column>
                                            </FlexRow>
                                    )
                                }
                            </SubHeaderUl>
                        </SubHeaderDiv>
                        

                        </Row>
                        </div>
                {/* </Container> */}
            </div>
        </>
    )
}
export default SubHeader