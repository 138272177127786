import styled from "styled-components";
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import { Column, Row } from "simple-flexbox";
import { ShareButton } from "../../MatchInfo/MatchInfoStyle";
import CopyToClipboard from "react-copy-to-clipboard";
import { TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { sessionManager } from "../../../../managers/sessionManager";
import { cookiesConstants } from "../../../../constants";
import { redisApiBaseUrl } from "../../../../config";
import { axiosRequest } from "../../../../utility/axios";
import moment from "moment";
import { successToast } from "../../../../utility";
import ReferAndEarn from "../../ReferAndEarn/ReferAndEarn";
import { Grid } from "@mui/material";
import { WebAppUrl } from "../../../../config";
export const ColumnDiv = styled.div`
margin-top: ${props =>props.marginTop || "5px"};
background: #F1F3F4;
padding: ${props => props.padding || "20px"};
border-radius: 8px;
// min-width:350px;

@media(max-width:480px){
   padding :${props =>props.sxPadding};

}
`
export const CustomMatchTabList = styled(TabList)`
list-style-type:none;
display:flex;
justify-content:space-between;
width:100%;
padding:0px 0px 5px 0px;
margin:0px 0px 10px 0px;

`


const Image = styled.img`
width:${props => props.width || "30px"};
margin-right:${props => props.margin || "0px"};
height:${props => props.height || "auto"};
margin-top:${props => props.marginTop || "0px"};
margin-left:${props => props.marginLeft || "0px"};
cursor:${props=>props.cursor || "auto"};
@media(max-width:680px){
    width:${props => props.smLWidth};
};
@media(max-width:480px){
    width:${props => props.smWidth};
    margin-right:${props => props.smMargin};
     margin-bottom:${props => props.smMarginBottom};
}
`
export const CustomMatchTab = styled(Tab)`
color:${props => props.primary ? "#0BA9DB" : "#525B7C"}; 
font-size: 16px;
font-weight:600;
   text-align:center;
    border-bottom: ${props => props.primary ? "3px solid transparent" : "none"};
    border-image: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
    border-image-slice: 1;
    width:100%;
    cursor:pointer;
@media(max-width:600px){
    font-size:14px;
}

`
export const HorizontalLineToActive = styled.div`
width:${props => props.width || "0px"};
height: ${props => props.height || "0px"} ;
background: linear-gradient(rgba(48, 164, 248, 1)) !important;
`
export const ShareImage = styled.img`
    width: 100%;
    max-width:400px;
    max-height: 350px;
    padding: 0px 15px 20px 15px;
    cursor:pointer;
       @media(max-width:480px){

padding:${props=>props.sxPadding};
   }
`
export const SocialMedia = styled.div`
   display:flex;
   justify-content:space-between;
   width:45%;
   @media(max-width:430px){
    width:95%;
    margin-right:10px;
   }
`
export const ShareCodediv = styled.div`
    border : 2px dotted #BCBBBB;
    display:flex;
    justify-content:space-between;
    padding: 10px;
    border-radius : 25px;
    background:#F6F6F6;

`
export const ShareCodeContainer = styled.div`
    padding:10px;
     margin: 2px 12px;
     background-color: #FFFFFF;
     border-radius : 5px;

        @media(max-width:480px){

margin:${props=>props.sxMargin};
   }
     
`;
export const CoinDiv = styled.div`
 background:#FFFFFF;
 padding: ${props => props.padding};
 margin: ${props => props.margin};
 border-radius: 10px;

@media(max-width:480px){

margin:${props=>props.sxMargin};
}

`
export const HistoryDiv = styled.div`
background: #F7F7F8;
display:flex;
justify-content:space-between;
padding: 10px 15px;
border-radius: 8px;
`
export const Heading = styled.div`
font-size: ${props => props.fontSize || "24px"};
font-weight:${props => props.fontWeight || "600"};
line-height: 30px;
letter-spacing: 0em;
text-align: ${props => props.align || "center"};
padding: ${props => props.padding || "0px"};
color: ${props => props.color || "#000000"};
margin-top :${props => props.marginTop || "0px"};
@media(max-width:600px){
    font-size:${props => props.smLFont}
};
@media(max-width:500px){
    font-size:${props => props.smFont};
    line-height:20px;
    padding: ${props => props.smPadding};
};
@media(max-width:400px){
    font-size:${props => props.xsFont}
};
`
export const VerticalLine = styled.div`
background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%);
width:1px;

margin:0px 30px 0px 30px;
@media(max-width:500px){
    margin:0px 10px 0px 10px;
}
`

const MyReferTab = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [history, setHistory] = useState([])
    const getTransactions = async () => {
        const profileDetails = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS)
        let url = `${redisApiBaseUrl}/getReferalTransactionRedis/${profileDetails.user_id}/0/10`;
        const response = await axiosRequest("GET", url);
        if (!response || response.errors || !response.status) {
            return;
        }
        setHistory(response.data)
    }
    useEffect(() => {
        if (props?.toggleDialog?.myReferrals)
            getTransactions()
    }, [props?.toggleDialog?.myReferrals])
    return (
        <Dialog open={props?.toggleDialog?.myReferrals} 
        sx={{
            "& .MuiDialog-paper": {
                margin: "20px 10px",
                borderRadius:"4px"
            },
        }}
        >
            <ColumnDiv marginTop={"0px"}padding={"5px 20px 20px 20px"} sxPadding={"5px 10px 10px 10px"}>
                <Row justifyContent='end'>
                    <Image width={"12px"}  cursor={"pointer"}marginTop={"5px"} src="../Assets/cross.svg" onClick={() => props.handleDialogToggle("myReferrals", false)}></Image>
                </Row>

                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <CustomMatchTabList>
                        <CustomMatchTab primary={tabIndex === 0 ? true : false}>How It Works
                        </CustomMatchTab>
                        <CustomMatchTab primary={tabIndex === 1 ? true : false}>My Referrals
                        </CustomMatchTab>
                    </CustomMatchTabList>
                    <TabPanel>
                        <HowItWorksDialog></HowItWorksDialog>
                        {/* <ReferAndEarn shareButtonPadding={"6px"} shareButtonWidth={"90px"} padding={"10px 8px 26px"} paddingImage={"0px"}></ReferAndEarn> */}
                    </TabPanel>
                    <TabPanel>
                        {MyReferrals(history)}
                    </TabPanel>
                </Tabs>
            </ColumnDiv>
        </Dialog>
    )
}

const HowItWorksDialog = () => {
    const referCode = sessionManager.getDataFromCookies(cookiesConstants.PROFILE_DETAILS)?.refer_id || "";
    const [shareUrl, setShareUrl] = useState('');
    const handleCopy = () => {
        successToast("Copied!!")
    }
    const handleShare = async () => {
        
        successToast("Copied Share Link!!")
    }
    useEffect(()=>{
        const baseUrl = "https://play.google.com/store/apps/details?id=com.app.sports.cricketlive&pcampaignid=web_share";
        const shareCode = referCode;
        const completeUrl = `${baseUrl}&shareCode=${shareCode}`;
        setShareUrl(completeUrl);
      },[referCode])

    return (
        <>
            <Row justifyContent="center">
                <ShareImage  sxPadding={"0px 0px 20px 0px"}src="../Assets/sharenew.svg"></ShareImage>
            </Row>

            <ShareCodeContainer sxMargin={"2px 0px"} >
                <Row>
                    <div style={{ marginBottom: "10px" }}>
                        Share your Code
                    </div>
                </Row>
                <ShareCodediv>
                    <span>{referCode}</span>
                    <CopyToClipboard
                        text={referCode}
                        onCopy={handleCopy}
                    >
                        <img
                            style={{ cursor: "copy" }}

                            src="../Assets/copyIcon.svg"
                        />
                    </CopyToClipboard>
                </ShareCodediv>
                {/* </ShareCodeContainer>
            <ShareCodeContainer> */}
                <Row justifyContent="space-between" style={{ marginTop: "20px" }}>
                    {/* <SocialMedia >
                        <WhatsappShareButton>
                            <WhatsappIcon size={"40px"} borderRadius={"10px"}></WhatsappIcon>
                        </WhatsappShareButton>
                        <TelegramShareButton>
                            <TelegramIcon size={"40px"} borderRadius={"10px"}></TelegramIcon>
                        </TelegramShareButton>
                        <TwitterShareButton>
                            <TwitterIcon size={"40px"} borderRadius={"10px"}></TwitterIcon>
                        </TwitterShareButton>
                    </SocialMedia>
                    <ShareButton>
                        <Image width={"20px"} smWidth={"14px"} smMargin={"2px"} smMarginBottom={"3px"} src="../Assets/shareIcon.svg"></Image>
                        Share

                    </ShareButton> */}
                    <Grid container>
                        <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2} display={"flex"} justifyContent={"center"}>
                            <WhatsappShareButton
                                url={WebAppUrl}
                                title={`Join CricFun`}
                            >
                                {/* <WhatsappIcon size={"40px"} borderRadius={"10px"}  ></WhatsappIcon> */}
                                <Image  width={" 40px"} src="https://storage.googleapis.com/cricfun-images/img-1718696834766.png" cursor={"pointer"}></Image>
                            </WhatsappShareButton>
                        </Grid>
                        <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2} display={"flex"} justifyContent={"center"} >
                            <TelegramShareButton

                                url={WebAppUrl}
                                title={`Join CricFun`}>
                                {/* <TelegramIcon size={"40px"} borderRadius={"10px"}></TelegramIcon> */}
                                <Image width={" 40px"}  src="https://storage.googleapis.com/cricfun-images/img-1718696946655.png" cursor={"pointer"}></Image>
                            </TelegramShareButton>
                        </Grid>
                        <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3} display={"flex"} justifyContent={"center"} >
                            {/* <Image  src={"https://storage.googleapis.com/cricfun-images/img-1716987393517.png"} width={"41px"}></Image> */}
                            <TwitterShareButton
                                url={WebAppUrl}
                                title={`Join CricFun`}>
                                {/* <TwitterIcon size={"40px"} borderRadius={"10px"}>

                                </TwitterIcon> */}
                                <Image  width={" 40px"} src="https://storage.googleapis.com/cricfun-images/img-1718696900563.png" cursor={"pointer"}></Image>
                            </TwitterShareButton>
                        </Grid>
                        <Grid item xs={5.3} sm={5.3} md={5.3} lg={5.3} display={"flex"} justifyContent={"center"} >
                            {/* <ShareButton padding={"6px 18px"} width={"90%"}>
                                <Image width={"17px"} margin={"5px"} src="../Assets/copyiconnew.svg"></Image>
                                <Heading fontWeight={"500"} fontSize={"18px"}>Copy</Heading>
                            </ShareButton> */}
                             <CopyToClipboard
                                text={shareUrl}
                                onCopy={handleShare}
                       
                            >
                                <ShareButton  padding={"6px 18px"} width={"90%"}>
                                <Image width={"17px"}margin={"5px"} src="../Assets/copyiconnew.svg"></Image>
                                <Heading fontWeight={"500"}fontSize={"18px"}>Copy</Heading>
                               
                            </ShareButton>
                            </CopyToClipboard>
                        </Grid>

                    </Grid>

                </Row>
            </ShareCodeContainer>
        </>
    )
}



const MyReferrals = (history) => {
    return (
        <>
            <CoinDiv padding={"20px 10px"}>
                <Row >
                    <Row>
                        <Image src={"../Assets/rewards.svg"} width={"60px"} smLWidth={"60px"} smWidth={"50px"} margin={"15px"}></Image>
                        <Column justifyContent="center">
                            <Heading fontSize={"20px"} smLFont={"20px"} smFont={"16px"} align={"left"} xsFont={"12px"}>{history?.totalReferralsRewards || "0.00"}</Heading>
                            <Heading fontSize={"16px"} smLFont={"20px"} smFont={"16px"} xsFont={"12px"} color={"#81838A"} fontWeight={"400"}>Rewards</Heading>
                        </Column>
                    </Row>
                    <VerticalLine></VerticalLine>
                    <Row>
                        <Image src={"../Assets/referral.svg"} width={"60px"} smLWidth={"60px"} smWidth={"50px"} margin={"15px"}></Image>
                        <Column justifyContent="center">
                            <Heading fontSize={"20px"} mLFont={"20px"} smFont={"16px"} align={"left"} xsFont={"12px"}>{history?.totalReferrals || "0"}</Heading>
                            <Heading fontSize={"16px"} smLFont={"20px"} smFont={"16px"} xsFont={"12px"} color={"#81838A"} fontWeight={"400"}>Referrals</Heading>
                        </Column>
                    </Row>
                </Row>
                {/* <Heading>Earned through referrals</Heading>
                <Row justifyContent="center">
                    <Image src="../Assets/coin.svg" width={"42px"}></Image>
                    <Heading fontSize={"48px"} fontWeight={"700"}>{walletInfo?.rewards + walletInfo?.winning}</Heading>
                </Row> */}
            </CoinDiv>
            <CoinDiv margin={"40px 0px 40px 0px"} sxMargin={"26px 0px 26px 0px"} padding={"30px 25px 45px 25px"}>
                
                {history?.userTransaction?.length ? history.userTransaction.map((transaction) =>
                    <HistoryDiv justifyContent="space-between">
                        <Row>
                            <Image src={"../Assets/badge.svg"} width={"140px"} margin={"10px"} smLWidth={"30px"} smWidth={"25px"}></Image>
                            <Column>
                                <Heading fontWeight={"500"} smLFont={"20px"} smFont={"16px"} xsFont={"12px"} align={"left"}>{transaction?.team_name}</Heading>
                                <Heading fontWeight={"400"} smLFont={"20px"} smFont={"16px"} xsFont={"12px"} color={"#81838A"} align={"left"}>{moment(transaction?.txn_date).format("DD MMM, HH:mm")}</Heading>
                            </Column>
                        </Row>
                        <Row>

                            <Heading fontSize={"24px"} smLFont={"20px"} smFont={"16px"} xsFont={"12px"} padding={"15px 5px 0px 0px "} smPadding={"10px 5px 0px 0px "}>{transaction.sign_type}{transaction.coin}</Heading>
                            <Image src="../Assets/coin.svg" width={"24px"}></Image>
                        </Row>
                    </HistoryDiv>
                )
                    :
                    <>
                        <Row justifyContent="center" style={{padding:"28px 0"}}>
                            <Image width={"120px"}  src={"../Assets/nodata.svg"} ></Image>
                        </Row>
                        <Heading fontSize={"21px"} marginTop={"25px"} xsFont={"16px"} fontWeight={"500"}>Sorry you haven’t referred anyone yet</Heading>
                    </>

                }
            </CoinDiv>
        </>
    )
}
export default MyReferTab 
